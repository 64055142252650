import React, { ReactNode } from 'react'
import { Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import {
  SitecoreNavigationObject,
  NavigationSettings,
  ImageLink,
} from '../types'
import { resolveLogo } from './resolve-logo'

type NavigationContextProps = {
  sitecoreContext: {
    pageEditing: boolean
    navigation: SitecoreNavigationObject
  }
  children?: ReactNode
}

const initialSettings: NavigationSettings = {
  noMenus: false,
  logo: { image: null, href: '' },
  mobileLogo: { image: null, href: '' },
  login: false,
  menu: null,
  isIE11: false,
}

export const NavigationContext = React.createContext(initialSettings)

function NavigationContextComponent({
  sitecoreContext: { pageEditing, navigation },
  children,
}: NavigationContextProps) {
  if (!navigation) {
    console.warn(
      'Navigation is missing from context and is required to render the nav'
    )
    return null
  }

  const mobileLogoObj =
    navigation.mobileLogo && navigation.mobileLogo.src !== ''
      ? navigation.mobileLogo
      : navigation.logo

  const settings: NavigationSettings = {
    menu: navigation,
    login: true,
    isIE11: false,
    noMenus: pageEditing,
    logo: getLogo(navigation.logo),
    mobileLogo: getLogo(mobileLogoObj, true),
  }

  return (
    <NavigationContext.Provider value={settings}>
      {children}
    </NavigationContext.Provider>
  )
}

function getLogo(logoField: ImageLink, isMobileLogo?: boolean) {
  const { href, image, title } = resolveLogo(logoField)

  return {
    href: href,
    image: isMobileLogo ? (
      <Image field={image} height="50" width="145" />
    ) : (
      <Image field={image} height="64" />
    ),
    title: title,
  }
}

export default withSitecoreContext()(NavigationContextComponent) as React.FC<
  any
>
