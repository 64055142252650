import React, { FC } from 'react'
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import QuoteLanding from './QuoteLanding'
import SearchAppStartContext from '../../quotes/experiences/search-app-start/context'
import SearchAppStart from '../../quotes/experiences/search-app-start/embeds/cta'
import { TextField } from '../../types/SitecoreAdapter'

type Props = {
  fields: {
    heading: TextField
    descriptor: TextField
  }
  testId?: string
  sitecoreContext: any
}

const QuoteLandingAdapter: FC<Props> = ({ fields, testId }) => {
  const { heading, descriptor } = fields

  return (
    <QuoteLanding
      testId={testId}
      heading={<Text field={heading} />}
      descriptor={<Text field={descriptor} />}
    >
      <SearchAppStartContext>
        <SearchAppStart />
      </SearchAppStartContext>
    </QuoteLanding>
  )
}

export default withSitecoreContext()(QuoteLandingAdapter)
