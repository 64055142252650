import React, { useContext } from 'react'
import clsx from 'clsx'
import Button from '../Button'
import { ModalCTAContext } from '../../context/ModalCTAContext'
import Media from '../Media'
import styles from './styles.module.scss'

export type TabbedFeatureButtonProps = {
  href: string
  title: string
  mobileText: string
  desktopText: string
  type: string
  isCTA: boolean
}

type ModalButtonProps = {
  mobileText: string
  desktopText: string
  title: string
  type: string
}

const TabbedFeatureButton = ({
  href,
  title,
  mobileText,
  desktopText,
  type,
  isCTA,
}: TabbedFeatureButtonProps) => {
  return isCTA ? (
    <ModalButton
      mobileText={mobileText}
      desktopText={desktopText}
      title={title}
      type={type}
    />
  ) : (
    getDefaultButton(type, href, title, mobileText, desktopText)
  )
}

export default React.memo(TabbedFeatureButton)

function ModalButton({
  mobileText,
  desktopText,
  title,
  type,
}: ModalButtonProps) {
  const { showModal } = useContext(ModalCTAContext)

  return (
    <Button
      className={clsx('button', styles.tabbedFeatureButton, styles.link)}
      title={title}
      type={type}
      size="medium"
      onClick={showModal}
    >
      <Media mobile={() => mobileText} desktop={() => desktopText} />
    </Button>
  )
}

function getDefaultButton(
  type: string,
  href: string,
  title: string,
  mobileText: string,
  desktopText: string
) {
  return (
    <Button
      className={clsx('button', styles.tabbedFeatureButton, styles.link)}
      type={type}
      size="medium"
      href={href}
      title={title}
    >
      <Media mobile={() => mobileText} desktop={() => desktopText} />
    </Button>
  )
}
