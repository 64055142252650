import React, { createContext, createElement, useEffect, useState } from 'react'
import {
  ComponentConsumerProps,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import { map, find, isFunction } from 'lodash-es'

import {
  BreakpointName,
  BreakpointListener,
  BreakpointSpec,
  MediaSpec,
} from './domain'
import { FC } from '../../types/common'

interface IDeviceLayout {
  sitecoreContext: {
    deviceLayout: string
  }
}

// by default, the current breakpoint is src
export const CurrentBreakpoint = createContext('source')

const breakpoints: BreakpointSpec[] = [
  ['mobile', '(max-width: 767px)'],
  ['tablet', '(min-width: 768px) and (max-width: 1023px)'],
  ['laptop', '(min-width: 1024px) and (max-width: 1199px)'],
  ['desktop', '(min-width: 1200px) and (max-width: 1399px)'],
  ['wide', '(min-width: 1400px)'],
]

// server gets the default breakpoint value
// browser spins up media listeners that change provided value
function MediaListenerComponent({
  sitecoreContext: { deviceLayout },
  children,
}: React.PropsWithChildren<ComponentConsumerProps & IDeviceLayout>) {
  const [currentBreakpoint, setBreakpoint] = useState(deviceLayout)

  useEffect(() => {
    if (window.matchMedia) {
      // create tuples of [breakpointName, queryListener]
      const listeners: BreakpointListener[] = map(
        breakpoints,
        ([name, rule]) => {
          return [name, window.matchMedia(rule)]
        }
      )

      // find the first breakpoint that's active
      const startPoint = find(listeners, ([, query]) => query.matches)

      // this should not be undefined, but the type system doesn't know that
      if (startPoint) {
        const [startPointName] = startPoint

        // add new breakpoint to the state
        setBreakpoint(startPointName)
      }

      listeners.forEach(([breakName, query]) => {
        query.addListener(({ matches }) => {
          if (matches) {
            setBreakpoint(breakName)
          }
        })
      })
    }
  })

  return (
    <CurrentBreakpoint.Provider value={currentBreakpoint}>
      {children}
    </CurrentBreakpoint.Provider>
  )
}

export const MediaListener = withSitecoreContext()(MediaListenerComponent) as FC

export type MediaProps = {
  source?: MediaSpec
  mobile?: MediaSpec
  tablet?: MediaSpec
  laptop?: MediaSpec
  desktop?: MediaSpec
  wide?: MediaSpec
}

// Media component consumes CurrentBreakpoint, and renders
export const getMedia = (
  BreakpointConsumer: React.Consumer<BreakpointName>
) => {
  return function Media({
    source,
    mobile,
    tablet,
    laptop,
    desktop,
    wide,
  }: MediaProps) {
    return (
      <BreakpointConsumer>
        {(currentBreakpoint: BreakpointName) => {
          switch (currentBreakpoint) {
            case 'source':
              return checkpoints([source, mobile], source)
            case 'mobile':
              return checkpoints([mobile, source], mobile)
            case 'tablet':
              return checkpoints([tablet, mobile, source], tablet)
            case 'laptop':
              return checkpoints([laptop, tablet, mobile, source], laptop)
            case 'desktop':
              return checkpoints(
                [desktop, laptop, tablet, mobile, source],
                desktop
              )
            case 'wide':
              return checkpoints(
                [wide, desktop, laptop, tablet, mobile, source],
                wide
              )
            default:
              return checkpoints([source, mobile], true)
          }
        }}
      </BreakpointConsumer>
    )
  }
}

export default getMedia(CurrentBreakpoint.Consumer)

export function checkpoints(
  breakpoints: (MediaSpec | undefined)[],
  switchOff: MediaSpec | undefined
): JSX.Element | false {
  if (switchOff === false || switchOff === null) {
    return false
  }

  // check all of our breakpoint props in order, returning the first that has a value
  const getComponent = find(breakpoints, isFunction)
  // if we find a matching breakpoint, run it to generate the Element to render
  return getComponent && getComponent()
}
