import React, { FC } from 'react'
import { RichText, Text, Image } from '@sitecore-jss/sitecore-jss-react'
import Media from '../Media'
import ValueProp from './ValueProp'
import { ImageField, TextField } from '../../types/SitecoreAdapter'

type Props = {
  fields: { icon: ImageField; heading: TextField; body: TextField }
  testId?: string
}

const ValuePropAdapter: FC<Props> = ({ fields: { icon, heading, body }, testId }) => {
  const imagerEl = (
    <Media
      mobile={() => (
        <Image
          field={icon}
          imageParams={{ w: 32, h: 32 }}
          height="32"
          width="32"
        />
      )}
      desktop={() => (
        <Image
          field={icon}
          imageParams={{ w: 48, h: 48 }}
          height="48"
          width="48"
        />
      )}
    />
  )
  const headingEl = <Text field={heading} tag="h4" />
  const bodyEl = <RichText field={body} />

  return <ValueProp image={imagerEl} heading={headingEl} body={bodyEl} testId={testId} />
}

export default ValuePropAdapter
