import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

type Props = {
  heading: ReactNode
  leftImage: ReactNode
  leftHeading: ReactNode
  middleImage: ReactNode
  middleHeading: ReactNode
  rightImage: ReactNode
  rightHeading: ReactNode
  testId?: string
}

type ValueCardProps = {
  image: ReactNode
  heading: ReactNode
}

const seriesValuePro: FC<Props> = ({
  heading,
  leftImage,
  leftHeading,
  middleImage,
  middleHeading,
  rightImage,
  rightHeading,
  testId
}) => (
  <div data-test-id={testId} className="block SeriesValueProp">
    <h2 className={clsx(styles.heading, 'contain')}>{heading}</h2>
    <div className={clsx(styles.cardContainer, 'contain')}>
      <ValueCard image={leftImage} heading={leftHeading} />
      <ValueCard image={middleImage} heading={middleHeading} />
      <ValueCard image={rightImage} heading={rightHeading} />
    </div>
  </div>
)

const ValueCard = ({ image, heading }: ValueCardProps) => (
  <div className={styles.card}>
    <div className={styles.imageWrapper}>{image}</div>
    <h3 className={styles.imageHeading}>{heading}</h3>
  </div>
)

export default seriesValuePro
