import React from 'react'
import tabStyles from '../tab.module.scss'
import carouselStyles from './styles.module.scss'
import { TabListTabType } from '../TabList'
import clsx from 'clsx'

const Tab = ({ text }: TabListTabType, index: number) => {
  // empty wrapping div because react-slick adds inline styles to the outermost container
  return (
    <div key={index}>
      <div
        className={clsx(
          tabStyles.tab,
          tabStyles.active,
          carouselStyles.mobileTab
        )}
      >
        <div className={carouselStyles.tabText}>{text}</div>
      </div>
    </div>
  )
}

export { Tab }
