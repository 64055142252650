import { useContext } from 'react'
import { SitecoreContextReactContext } from '@sitecore-jss/sitecore-jss-react'

/**
 * Returns sitecore context
 */
export const useSitecoreContext = () => {
  const { context: sitecoreContext } = useContext(SitecoreContextReactContext)

  return {
    sitecoreContext,
  }
}
