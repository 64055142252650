import React from 'react'
import {
  VisitorIdentification,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import Helmet from 'react-helmet'

import { MediaListener } from '../components/Media'
import { ModalCTAProvider } from '../context/ModalCTAContext'
import Meta from '../page-components/Meta'
import JsonLd from '../page-components/Json-Ld'
import { IE11BodyClass } from '../util/IE11'
import { SitecoreAwareEnvironmentContextComponent } from '../context/Environment/EnvironmentContext'
import AppStartModalContent from './AppStartModalContent'

import PageType from '../page-types'

import '../assets/app.css'
import '../styles/app.scss'
import { SitecoreContextType } from '../types/SitecoreAdapter'

type LayoutProps = SitecoreContextType<{
  pageTitle?: {
    value?: string
  }
}>

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ sitecoreContext: { route } }: LayoutProps) => {
  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields &&
            route.fields.pageTitle &&
            route.fields.pageTitle.value) ||
            'Page'}
        </title>
      </Helmet>
      <Meta />
      <JsonLd />
      <IE11BodyClass />

      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />

      <SitecoreAwareEnvironmentContextComponent>
        <ModalCTAProvider>
          <MediaListener>
            <AppStartModalContent />
            <PageType />
          </MediaListener>
        </ModalCTAProvider>
      </SitecoreAwareEnvironmentContextComponent>
    </React.Fragment>
  )
}

export default withSitecoreContext()(Layout)
