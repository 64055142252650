import React from 'react'
import {
  Text,
  Image,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'

import ErrorPage from './ErrorPage'

type ErrorPageAdapterProps = {
  sitecoreContext: {
    route: any
  }
}

const ErrorPageAdapter = ({ sitecoreContext: { route } }: ErrorPageAdapterProps) => {
  const { image, heading, link, linkDataTag } = route.fields

  return (
    <>
      <ErrorPage
        image={<Image field={image} />}
        heading={<Text field={heading} />}
        linkText={link && link.fields.body.value}
        linkHref={link && link.fields.href.value}
        linkDataTag={linkDataTag.value}
      />
    </>
  )
}

export default withSitecoreContext()(ErrorPageAdapter)
