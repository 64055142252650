import React from 'react'
import {
  withSitecoreContext,
  Placeholder,
  RouteData,
} from '@sitecore-jss/sitecore-jss-react'

import Hero from './AboutUs-Hero'
import TabList, { SitecoreTab } from '../../page-components/IconTabs/Adapter'
import styles from './styles.module.scss'
import {
  TextField,
  BooleanField,
  LinkObjectField,
} from '../../types/SitecoreAdapter'
import { ResponsiveImageProps } from '../../page-components/Responsive-Image'
import { TrustPilotWidgetGlobalSettingsType } from '../../types/common'
import { get } from 'lodash-es'
import BottomCTA from '../../page-components/Bottom-CTA/WithContext'

type AboutUsPageProps = {
  sitecoreContext: {
    route: {
      fields: {
        hero: {
          fields: {
            heading: TextField
            isDark: BooleanField
            tabs: LinkObjectField[]
          } & ResponsiveImageProps
        }
        iconTabs: SitecoreTab[]
        tpWidgetGlobalSettings: TrustPilotWidgetGlobalSettingsType
      }
    } & RouteData
  }
}

function AboutUsPage({ sitecoreContext: { route } }: AboutUsPageProps) {
  const { hero, iconTabs, tpWidgetGlobalSettings } = route.fields
  const enableTrustPilotHeroWidget = get(
    tpWidgetGlobalSettings,
    'fields.enableTrustPilotHeroWidget.value'
  )

  return (
    <>
      <Hero
        desktopImage={hero.fields.desktopImage}
        tabletImage={hero.fields.tabletImage}
        mobileImage={hero.fields.mobileImage}
        heading={hero.fields.heading}
        isDark={hero.fields.isDark.value}
        applyDefaultImgSizing={true}
        showTrustPilotWidget={enableTrustPilotHeroWidget}
      />
      <Placeholder name="Hero-Placeholder" rendering={route} />
      <TabList tabs={iconTabs} className={styles.tabs} />
      <div className="copy about">
        <Placeholder name="About-Placeholder" rendering={route} />
        <BottomCTA />
      </div>
    </>
  )
}

export default withSitecoreContext()(AboutUsPage)
