type DataLayerAtLoad = [] | undefined

export type Label = string | number | null | undefined

export interface IGaEvent {
  event: string //TrackEvent
  category: string
  action: string
  label?: Label
  eventCallback?: () => void
}

declare global {
  interface Window {
    dataLayer: IGaEvent[]
  }
}

export function toDataLayer(ev: IGaEvent) {
  console.log('[Data Layer event] ', ev)

  getDataLayer().push(ev)
}

export function getDataLayer(): IGaEvent[] {
  window['dataLayer'] = <DataLayerAtLoad>window['dataLayer'] || []
  return window['dataLayer']
}

export function waitForDataLayer(ev: IGaEvent): Promise<void> {
  const task: Promise<void> = new Promise(resolve => {
    ev.eventCallback = () => resolve()
  })

  toDataLayer(ev)
  
  return task
}