import clsx from 'clsx'
import React from 'react'
import Slider from 'react-slick'

import Testimonial from '../Testimonial'
import { NextArrow, PrevArrow } from './Arrows'

import { CustomerTestimonialProps } from './domain'

import styles from './styles.module.scss'
import TrustPilotWidget from '../TrustPilot-Widget/TrustPilotWidget'

const CustomerTestimonial = ({
  testimonials,
  imageEl,
  widgetType,
  useTrustpilotWidget,
}: CustomerTestimonialProps) => {
  const settings = {
    className: clsx('center', styles.tabletSlider),
    centerMode: true,
    infinite: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    centerPadding: '35px',
    slidesToShow: 2,
    speed: 400,
    dots: false,
  }

  const testimonialEls = testimonials.map(Testimonial)

  return (
    <div>
      {useTrustpilotWidget ? (
        <TrustPilotWidget type={widgetType} />
      ) : (
        <>
          <Slider {...settings}>{testimonialEls}</Slider>
          <div className={styles.img}>{imageEl}</div>{' '}
        </>
      )}
    </div>
  )
}

export default CustomerTestimonial
