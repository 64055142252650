import React, { ReactNode } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

type MultiColumnProps = {
  left: ReactNode
  right: ReactNode
  mergeColumnsOnMobile?: boolean
  testId?: string
}

const MultiColumn = ({
  left,
  right,
  mergeColumnsOnMobile = false,
  testId = 'MultiColumnWrap',
}: MultiColumnProps) => {
  return (
    <div
      data-test-id={testId}
      className={clsx(
        spaceStyles.contain,
        styles.wrap,
        'block',
        'MultiColumn',
        mergeColumnsOnMobile && 'MergedColumns'
      )}
    >
      <div className={styles.col}>{left}</div>
      <div className={clsx(styles.col, 'bottomCol')}>{right}</div>
    </div>
  )
}

export default MultiColumn
