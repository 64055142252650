import React from 'react'
import styles from '../../styles/test-utils.module.scss'
import clsx from 'clsx'
import { FC } from '../../types/common'

type Props = {
  container?: boolean
  copy?: boolean
  article?: boolean
}

const Preview: FC<Props> = ({
  children,
  container = false,
  copy = true,
  article = true,
}) => (
  <div className={clsx(container && styles.container)}>
    {copy && (
      <div className={styles.previewWrap}>
        <div className={styles.bgCement}>
          <div className={styles.tag}>Copy</div>
        </div>
        <div className="copy">{children}</div>
      </div>
    )}
    {article && (
      <div className={styles.previewWrap}>
        <div className={styles.bgCement}>
          <div className={styles.tag}>Copy Article</div>
        </div>
        <div className="copy-article">{children}</div>
      </div>
    )}
  </div>
)

export default Preview
