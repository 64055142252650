import React from 'react'
import styles from './styles.module.scss'

type BodySidebarProps = {
  body: JSX.Element
  sidebar: JSX.Element
}

const BodySidebar = React.memo(({body, sidebar}: BodySidebarProps) =>
  <div className={styles.base}>
    <article className={styles.article}>{body}</article>
    <aside className={styles.aside} data-sidebar="sidebar">
      <div className={styles.inner} data-sidebar="inner">
        {sidebar}
      </div>
    </aside>
  </div>
)

export default BodySidebar