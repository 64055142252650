import React from 'react'
import { ControlProps, components } from 'react-select'
import styles from './styles.module.scss'
import searchIcon from './search.svg'
import { IOption } from '../../../types'

export default function SelectControl({
  children,
  ...props
}: ControlProps<IOption, false>) {
  return (
    <components.Control {...props}>
      {props.selectProps.hasSearchIcon && (
        <img src={searchIcon} className={styles.searchIcon} />
      )}
      {children}
    </components.Control>
  )
}
