import React from 'react'
import { LinkWrapperWithChildItems } from '../../../components/LinkWrapper'
import Media from '../../../components/Media'
import Columns from './Columns'
import styles from './styles.module.scss'
import { SecurityImagePropType } from '../DynamicFooter'

const SecurityImages = ({
  securityImages,
}: {
  securityImages: SecurityImagePropType[]
}) => {
  const desktopImages = securityImages.map(
    (item, i) =>
      item.image && (
        <LinkWrapperWithChildItems
          href={item.href}
          className={styles.image}
          key={i}
        >
          {item.image}
        </LinkWrapperWithChildItems>
      )
  )

  const mobileImages = securityImages.map(
    (item, i) =>
      item.mobileImage && (
        <LinkWrapperWithChildItems
          href={item.href}
          className={styles.image}
          key={i}
        >
          {item.mobileImage}
        </LinkWrapperWithChildItems>
      )
  )

  const Mobile = React.memo(() => (
    <Columns columnCount={2}>{mobileImages}</Columns>
  ))
  const Tablet = React.memo(() => (
    <Columns columnCount={4}>{desktopImages}</Columns>
  ))

  return (
    <div className={styles.wrap}>
      <Media mobile={() => <Mobile />} tablet={() => <Tablet />} />
    </div>
  )
}
export default React.memo(SecurityImages)
