import React from 'react'
import LinkWrapper from '../../components/LinkWrapper'

import styles from './styles.module.scss'

export type TagObjectProps = {
  label: string
  href: string
}

function Tag({ label, href }: TagObjectProps) {
  if (href) {
    return (
      <div className={styles.tag}>
        <LinkWrapper text={label} href={href} />
      </div>
    )
  }

  return <span className={styles.tag}>{label}</span>
}

export default Tag
