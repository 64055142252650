import React from 'react'
import {
  RouteData,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'

import TestPage from './test'
import HomePage from './home'
import PolicyPage from './policy'
import MajorPage from './major'
import MinorPage from './minor'
import ArticlePage from './article'
import LegalPage from './legal'
import AboutUsPage from './aboutUs'
import TagResultsPage from './tag-results'
import ErrorPage from './error'
import ScrollToTopOnMount from '../util/ScrollToTopOnMount'
import Nav from '../page-components/Navigation'
import Footer from '../page-components/Footer'
import Banner from '../page-components/Banner'

type PageTypeProps = {
  sitecoreContext: { route: RouteData }
}

function PageType({
  sitecoreContext: {
    route: { templateName },
  },
}: PageTypeProps) {

  const PageContent = () => {
    switch (templateName) {
      case 'Home Page':
        return <HomePage />
      case 'Policy Page':
        return <PolicyPage />
      case 'Major Page':
        return <MajorPage />
      case 'Minor Page':
        return <MinorPage />
      case 'Article Page':
        return <ArticlePage />
      case 'Legal Page':
        return <LegalPage />
      case 'About Page':
        return <AboutUsPage />
      case 'Tag Results Page':
        return <TagResultsPage />
      case 'Error Page':
        return <ErrorPage />
      default:
        return <TestPage />
    }
  }

  return (
    <div className={templateName}>
      <ScrollToTopOnMount />
      <Nav />
      <Banner />
      <PageContent />
      <Footer />
    </div>
  )

  
}

export default withSitecoreContext()(PageType)
