import React from 'react'
import SidebarSection from '../../../../components/SidebarSection'
import ListWrapper from './ListWrapper'
import { LinkObject } from '../../../../types/SitecoreAdapter'
import styles from './styles.module.scss'
import clsx from 'clsx'
import LinkWrapper from '../../../../components/LinkWrapper'

type LearnMoreProps = {
  links: LinkObject[]
}

const LearnMore = ({ links }: LearnMoreProps) => {
  return (
    <SidebarSection
      title="Learn More"
      className={clsx(styles.list, 'LearnMore')}
    >
      <ListWrapper>
        {links.map((link: LinkObject, index: number) => (
          <LinkWrapper
            href={link.href}
            title={link.title}
            text={link.body}
            className={styles.link}
            key={`link-${index}`}
          />
        ))}
      </ListWrapper>
    </SidebarSection>
  )
}

export default React.memo(LearnMore)
