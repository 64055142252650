// @format
import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'
import Media from '../Media'

type CustomImageBaseProps = {
  desktopImage: React.ReactNode
  tabletImage: React.ReactNode
  mobileImage: React.ReactNode
  caption?: React.ReactNode
  testId?: string
}

type CustomImageCoreProps = {
  image: React.ReactNode
  caption?: React.ReactNode
  className?: string
}

const CustomImageBase = ({
  desktopImage,
  tabletImage,
  mobileImage,
  caption,
  testId
}: CustomImageBaseProps) => {
  return (
    <div data-test-id="CustomImage" className={clsx(styles.container, 'CustomImage')}>
      <figure className={clsx(styles.imageWrap, 'block', spaceStyles.contain)}>
        <Media
          mobile={() => mobileImage}
          tablet={() => tabletImage}
          laptop={() => desktopImage}
        />
        {caption && (
          <figcaption className={styles.caption}>{caption}</figcaption>
        )}
      </figure>
    </div>
  )
}

export const CustomImageCore = React.memo(
  ({ image, caption, className }: CustomImageCoreProps) => {
    return (
      <div data-test-id="CustomImage" className={clsx(styles.container, 'CustomImage')}>
        <figure className={clsx(styles.imageWrap, className)}>
          {image}
          {caption && (
            <figcaption className={styles.caption}>{caption}</figcaption>
          )}
        </figure>
      </div>
    )
  }
)

export default CustomImageBase
