import React from 'react'

import styles from './styles.module.scss'
import { TextField } from '../../types/SitecoreAdapter'
import {
  ComponentConsumerProps,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import LinkWrapper, { LinkWrapperWithChildItems } from '../LinkWrapper'
import Media from '../Media'

type DisplayType = {
  variant?: string
}

export type LeadRoutingNumberProps = ComponentConsumerProps & {
  sitecoreContext: {
    route: {
      fields: {
        leadRoutingAttributes: {
          fields: {
            leadRoutingNumber: TextField
          }
        }
      }
    }
  }
  className?: string
  testId?: string
} & DisplayType

const LeadRoutingNumberAttribution = ({
  sitecoreContext: {
    route: {
      fields: {
        leadRoutingAttributes: {
          fields: { leadRoutingNumber },
        },
      },
    },
  },
  className,
  variant,
  testId,
}: LeadRoutingNumberProps) => {
  if (!leadRoutingNumber || leadRoutingNumber.value === '') {
    return null
  }

  const cleanNumberString = leadRoutingNumber.value.replace(/[^a-zA-Z0-9]/g, '')

  if (!variant) {
    return (
      <LinkWrapper
        text={leadRoutingNumber.value}
        href={`tel:+1${cleanNumberString}`}
        className={className}
      />
    )
  }

  function getAttributionText() {
    switch (variant) {
      case 'appStart':
        return (
          <Media
            mobile={() => (
              <span>
                Have a question? <br /> Give us a call:{' '}
              </span>
            )}
            tablet={() => <span>Have a question? Give us a call: </span>}
          />
        )
      case 'search':
        return (
          <span>
            Can't find what you're looking for? <br /> Give us a call:{' '}
          </span>
        )
      default:
        return ''
    }
  }

  return (
    <>
      {variant !== 'cta' ? (
        <div
          data-test-id={testId}
          className={clsx(styles.attribution, className)}
        >
          {getAttributionText()}
          <LinkWrapper
            className={styles.attr_number}
            text={leadRoutingNumber.value}
            href={`tel:+1${cleanNumberString}`}
          />
        </div>
      ) : (
        <LinkWrapperWithChildItems
          className={styles.ctaAttr}
          href={`tel:+1${cleanNumberString}`}
        >
          Or call us at{' '}
          <span className={styles.ctaAttr_number}>
            {leadRoutingNumber.value}
          </span>
        </LinkWrapperWithChildItems>
      )}
    </>
  )
}

export default withSitecoreContext()(LeadRoutingNumberAttribution)
