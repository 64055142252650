import React from 'react'
import styles from './styles.module.scss'

type ClearProps = {
  onClick: () => void
}

export default function CustomClear({ onClick }: ClearProps) {
  return (
    <button
      // Prevents React Select from swallowing click event on mobile
      onTouchEnd={e => e.stopPropagation()}
      onClick={onClick}
      className={styles.clear}
    >
      Clear
    </button>
  )
}
