import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router'

import Pagination from '../../components/Pagination'

import ContactSidebar from '../../components/Contact-Sidebar'
import NoTag from './NoTag'
import TagResults from './TagResults'
import BottomCTA from '../../page-components/Bottom-CTA/WithContext'

// TODO: Should type this and tag results component out
type TagResultsAdapterProps = {
  sitecoreContext: any
}

function TagResultsAdapter({ sitecoreContext }: TagResultsAdapterProps) {
  const navigate = useNavigate()

  if (!sitecoreContext.tagResults) {
    return (
      <>
        <NoTag />
      </>
    )
  }

  const {
    tagResults: {
      tagPath,
      tagName,
      cards,
      currentPage,
      totalResults,
      pageSize,
      canonicalUrl,
      urlBase,
      nextPath,
      prevPath,
      title,
      metaDescription,
      relatedTags,
    },
  } = sitecoreContext

  // Next and previous urls are divided into parts, urlBase and an absolute path.
  // The client-side routing, using React Router, can use the absolute path.
  // The <link rel="next" ..> and <link rel="prev" ..> tags use urls that include hostname.

  const handleNext = (event: MouseEvent) => {
    // don't use the default link behavior
    event.preventDefault()
    // use React Router instead
    navigate(nextPath)
  }

  const handlePrevious = (event: MouseEvent) => {
    // don't use the default link behavior
    event.preventDefault()
    // use React Router instead
    navigate(prevPath)
  }

  const nextMeta = nextPath && <link rel="next" href={urlBase + nextPath} />
  const prevMeta = prevPath && <link rel="prev" href={urlBase + prevPath} />

  const paging = (
    <Pagination
      currentPage={currentPage}
      totalResults={totalResults}
      pageSize={pageSize}
      nextPath={nextPath}
      prevPath={prevPath}
      onNextClick={handleNext}
      onPreviousClick={handlePrevious}
    />
  )

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content="index, follow" />
        <meta name="description" content={metaDescription} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:url" content={canonicalUrl} />

        {nextMeta}
        {prevMeta}
      </Helmet>

      <TagResults
        tagName={tagName}
        cards={cards}
        contact={<ContactSidebar />}
        pagination={paging}
        relatedTags={relatedTags}
        ctaBanner={<BottomCTA />}
      />
    </>
  )
}

export default withSitecoreContext()(TagResultsAdapter)
