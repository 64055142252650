import React, { ReactNode } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

type PullQuoteProps = {
  body: ReactNode
  attribution: ReactNode
  testId?: string
}

const PullQuote = ({ body, attribution, testId }: PullQuoteProps) => {
  return (
    <div data-test-id={testId} className={clsx(spaceStyles.contain, styles.root)}>
      <div className={styles.body}>{body}</div>
      {attribution && <div className={styles.attribution}>{attribution}</div>}
    </div>
  )
}

export default React.memo(PullQuote)
