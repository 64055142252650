import React, { useContext } from 'react'
import { Status } from '../../state-management/states'
import {
  SelectFocus,
  SelectCTA,
  ModalClose,
} from '../../state-management/events'
import {
  triggerInputClick,
  triggerButtonClick,
} from '../../analytics/search-app-analytics'
import SelectInert from '../../components/Select/SelectInert'
import QuoteButton from '../../components/QuoteButton'
import { AppStartSettingsContext } from '../../context'
import {
  CloseCallback,
  DispatchProp,
  SearchAppStartEmbedProps,
} from '../../state-management/container'
import TypeaheadContent from '../../components/TypeaheadContent'
import Modal from '../../../../../page-components/Modal'
import styles from './styles.module.scss'

export default function DesktopHeroSearchAppStart({
  loadOptions,
  appState,
  dispatch,
  callbacks: { onInput, onClose },
}: SearchAppStartEmbedProps) {
  const {
    buttonText,
    placeholder,
    modalHeading,
    preselectShowInput,
    confirmButtonLabel,
  } = useContext(AppStartSettingsContext)

  if (appState.status === Status.Inert) {
    return (
      <div className={styles.control}>
        {preselectShowInput && (
          <SelectInert
            value={appState.selection}
            placeholder={placeholder}
            onFocus={() => {
              triggerInputClick()
              dispatch(SelectFocus)
            }}
          />
        )}

        <div className={styles.desktopButton}>
          <QuoteButton
            onClick={() => {
              triggerButtonClick()
              dispatch(SelectCTA)
            }}
          >
            {buttonText}
          </QuoteButton>
        </div>
      </div>
    )
  }

  if (appState.status === Status.AppStart && appState.direct === true) {
    return (
      <div className={styles.control}>
        {preselectShowInput && (
          <SelectInert
            placeholder={placeholder}
            value={appState.selection}
            isDisabled={true}
          />
        )}

        <div className={styles.desktopButton}>
          <QuoteButton loading disabled>
            {buttonText}
          </QuoteButton>
        </div>
      </div>
    )
  }

  return (
    <ModalAndBackground
      onClose={onClose}
      dispatch={dispatch}
      preselectShowInput={preselectShowInput}
      placeholder={placeholder}
      modalHeading={modalHeading}
      buttonText={buttonText}
    >
      <TypeaheadContent
        appState={appState}
        confirmButtonLabel={confirmButtonLabel}
        dispatch={dispatch}
        loadOptions={loadOptions}
        onInput={onInput}
        placeholder={placeholder}
      />
    </ModalAndBackground>
  )
}

type ModalAndBackgroundProps = {
  children: React.ReactNode
  preselectShowInput: boolean
  buttonText: string
  placeholder: string
  modalHeading: React.ReactElement
} & CloseCallback &
  DispatchProp

// extract ModalAndBackground component in order to have scroll block attached once (prevents flashing)
function ModalAndBackground({
  onClose,
  dispatch,
  preselectShowInput,
  placeholder,
  buttonText,
  modalHeading,
  children,
}: ModalAndBackgroundProps) {
  const handleClose = () => {
    onClose && onClose()
    dispatch(ModalClose)
  }

  return (
    <>
      <div className={styles.control}>
        {preselectShowInput && <SelectInert placeholder={placeholder} />}
        <div className={styles.desktopButton}>
          <QuoteButton>{buttonText}</QuoteButton>
        </div>
      </div>
      <Modal onClose={handleClose} modalHeading={modalHeading} desktopSize="md">
        {children}
      </Modal>
    </>
  )
}
