import React from 'react'
import styles from './styles.module.scss'

export type IframeProps = {
  id: string
  src: string
  allowFullscreen?: boolean
}

const YoutubeIframe = ({ id, src, allowFullscreen = true }: IframeProps) => {
  return (
    <div className={styles.parent} data-nosnippet>
      <iframe
        id={id}
        src={src}
        className={styles.child}
        frameBorder={0}
        allowFullScreen={allowFullscreen}
        loading="lazy"
      ></iframe>
    </div>
  )
}

export default React.memo(YoutubeIframe)
