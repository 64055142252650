import React from 'react'
import { ImageCloneWrapper } from '../ImageWrapper'

import { LinkWrapperProps, LinkWrapperWithChildItemsProps } from './LinkTypes'

export const LinkWrapperWithChildItems = (props: LinkWrapperWithChildItemsProps) => {
  return (
    <a
      href={props.href}
      title={props.title || props.text}
      className={props.className}
      target={props.newWindow ? '_blank' : ''}
      onClick={props.onClick}
    >
      {props.children}
    </a>
  )
}

const LinkWrapper = (props: LinkWrapperProps) => {
  return (
    <a
      href={props.href}
      title={props.title}
      className={props.className}
      target={props.newWindow ? '_blank' : ''}
      onClick={props.onClick}
    >
      {props.image
        ? React.createElement(props.image.type, { 
          ...props.image.props,
          className: props.imageStyles
        })
        : props.text
      }
    </a>
  )
}

export default LinkWrapper
