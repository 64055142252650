import React from 'react'
import SubmenuLinkSection from './SubmenuLinkSection'
import SubmenuBrightHeading from './SubmenuBrightHeading'

const SubmenuContent = React.memo(({ specs }) => {
  return specs.map((child, index) => {
    if (child.type === 'Heading') {
      const { heading } = child
      return <SubmenuBrightHeading heading={heading} key={index} />
    }

    if (child.type === 'LinkSection') {
      const { links, title } = child
      return <SubmenuLinkSection links={links} title={title} key={index} />
    }

    return null
  })
})

export default SubmenuContent