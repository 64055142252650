import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

const Container = React.memo(({ isOpen, onClose, children }) => (
  <div
    className={clsx(styles.megaMenu, isOpen && styles.isOpen)}
    onMouseLeave={onClose}
  >
    {children}
  </div>
))

export default Container
