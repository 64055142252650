import React from 'react'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import styles from '../styles.module.scss'
import clsx from 'clsx'

class Submenu extends React.PureComponent {
  targetElement = null

  componentDidUpdate() {
    this.targetElement.scrollTop = 0
    
    if (this.props.isOpen) {
      disableBodyScroll(this.targetElement)
    } else {
      enableBodyScroll(this.targetElement)
    }
  }

  // we probably will not unmount the nav, but just in case, clean up
  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  render() {
    const { isOpen, children } = this.props

    return (
      <div
        ref={el => (this.targetElement = el)}
        className={clsx(styles.subMenu, isOpen && styles.isOpen)}
      >
        {children}
      </div>
    )
  }
}

export default Submenu
