import React from 'react'
import { AppSearch_States, Status } from '../../state-management/states'
import {
  RejectSelection,
  ConfirmSelection,
} from '../../state-management/events'
import { selectProfession } from '../../state-management/reducer'
import SelectAsync, {
  ILoadOptionsCallback,
} from '../Select/SelectAsync'
import SelectInert from '../Select/SelectInert'
import { DispatchProp } from '../../state-management/container'
import Button from '../../../../../components/Button'
import styles from './styles.module.scss'

type TypeaheadContentProps = {
  appState: AppSearch_States
  confirmButtonLabel: string
  onInput: (searchInput: string) => void
  placeholder: string
} & ILoadOptionsCallback &
  DispatchProp

export default function DesktopTypeaheadContent({
  dispatch,
  placeholder,
  appState,
  confirmButtonLabel,
  loadOptions,
  onInput,
}: TypeaheadContentProps) {
  return confirmBodyActive() || confirmBodyLoading() || modalBody() || null

  function confirmBodyActive() {
    if (
      appState.status === Status.Searching &&
      appState.selection !== undefined
    ) {
      return (
        <>
          <SelectInert
            className={styles.inlineSelectRoot}
            value={appState.selection}
            placeholder={placeholder}
            onFocus={() => {
              dispatch(RejectSelection)
            }}
          />
          <Button
            className={styles.confirmButton}
            size="xlarge"
            testId="ConfirmButton"
            onClick={() => dispatch(ConfirmSelection)}
          >
            {confirmButtonLabel}
          </Button>
        </>
      )
    }
  }

  function confirmBodyLoading() {
    if (appState.status === Status.AppStart) {
      return (
        <>
          <SelectInert
            className={styles.inlineSelectRoot}
            value={appState.selection}
            placeholder={placeholder}
            isDisabled
          />
          <Button
            className={styles.confirmButton}
            size="xlarge"
            testId="ConfirmButton"
            disabled
            loading
          >
            {confirmButtonLabel}
          </Button>
        </>
      )
    }
  }

  function modalBody() {
    if (
      appState.status === Status.Searching ||
      appState.status === Status.AppStart
    ) {
      return (
        <div className={styles.horizontalSelectRoot}>
          <SelectAsync
            loadOptions={loadOptions}
            onSelect={option => {
              dispatch(selectProfession(option))
            }}
            onInput={onInput}
          />
        </div>
      )
    }
  }
}
