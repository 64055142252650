import React from 'react'
import clsx from 'clsx'
import Slider from 'react-slick'

import Testimonial from '../Testimonial'
import { NextArrow, PrevArrow } from './Arrows'
import { CustomerTestimonialProps } from './domain'

import styles from './styles.module.scss'
import TrustPilotWidget from '../TrustPilot-Widget/TrustPilotWidget'

const CustomerTestimonial = ({
  testimonials,
  imageEl,
  widgetType,
  useTrustpilotWidget,
}: CustomerTestimonialProps) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 3,
    slidestoScroll: 1,
    centerMode: true,
  }

  const testimonialEls = testimonials.map(Testimonial)

  return (
    <div>
      <div className={clsx(styles.wrap, 'slick-wrapper')}>
        {useTrustpilotWidget ? (
          <TrustPilotWidget type={widgetType} />
        ) : (
          <Slider {...settings}>{testimonialEls}</Slider>
        )}
      </div>
      {!useTrustpilotWidget && <div className={styles.img}>{imageEl}</div>}
    </div>
  )
}

export default CustomerTestimonial
