import React from 'react'
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react'

import CustomImageBase from './CustomImage'
import { ImageField, TextField } from '../../types/SitecoreAdapter'

type CustomImageProps = {
  fields: {
    image: ImageField
    caption?: TextField
    tabletImage?: ImageField
    mobileImage?: ImageField
  }
}

const CustomImageAdapter = ({
  fields: { image, caption, tabletImage, mobileImage }
}: CustomImageProps) => {
  const imageCaption = caption && caption.value && <RichText field={caption} />

  const mobileImg = mobileImage && mobileImage.value.src ? mobileImage : image
  const tabletImg = tabletImage && tabletImage.value.src ? tabletImage : image

  return (
    <CustomImageBase
      desktopImage={<Image field={image} />}
      mobileImage={<Image field={mobileImg} />}
      tabletImage={<Image field={tabletImg} />}
      caption={imageCaption}
    />
  )
}

export default CustomImageAdapter
