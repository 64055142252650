import React from 'react'
import CTALinkIntercept from '../../../../../quotes/CTALinkIntercept'
import clsx from 'clsx'
import styles from './styles.module.scss'
import PageTypography from '../../../../PageTypography'

import { CarouselRouterContentProps } from '../types'

type WrapperProps = {
  children: JSX.Element
  pageStyles?: string
} & CarouselRouterContentProps

export default function({
  children,
  pageStyles,
  content: { heading, subhead, escapeText },
}: WrapperProps) {
  const carouselClasses = clsx(
    !heading && !subhead && styles.carouselOnly,
    styles.carousel
  )

  return (
    <PageTypography>
      {(pageType: string) => (
        <div
          data-test-id={'UniversalRouter-Wrap'}
          className={clsx(
            'block contain UniversalRouter',
            styles.root,
            pageType === 'blog' && styles.blogPadding,
            pageStyles
          )}
        >
          {heading && (
            <div className={styles.heading}>{heading}</div>
          )}
          {subhead && <div className={styles.subheading}>{subhead}</div>}
          <div className={carouselClasses}>{children}</div>
          {escapeText && (
            <div className={styles.escapeText}>
              <CTALinkIntercept preSelectDisabled={true}>{escapeText}</CTALinkIntercept>
            </div>
          )}
        </div>
      )}
    </PageTypography>
  )
}
