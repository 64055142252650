import clsx from 'clsx'
import styles from './styles.module.scss'
import React from 'react'
import { CrossSellPolicyFields } from './types'
import HeadingBanner from './Banner'
import AdditionalPolicies from './AdditionalPolicies'

type Props = {
  fields?: CrossSellPolicyFields
}

const CrossSellPolicies: React.FC<Props> = ({ fields }) => {
  if (fields && fields.relatedPolicies) {
    return (
      <div className={clsx(styles.crossSellPoliciesWrap, 'block', 'contain')}>
        <HeadingBanner {...fields} />
        <AdditionalPolicies relatedPolicies={fields.relatedPolicies} />
      </div>
    )
  } else return null
}

export default CrossSellPolicies
