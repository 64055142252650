import React, { ReactNode } from 'react'
import DefaultMedia, { MediaProps } from '../../components/Media'
import { Image } from '@sitecore-jss/sitecore-jss-react'
import { ImageField } from '../../types/SitecoreAdapter'
import {
  Breakpoints as defaultBreakpoints,
  ImageBreakpoints,
} from './breakpoints'

export type ResponsiveImageFields = {
  mobileImage: ImageField
  tabletImage: ImageField
  desktopImage: ImageField
}

type ImageProps = {
  className?: string
}

export type ResponsiveImageProps = ResponsiveImageFields & {
  breakpoints?: ImageBreakpoints
  Media?: React.FC<MediaProps>
  applyDefaultImgSizing?: boolean
} & ImageProps

type ImageGraph = {
  mobile?: () => ReactNode
  tablet?: () => ReactNode
  laptop?: () => ReactNode
  desktop?: () => ReactNode
}

type SizeParams = {
  [paramName: string]: string | number
}

function getImage(
  field: ImageField,
  imageParams?: SizeParams,
  className?: string
) {
  return <Image field={field} imageParams={imageParams} className={className} />
}

export function imageHasSrc(field: ImageField) {
  return field && field.value && field.value.src
}

const imageGraph = (
  breakpoints: ImageBreakpoints,
  applyDefaultImgSizing: boolean,
  className?: string
) => (
  mobile: ImageField,
  tablet: ImageField,
  desktop: ImageField
): ImageGraph => {
  // Default graph consists of each entry falling back to desktop
  const graph: ImageGraph = {
    desktop: () => getImage(desktop, {}, className),
    tablet: () => getImage(desktop, { mw: breakpoints.smMax }, className),
    mobile: () =>
      getImage(
        desktop,
        {
          mw: breakpoints.xsMax,
          // 35 rem - Average value based on the height of content
          // currently only applicable for hero images
          mh: applyDefaultImgSizing ? 700 : '',
        },
        className
      ),
  }

  if (imageHasSrc(tablet)) {
    graph.tablet = () => getImage(tablet, { mw: breakpoints.smMax }, className)
  }

  if (imageHasSrc(mobile)) {
    graph.mobile = () => getImage(mobile, { mw: breakpoints.xsMax }, className)
  }

  return graph
}

function ResponsiveImage({
  mobileImage,
  tabletImage,
  desktopImage,
  breakpoints = defaultBreakpoints,
  Media = DefaultMedia,
  applyDefaultImgSizing = false,
  className,
}: ResponsiveImageProps) {
  const images = imageGraph(breakpoints, applyDefaultImgSizing, className)(
    mobileImage,
    tabletImage,
    desktopImage
  )
  return <Media {...images} />
}

export default ResponsiveImage
