import React, { memo } from 'react'
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react'
import MainHeading from './MainHeading'
import {
  SitecoreContextType,
  TextField,
} from '../../../../types/SitecoreAdapter'

type MainHeadingAdapterProps = SitecoreContextType<{
  pageHeading: TextField
}>

const MainHeadingAdapter = memo(
  ({
    sitecoreContext: {
      route: {
        fields: { pageHeading },
      },
    },
  }: MainHeadingAdapterProps) => {
    return (
      <MainHeading>
        <Text field={pageHeading} />
      </MainHeading>
    )
  }
)

export default withSitecoreContext()(MainHeadingAdapter)
