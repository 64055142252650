import React, { useEffect, useRef } from 'react'
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import CarouselWidget from './components/CarouselWidget'
import FooterWidget from './components/FooterWidget'
import MicroStarWidget from './components/MicroStarWidget'
import MicroComboWidget from './components/MicroComboWidget'
import GridWidget from './components/GridWidget'
import ProductReviewWidget from './components/ProductReviewWidget'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { IValue } from '../../types/SitecoreAdapter'
import PageTypography from '../PageTypography'

type TP_BaseProps = {
  heading?: IValue<string>
  subHeading?: IValue<string>
  type: string
  sku?: string
  productName?: string
}

export type TP_AdditionalProps = {
  hideWidgetFooter?: boolean
  isDark?: boolean
  variant?: string
  externalStyles?: string
}

export type TrustPilotWidgetProps = TP_BaseProps & TP_AdditionalProps

declare var window: any

const TrustPilotWidget = ({
  heading,
  subHeading,
  type,
  hideWidgetFooter = false,
  sku,
  productName,
  isDark,
  variant,
  externalStyles,
}: TrustPilotWidgetProps) => {
  const trustBoxRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let timer = setTimeout(() => {
      if (
        typeof window !== 'undefined' &&
        typeof window.Trustpilot !== 'undefined'
      ) {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        window.Trustpilot.loadFromElement(trustBoxRef.current, true)
      }
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  })

  const addonProps: TP_AdditionalProps = {
    hideWidgetFooter,
    isDark,
    variant,
  }

  const WidgetBody = () => {
    switch (type.trim().toLowerCase()) {
      case 'microstar':
        return <MicroStarWidget ref={trustBoxRef} {...addonProps} />
      case 'microcombo':
      case 'micro':
        return <MicroComboWidget ref={trustBoxRef} {...addonProps} />
      case 'line':
        return (
          <FooterWidget
            ref={trustBoxRef}
            {...{ externalStyles, ...addonProps }}
          />
        )
      case 'carousel':
        return <CarouselWidget ref={trustBoxRef} {...addonProps} />
      case 'grid':
        return <GridWidget ref={trustBoxRef} {...addonProps} />
      case 'product review':
        return (
          <ProductReviewWidget
            ref={trustBoxRef}
            sku={sku}
            productName={productName}
            {...addonProps}
          />
        )
      default:
        return <MicroStarWidget ref={trustBoxRef} {...addonProps} />
    }
  }

  const headingEl =
    heading && heading.value !== '' ? (
      <Text field={heading} tag="div" className="h2" />
    ) : null

  const subHeadingEl =
    subHeading && subHeading.value !== '' ? (
      <div className={clsx(styles.subHeading, 'descriptor')}>
        <RichText field={subHeading} />
      </div>
    ) : null

  const WidgetHeader = () => (
    <PageTypography>
      {(pageType: string) => (
        <div
          className={clsx(
            pageType !== 'home' && 'contain',
            styles.headingContainer
          )}
          id="TPWidgetHeadingContainer"
        >
          {headingEl}
          {subHeadingEl}
        </div>
      )}
    </PageTypography>
  )

  return (
    <>
      <WidgetHeader />
      <div className={externalStyles}>
        <WidgetBody />
      </div>
    </>
  )
}

export default TrustPilotWidget
