import React, { FC } from 'react'
import H2AndDescriptor from './H2AndDescriptor'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import { TextField } from '../../types/SitecoreAdapter'

type Props = {
  fields: {
    heading: TextField
    descriptor: TextField
  }
  testId?: string
}

const H2AndDescriptorAdapter: FC<Props> = ({
  fields: { heading, descriptor }, testId
}) => {
  return (
    <H2AndDescriptor
      testId={testId}
      heading={<Text field={heading} />}
      descriptor={<RichText field={descriptor} />}
    />
  )
}

export default H2AndDescriptorAdapter
