import React from 'react'
import styles from './../styles.module.scss'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { CrossSellPolicyFields } from '../types'
import { getResponsiveHeroImage } from '../../../page-components/Responsive-Image/HeroImageAdapter'

const HeadingBanner: React.FC<CrossSellPolicyFields> = ({
  title,
  desktopImage,
  mobileImage,
  tabletImage,
  backgroundImage,
}) => {
  const bannerImage = getResponsiveHeroImage({
    mobileImage,
    tabletImage,
    desktopImage,
    backgroundImage,
    className: styles.bannerImage,
  })
  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerWrap}>
        <div className={styles.bannerImageWrap}>{bannerImage}</div>
        <div className={styles.bannerHeadingWrap}>
          <Text tag="h2" className={styles.bannerHeading} field={title} />
        </div>
      </div>
    </div>
  )
}

export default HeadingBanner
