import React, { FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

type SeeMoreLinkProps = {
  href: string
  className?: string
  body: React.ReactNode
}

const SeeMoreLink: FC<SeeMoreLinkProps> = ({ href, body, className }) => {
  return (
    <>
      {href && body && (
        <a href={href} className={clsx(styles.moreLink, className)}>
          {body}
        </a>
      )}
    </>
  )
}

export default SeeMoreLink
