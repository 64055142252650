import React from 'react'
import {
  withSitecoreContext,
  Placeholder,
  RouteData,
} from '@sitecore-jss/sitecore-jss-react'

type ArticlePlaceholderProps = {
  sitecoreContext: {
    route: RouteData
  }
}

const ArticlePlaceholder = ({ sitecoreContext: { route } }: ArticlePlaceholderProps) => (
  <Placeholder name="Article-Placeholder" rendering={route} />
)

export default withSitecoreContext()(React.memo(ArticlePlaceholder))
