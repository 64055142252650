/**
 * SSR/client  conditionals should import from here
 * so if we change how we detect server/client it's in one place.
 * 
 * Usage:
```
import { isServer } from '../../util/getEnv'

if (isServer) {
  return <Placeholder />
}
```
 */

//@ts-ignore global
export const isServer = __SERVER__

//@ts-ignore global
export const isClient = __CLIENT__