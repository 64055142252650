import React from 'react'
import {
  withSitecoreContext,
  Image,
  RichText,
  ComponentConsumerProps,
} from '@sitecore-jss/sitecore-jss-react'
import {
  TextField,
  ImageField,
  BooleanField,
  DateField,
} from '../../types/SitecoreAdapter'
import Banner from './Banner'

export type BannerAdapterProps = ComponentConsumerProps & {
  sitecoreContext: {
    route: {
      fields: {
        banner: {
          fields: {
            display: BooleanField
            text: TextField
            icon: ImageField
            dateUp: DateField
            dateDown: DateField
          }
        }
      }
    }
  }
}

const BannerAdapter = ({
  sitecoreContext: {
    route: {
      fields: { banner },
    },
  },
}: BannerAdapterProps) => {
  if (!banner || !banner.fields.dateUp || !banner.fields.dateDown) {
    return null
  }

  const todaysDate = new Date()

  if (
    banner.fields.dateUp.value === '0001-01-01T00:00:00Z' ||
    banner.fields.dateDown.value === '0001-01-01T00:00:00Z' ||
    todaysDate < new Date(banner.fields.dateUp.value) ||
    todaysDate > new Date(banner.fields.dateDown.value)
  ) {
    return null
  }

  const text = <RichText field={banner.fields.text} />
  const icon = banner.fields.icon && <Image field={banner.fields.icon} />
  return <Banner text={text} icon={icon} />
}

export default withSitecoreContext()(BannerAdapter)
