import React, { useContext } from 'react'
import clsx from 'clsx'

import Button from '../Button'
import Video from '../Video/Video'
import { getIdFromUrl, getEmbedUrlFromId } from '../Video/services/getEmbedUrl'

import { ModalCTAContext } from '../../context/ModalCTAContext'

type SingleColumnVideoProps = {
  heading?: string
  descriptor?: React.ReactElement | boolean
  videoUrl: string
  ctaButtonText?: string
  isBottomDescriptor?: boolean
  headingSize?: string
  useBodyText?: boolean
  testId?: string
}

type StylingProps = {
  styles: { [key: string]: string }
}

const SingleColumnVideo = ({
  heading,
  descriptor,
  videoUrl,
  ctaButtonText,
  isBottomDescriptor,
  headingSize,
  useBodyText,
  styles,
  testId
}: SingleColumnVideoProps & StylingProps) => {
  const { showModal } = useContext(ModalCTAContext)
  const videoId = getIdFromUrl(videoUrl) || ''
  const videoEmbedUrl = videoId ? getEmbedUrlFromId(videoId) : ''
  const h3OverrideClass = (headingSize && headingSize === 'H3')? styles.smallHeader: ''
  const descriptorStyleClass = useBodyText ? 'BodyText': styles.descriptor

  return (
    <div data-test-id={testId} className={styles.singleColumnVideo}>
      {heading && headingSize === 'h3' ? (
        <h3 className={clsx(styles.header, h3OverrideClass)}>
          {heading}
        </h3>
      ): (
        <h2 className={clsx(styles.header)}>
          {heading}
        </h2>
      )}
      {descriptor && !isBottomDescriptor && (
        <div
          className={clsx(
            styles.topDescriptor,
            descriptorStyleClass
          )}
        >
          {descriptor}
        </div>
      )}
      <div className={styles.videoComponent}>
        <Video id={videoId} embedUrl={videoEmbedUrl} lazyLoad={true} />
      </div>
      {descriptor && isBottomDescriptor && (
        <div
          className={clsx(
            styles.bottomDescriptor,
            descriptorStyleClass
          )}
        >
          {descriptor}
        </div>
      )}
      {ctaButtonText && (
        <div className={styles.ctaButtonComponent}>
          <Button
            className={clsx('button', styles.ctaButton)}
            type="primary"
            onClick={showModal}
          >
            {ctaButtonText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default SingleColumnVideo
