import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import TrustPilotWidget from '../../../components/TrustPilot-Widget/TrustPilotWidget'
import Media from '../../../components/Media'

type HomePageHeroProps = {
  backgroundImage: React.ReactNode
  heading: React.ReactNode
  descriptor: React.ReactNode
  isDark: boolean
  children?: React.ReactNode
  showTrustPilotWidget: boolean
}

const HomePageHero = ({
  backgroundImage,
  heading,
  descriptor,
  isDark,
  children,
  showTrustPilotWidget = true,
}: HomePageHeroProps) => {
  return (
    <div
      className={clsx(styles.hero, isDark && styles.dark)}
      data-test-context="HomeHero"
    >
      <div className={styles.imageWrapper}>{backgroundImage}</div>
      <div className={styles.overlay} />
      <div className={styles.content}>
        <h1 className={styles.heading}>{heading}</h1>
        {showTrustPilotWidget && (
          <div
            className={styles.homeHeroTPWidget}
            data-test-id={'home-hero-tp-widget'}
          >
            <Media
              mobile={() => (
                <TrustPilotWidget isDark={isDark} type="MicroStar" />
              )}
              tablet={() => <TrustPilotWidget isDark={isDark} type="Micro" />}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

export default HomePageHero
