import React, { useContext } from 'react'
import { Status } from '../../state-management/states'
import { ModalOpen } from '../../state-management/events'
import { AppStartSettingsContext } from '../../context'
import SearchAppStartContainer from '../../state-management/container'
import styles from './styles.module.scss'
import TypeaheadContent from '../../components/TypeaheadContent'

// this is not ideal
import headingStyles from '../../../../../page-components/Modal/heading.module.scss'

export default function ModalSearchAppStart() {
  const {
    cookieError,
    modalHeading,
    confirmButtonLabel,
    placeholder,
  } = useContext(AppStartSettingsContext)

  return (
    <SearchAppStartContainer>
      {({
        appState,
        dispatch,
        loadOptions,
        callbacks: { onInput },
      }) => {
        // cookie check fails
        if (appState.status === Status.BrowserError) {
          return cookieError
        }

        if (appState.status === Status.Inert) {
          dispatch(ModalOpen)
        }

        return (
          <div
            className={styles.root}
            data-test-context="SearchAppStart"
            data-embed="Modal"
          >
            <div className={headingStyles.heading}>{modalHeading}</div>
            <TypeaheadContent
              appState={appState}
              confirmButtonLabel={confirmButtonLabel}
              dispatch={dispatch}
              loadOptions={loadOptions}
              onInput={onInput}
              placeholder={placeholder}
            />
          </div>
        )
      }}
    </SearchAppStartContainer>
  )
}
