import React, { memo } from 'react'
import TableBase from './Table'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import { TextField } from '../../types/SitecoreAdapter'
import { isEmpty, toNumber } from 'lodash-es'
import { TableProps } from './types'
import Banner from '../../page-components/Banner/Banner'

const isLargerFirstColumn = (layout: string) => layout === 'largerFirstColumn'

export function fieldsOfType(
  fields: { [key: string]: TextField },
  type: string
): JSX.Element[] {
  return Object.entries(fields) // force-break
    .filter(([name, value]) => name.startsWith(type))
    .sort(([firstName], [secondName]) => firstName.localeCompare(secondName))
    .map(([, value]) => <RichText field={value} />)
}

const Table = ({ fields }: TableProps) => {
  const {
    numColumns: { value: numColumns },
    boldRowHeadings: { value: boldRowHeadings },
    squash: { value: squash },
    layout: { value: layout },
  } = fields

  return !isEmpty(fields.rows) ? (
    <TableBase
      numberOfColumns={toNumber(numColumns)}
      data={fields.rows}
      boldRowHeadings={boldRowHeadings}
      squash={squash}
      largerFirstColumn={isLargerFirstColumn(layout)}
    />
  ) : (
    <Banner text={<>Please select rows for the Table</>} />
  )
}

const TableAdaptor = ({ fields }: TableProps) => {
  return !isEmpty(fields) ? <Table fields={fields} /> : <></>
}

export default memo(TableAdaptor)
