import React from 'react'
import SidebarSection from '../SidebarSection'
import styles from './styles.module.scss'
import { RelatedPolicyLink } from './index'
import LinkWrapper from '../LinkWrapper'

type RelatedPoliciesProps = {
  title: string
  links: RelatedPolicyLink[]
}

const RelatedPolicies = ({ title, links }: RelatedPoliciesProps) => {
  const linkEls = links.map(({ icon, linkText, href }: RelatedPolicyLink) => {
    return <div className={styles.linkWrap} key={href}>
      {React.createElement(icon.type, {
        ...icon.props,
        className: styles.image,
      })}
      <LinkWrapper text={linkText} href={href} className={styles.link} />
    </div>
  })

  return <SidebarSection title={title}>{linkEls}</SidebarSection>
}

export default React.memo(RelatedPolicies)
