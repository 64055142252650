import React from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'

import SingleColumnVideo from './SingleColumnVideo'
import MultiColumnVideo, { AlignmentOption } from './MultiColumnVideo'

import { BooleanField, TextField } from '../../types/SitecoreAdapter'

import PageTypography from '../PageTypography'

import articleStyles from './styles-article.module.scss'
import copyStyles from './styles-copy.module.scss'
import Media from '../Media'

export type VariantOption = 'SingleCol' | 'MultiCol'

export type HeadingSizeOption = 'H2' | 'H3'

type VideoComponentProps = {
  fields: {
    heading: TextField
    descriptor: TextField
    videoUrl: TextField
    ctaButtonText: TextField
    isBottomDescriptor: BooleanField
    variant: { value: VariantOption }
    alignment: { value: AlignmentOption }
    headingSize: { value: HeadingSizeOption }
    useBodyText: BooleanField
  },
  testId?: string
}

const VideoComponent = ({ fields, testId }: VideoComponentProps) => {
  const descriptorElCheck = fields.descriptor && fields.descriptor.value !== ''

  const DesktopComponent = () => {
    return (
      <>
        {fields.variant && fields.variant.value === 'MultiCol' ? (
          <div data-test-id={testId} className={copyStyles.multiColumnVideoContainer}>
            <MultiColumnVideo
              heading={fields.heading.value}
              headingSize={fields.headingSize && fields.headingSize.value}
              descriptor={<RichText field={fields.descriptor} />}
              videoUrl={fields.videoUrl.value}
              ctaButtonText={fields.ctaButtonText.value}
              alignment={fields.alignment && fields.alignment.value}
              styles={copyStyles}
              useBodyText={fields.useBodyText && fields.useBodyText.value}
              testId={testId}
            />
          </div>
        ) : (
          <div data-test-id={testId} className={articleStyles.singleColumnVideoContainer}>
            <PageTypography>
              {(pageType: string) => (
                <SingleColumnVideo
                  heading={fields.heading.value}
                  headingSize={fields.headingSize && fields.headingSize.value}
                  descriptor={
                    descriptorElCheck && <RichText field={fields.descriptor} />
                  }
                  videoUrl={fields.videoUrl.value}
                  ctaButtonText={fields.ctaButtonText.value}
                  isBottomDescriptor={fields.isBottomDescriptor.value}
                  styles={
                    pageType === 'article' || pageType === 'policy'
                      ? articleStyles
                      : copyStyles
                  }
                  useBodyText={fields.useBodyText && fields.useBodyText.value}
                  testId={testId}
                />
              )}
            </PageTypography>
          </div>
        )}
      </>
    )
  }

  const MobileComponent = () => {
    return (
      <div data-test-id={testId} className={articleStyles.singleColumnVideoContainer}>
        <PageTypography>
          {(pageType: string) => (
            <SingleColumnVideo
              heading={fields.heading.value}
              headingSize={fields.headingSize && fields.headingSize.value}
              descriptor={
                descriptorElCheck && <RichText field={fields.descriptor} />
              }
              videoUrl={fields.videoUrl.value}
              ctaButtonText={fields.ctaButtonText.value}
              isBottomDescriptor={
                fields.variant && fields.variant.value === 'MultiCol'
                  ? true
                  : fields.isBottomDescriptor.value
              }
              styles={
                pageType === 'article' || pageType === 'policy'
                  ? articleStyles
                  : copyStyles
              }
              testId={testId}
            />
          )}
        </PageTypography>
      </div>
    )
  }

  return (
    <Media
      desktop={() => <DesktopComponent />}
      laptop={() => <DesktopComponent />}
      mobile={() => <MobileComponent />}
    />
  )
}

export default VideoComponent
