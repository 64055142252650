import React from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import * as SC from '../../types/SitecoreAdapter'

import PageTypography from '../PageTypography'

import VideoComponentWithListBase from './VideoComponentWithList'

import copyStyles from './styles/styles-copy.module.scss'
import homeStyles from './styles/styles-home.module.scss'

type VideoComponentWithListFields = {
  heading: SC.TextField
  videoUrl: SC.TextField
  
}

type VideoComponentWithListProps = {
  fields: VideoComponentWithListFields
  rendering: any
  testId?: string
}

const VideoComponentWithList = (props: VideoComponentWithListProps) => {
  const getStyleClass = (pageType: string) => {
    switch (pageType) {
      case 'home':
        return homeStyles
      case 'policy':
      case 'article':
      default:
        return copyStyles
    }
  }

  return (
    <PageTypography>
      {(pageType: string) => (
        <VideoComponentWithListBase
          heading={props.fields.heading && props.fields.heading.value}
          videoUrl={props.fields.videoUrl && props.fields.videoUrl.value}
          listPlaceholder={<Placeholder name="Video-Bullet-List-Placeholder" rendering={props.rendering} />}
          styles={getStyleClass(pageType)}
          testId={props.testId}
        />
      )}
    </PageTypography>
  )
}

export default VideoComponentWithList
