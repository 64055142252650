import React, { FC, ReactNode } from 'react'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import { getColumns } from './DesktopTable'
import { TableRow } from '../types'

function TH({ children = null }: { children?: ReactNode }) {
  return <th>{children}</th>
}

function TD({ children = null }: { children?: ReactNode }) {
  return <td>{children}</td>
}

export const BodyRows: FC<{
  bold: boolean
  numberOfColumns: number
  rows: TableRow[]
}> = ({ bold, rows, numberOfColumns }) => {
  const Cell = bold ? TH : TD

  return (
    <tbody>
      {rows.map((row, index) => (
        <tr key={`row_${index}`}>
          <Cell>
            <RichText field={row.fields.rowHeading_00} />{' '}
          </Cell>
          {getColumns(row, numberOfColumns).map((col, colIndex) => (
            <td key={`col_${colIndex}`}>{col}</td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}
