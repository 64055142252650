import React, { ReactNode } from 'react'
import clsx from 'clsx'
import spaceStyles from '../../../styles/space.module.scss'
import styles from './styles.module.scss'
// Handle exterior margin, if there's a special case there

export default function({
  intro,
  children,
}: {
  intro: ReactNode
  children: ReactNode
}) {
  return (
    <div data-test-id="FeaturedContent-Section" className={clsx(spaceStyles.contain, 'block')}>
      <div className={styles.intro}>{intro}</div>
      <div>{children}</div>
    </div>
  )
}
