import React, { FC } from 'react'
import ContactCard from './ContactCard'
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react'
import { ImageField, TextField } from '../../types/SitecoreAdapter'

type Props = {
  fields: { image: ImageField; heading: TextField; descriptor: TextField }
}

const ContactCardAdapter: FC<Props> = ({
  fields: { image, heading, descriptor },
}) => {
  return (
    <ContactCard
      image={<Image field={image} />}
      heading={<Text field={heading} />}
      descriptor={<RichText field={descriptor} />}
    />
  )
}

export default ContactCardAdapter
