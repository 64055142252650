// @format
import React, { ReactNode, useRef } from 'react'

import styles from './styles.module.scss'
import bestForImage from './images/best-for.svg'
import { useCardClick } from './useCardClick'
import { withClassName } from '../../util/withClassName'
import { LinkObject } from '../../types/SitecoreAdapter'

type PolicyCardProps = {
  icon: React.ReactElement
  link: LinkObject
  title: string
  body: React.ReactElement
  bestFor: React.ReactElement
  desktopButton: React.ReactElement
  mobileButton: React.ReactElement
  children?: ReactNode
  testId?: string
}

const PolicyCard = ({
  icon,
  link,
  title,
  body,
  bestFor,
  children,
  testId
}: PolicyCardProps) => {
  const anchorEl = useRef<HTMLAnchorElement>(null)
  const [onCardMouseDown, onCardMouseUp, onAnchorMouseDown] = useCardClick(
    anchorEl
  )

  return (
    <div
      data-test-id={testId}
      className={styles.card}
      onMouseDown={onCardMouseDown}
      onMouseUp={onCardMouseUp}
    >
      <a
        ref={anchorEl}
        onMouseDown={onAnchorMouseDown}
        href={link.href}
        title={link.title}
        className={styles.head}
      >
        <h3 className={styles.title}>{title}</h3>
        {withClassName(icon, styles.image)}
      </a>
      <div className={styles.body}>{body}</div>
      <div className={styles.bestForWrap}>
        <img src={bestForImage} className={styles.bestForImage} alt="" />
        <div className={styles.bestForHead}>BEST FOR</div>
      </div>
      <div className={styles.bestFor}>{bestFor}</div>
      <div className={styles.buttonWrap}>{children}</div>
    </div>
  )
}
export default PolicyCard
