import React from 'react'
import Heading, { HeadingProps } from './Heading'
import List from './List'
import FooterColumnLink, { FooterColumnLinkProps } from './FooterColumnLink'
import clsx from 'clsx'
import styles from './styles.module.scss'

type ColumnTypeProps = {
  type: string
}

type ColumnWrapperProps = {
  children?: React.ReactNode | JSX.Element
} & ColumnTypeProps

export type ColumnProps = {
  items: FooterColumnLinkProps[]
} & ColumnTypeProps &
  HeadingProps

const Wrap = ({ children, type }: ColumnWrapperProps) => {
  const columnClass = clsx(
    styles.column,
    type === 'social' && styles.isSocial
  )

  return <div className={columnClass}>{children}</div>
}

const Column = ({ heading, items, type }: ColumnProps) => {
  const ImageColumn = () => {
    const { image, href, title, newWindow } = items[0]

    return (
      <Wrap type={'image'}>
        <FooterColumnLink
          image={image}
          href={href}
          title={title}
          newWindow={newWindow}
        />
      </Wrap>
    )
  }

  return (
    <Wrap type={type}>
      {type === 'image' ? (
        <ImageColumn />
      ) : (
        <>
          <Heading heading={heading} />
          <List items={items} type={type} />
        </>
      )}
    </Wrap>
  )
}

export default React.memo(Column)
