import React from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react'
import {
  BooleanField,
  ImageField,
  Item,
  TextField,
  Value,
} from '../../../types/SitecoreAdapter'

export type LogoType = {
  image: ImageField
  href: TextField
  title: TextField
  newWindow: BooleanField
}

type LogoFields = Item<LogoType>

const logoAdapter = (logo: LogoFields) => {
  if (!logo || !logo.fields.image.value.src || !logo.fields.href) {
    return []
  }

  const {
    fields: { image, href, title, newWindow },
  } = logo

  return [
    {
      type: 'image',
      items: [
        {
          image: (
            <img
              src={image.value.src}
              alt={image.value.alt}
              width={image.value.width}
              height={image.value.height}
              loading={'lazy'}
            />
          ),
          href: href.value,
          title: title.value,
          newWindow: newWindow && newWindow.value,
        },
      ],
    },
  ]
}

export type ColumnFields = Item<{
  footerLogo: LogoFields
  columns: Item<{
    heading: TextField
    headingLink: TextField
    items: Item<{
      href: TextField
      text: TextField
      title: TextField
      image: ImageField
      newWindow: BooleanField
    }>[]
    isSocial: Value<Boolean>
  }>[]
}>

export default function columnAdapter({
  fields: { columns, footerLogo },
}: ColumnFields) {
  return [
    ...logoAdapter(footerLogo),
    ...columns.map(({ fields: { heading, headingLink, items, isSocial } }) => {
      return {
        type: isSocial.value ? 'social' : 'link',
        heading: {
          text: heading.value,
          href: headingLink.value,
          title: heading.value,
        },
        items: items.map(
          ({ fields: { href, text, title, image, newWindow } }) => {
            return {
              text: text && text.value,
              href: href && href.value,
              title: title && title.value,
              image: image && <Image field={image} />,
              newWindow: newWindow && newWindow.value,
            }
          }
        ),
      }
    }),
  ]
}
