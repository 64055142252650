import React, { FC } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import {
  ComponentConsumerProps,
  Image,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import { ImageField, TextField } from '../../types/SitecoreAdapter'

type Props = ComponentConsumerProps & {
  sitecoreContext: {
    route: {
      fields: {
        trustBadge: {
          fields: { href: TextField; image: ImageField }
        }
      }
    }
  }
}

const TrustImage: FC<Props> = React.memo(
  ({
    sitecoreContext: {
      route: {
        fields: {
          trustBadge: {
            fields: { href, image },
          },
        },
      },
    },
  }) => (
    <a href={href.value} className={clsx('TrustImage', styles.root)}>
      <Image field={image} className={styles.img} />
    </a>
  )
)

export default withSitecoreContext()(TrustImage)
