import clsx from 'clsx'
import React from 'react'
import styles from './styles.module.scss'
import PageTypography from '../../../PageTypography'
import { TP_AdditionalProps } from '../../TrustPilotWidget'

const MicroComboWidget = React.forwardRef(
  (props: TP_AdditionalProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <PageTypography>
        {(pageType: string) => (
          <div
            className={clsx(
              'TrustPilotMicroComboWidget',
              styles.microComboWrap
            )}
            data-test-id={'micro-combo-tp-widget'}
          >
            <div
              className={clsx(
                styles.widgetContainer,
                styles.microWidget,
                styles[`micro-combo-${pageType}`],
                props.variant === 'hero' && styles.tpLineHeroWidget
              )}
              id="widgetContainer"
            >
              <div
                id={'trustBox'}
                ref={ref}
                className={styles.trustPilotWidget}
                data-locale="en-US"
                data-template-id="5419b6ffb0d04a076446a9af"
                data-businessunit-id="545aa5ea00006400057b5df9"
                data-style-height="20px"
                data-style-width="100%"
                data-theme={props.isDark ? 'dark' : 'light'}
                data-tags="SelectedReview"
              ></div>
            </div>
          </div>
        )}
      </PageTypography>
    )
  }
)

export default MicroComboWidget
