import React from 'react'
import RS_Select, { components, DropdownIndicatorProps } from 'react-select'
import EmptyComponent from './EmptyComponent'
import getSelectStyles from './styles'
import styles from './styles.module.scss'
import downChevron from './assets/down-chevron.svg'
import { IOption } from '../../types'
import clsx from 'clsx'
import SelectControl from './Control'

type SelectInertProps = {
  onFocus?: () => void
  placeholder?: string
  value?: IOption | null
  isDisabled?: boolean
  className?: string
}

export default function SelectInert({
  value,
  onFocus,
  placeholder,
  isDisabled = false,
  className,
}: SelectInertProps) {
  return (
    <div className={clsx(styles.root, className)}>
      <RS_Select
        placeholder={placeholder}
        value={value}
        isDisabled={isDisabled}
        components={{
          Control: SelectControl,
          ClearIndicator: EmptyComponent,
          IndicatorSeparator: EmptyComponent,
          DropdownIndicator,
        }}
        styles={getSelectStyles()}
        hasSearchIcon={false}
        isInert={true}
        onFocus={onFocus}
      />
    </div>
  )
}

const DropdownIndicator = (props: DropdownIndicatorProps<IOption, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={downChevron} className={styles.downChevron} />
    </components.DropdownIndicator>
  )
}
