import React from 'react'

import LinkWrapper from '../../../components/LinkWrapper'
import { LinkWrapperProps } from '../../../components/LinkWrapper/LinkTypes'

export type FooterColumnLinkProps = LinkWrapperProps

type LinkAttrs = {
  'data-tag': string
}

const FooterColumnLink = ({
  image,
  imageStyles,
  href,
  text,
  title,
  newWindow,
  className,
}: FooterColumnLinkProps) => {
  const attrs: LinkAttrs = {
    'data-tag': 'footer',
  }

  return (
    <LinkWrapper
      {...attrs}
      image={image}
      imageStyles={imageStyles}
      className={className}
      href={href}
      text={text}
      title={title}
      newWindow={newWindow}
    />
  )
}

export default React.memo(FooterColumnLink)
