import React, { memo, FC } from 'react'
import CookieError from './index'
import { withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react'
import { SitecoreContextType } from '../../../../../types/SitecoreAdapter'
import { SearchAppStartItem } from '../../context'

type CookieErrorCmsProps = {
  darkBg?: Boolean
} & SitecoreContextType<{ searchAppStart: SearchAppStartItem }>

const CookieErrorCms = ({
  darkBg = true,
  sitecoreContext,
}: CookieErrorCmsProps) => {
  const { route } = sitecoreContext

  if (!route.fields.searchAppStart || !route.fields.searchAppStart.fields) {
    return null
  }

  return (
    <CookieError darkBg={darkBg}>
      <RichText field={route.fields.searchAppStart.fields.cookieErrorMessage} />
    </CookieError>
  )
}

export default memo(withSitecoreContext()(CookieErrorCms))
