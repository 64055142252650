// @format
import React from 'react'
import {
  withSitecoreContext,
  Placeholder,
  RouteData,
} from '@sitecore-jss/sitecore-jss-react'

import BreadcrumbsFromRoute from '../../page-components/Breadcrumb/BreadcrumbsFromRoute'
import BreadcrumbList from '../../page-components/Breadcrumb/BreadcrumbList'
import Preview from '../../components/Preview/base'

type TestPageProps = {
  sitecoreContext: {
    route: RouteData
  }
}

function TestPage({ sitecoreContext: { route } }: TestPageProps) {
  return (
    <>
      <Preview>
        <BreadcrumbsFromRoute>
          {(breadcrumbs) => 
            <BreadcrumbList
              showWrap={false}
              breadcrumbs={breadcrumbs}
              isSticky={true}
              paddedLeft
            />}
        </BreadcrumbsFromRoute>
        <div>
          <Placeholder name="jss-main" rendering={route} />
        </div>
      </Preview>
    </>
  )
}

export default withSitecoreContext()(TestPage)
