import { ReactNode } from 'react'

export enum NavigationItemType {
  MenuOption = 'MenuOption',
  Heading = 'Heading',
  Columns = 'Column',
  LinkSection = 'LinkSection',
  Link = 'Link',
}

export enum NavigationType {
  ResponsiveNav = 'ResponsiveNav',
  DesktopNav = 'DesktopNav',
  BlogNav = 'BlogNav',
}

export enum NavigationColumnWidth {
  med = 'med',
  double = 'double',
  full = 'full',
}

export type Link = {
  href: string
  title: string
  text: string
}

export type ImageLink = {
  src: string
  href: string
  alt?: string
  width?: string
  height?: string
  title?: string
}

export type Heading = {
  type: NavigationItemType.Heading
  heading: string
}

export type Columns = {
  type: NavigationItemType.Columns
  children: LinkSection[]
}

export type LinkSection = {
  type: NavigationItemType.LinkSection
  title: string
  linksPerColumn: number
  width: NavigationColumnWidth
  links: NavigationItem[]
}

export type NavigationItem = Link & {
  icon: string
  isSummaryLink: boolean
  mobileOnly: boolean
}

export type MenuOptionChild = Heading | Columns

export type MenuOption = NavigationItem & {
  children: MenuOptionChild[]
}

export type SitecoreNavigationObject = {
  logo: ImageLink
  mobileLogo: ImageLink
  startQuote: Link
  login: Link
  links: MenuOption[]
} | null

export type Navigation = {
  navigationType: NavigationType
  menu: SitecoreNavigationObject
}

export type NavigationSettings = {
  noMenus: boolean
  logo: { image: ReactNode; href: string }
  mobileLogo: { image: ReactNode; href: string }
  menu: SitecoreNavigationObject
  login: boolean
  isIE11: boolean
}

export type LegacyNavFields = { fields: { navResources: { fields: any } } }

export const NavViewportSize = {
  xs: 480,
  xsMax: 599,
  sm: 767,
  smMax: 1024,
}