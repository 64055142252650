import React from 'react'
import clsx from 'clsx'

import MultiColumn from '../MultiColumn/MultiColumn'
import CostModuleButton, { CostModuleButtonProps } from './CostModuleButton'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'
import typeStyles from '../../styles/type.module.scss'

export type CostModuleProps = {
  heading: React.ReactNode
  image: JSX.Element
  body: JSX.Element
  cta: CostModuleButtonProps
}

const CostModule = ({
  heading,
  image,
  body,
  cta,
}: CostModuleProps) => {
  return (
    <div data-test-id="CostModule" className={clsx('block', spaceStyles.contain, 'CostModule')}>
      <h2 className={styles.head}>{heading}</h2>
      <MultiColumn
        left={React.createElement(image.type, {
          ...image.props,
          className: styles.img,
        })}
        right={
          <div className={clsx(typeStyles.list, styles.textWrap, styles.body)}>
            {body}
            <CostModuleButton {...cta} />
          </div>
        }
      />
    </div>
  )
}

export default React.memo(CostModule)
