import React, {ReactNode} from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

export type BottomCTAProps = {
  heading: ReactNode
  descriptor: ReactNode
  escapeText: ReactNode
  escapeHref: string
  image: ReactNode
  className?: string
  children: ReactNode
}

const BottomCTA = ({
  heading,
  descriptor,
  escapeText,
  escapeHref,
  image,
  className,
  children,
} : BottomCTAProps) => (
  <div className={clsx(className, styles.main)}>
    <div className={styles.title}>{heading}</div>
    <div className={styles.descriptor}>{descriptor}</div>
    <div className={styles.childrenWrap}>{children}</div>
    <a href={escapeHref} className={styles.escape}>{escapeText}</a>
    <div className={styles.logo}>{image}</div>
  </div>
)

export default BottomCTA
