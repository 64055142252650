import React, { FC } from 'react'
import { Text, Image } from '@sitecore-jss/sitecore-jss-react'
import SeriesValueProp from './SeriesValueProp'
import { ImageField, TextField } from '../../types/SitecoreAdapter'

type Props = {
  fields: {
    heading: TextField
    leftImage: ImageField
    leftHeading: TextField
    middleImage: ImageField
    middleHeading: TextField
    rightImage: ImageField
    rightHeading: TextField
  }
  testId?: string
}

const SeriesValuePropsAdapter: FC<Props> = ({
  fields: {
    heading,
    leftImage,
    leftHeading,
    middleImage,
    middleHeading,
    rightImage,
    rightHeading,
  },
  testId
}) => {
  return (
    <SeriesValueProp
      testId={testId}
      heading={<Text field={heading} />}
      leftImage={<Image field={leftImage} />}
      leftHeading={<Text field={leftHeading} />}
      middleImage={<Image field={middleImage} />}
      middleHeading={<Text field={middleHeading} />}
      rightImage={<Image field={rightImage} />}
      rightHeading={<Text field={rightHeading} />}
    />
  )
}

export default SeriesValuePropsAdapter
