import React, {
  ReactElement,
  ReactNode,
  useRef,
  useState,
  useEffect,
} from 'react'

import clsx from 'clsx'

import spaceStyles from '../../styles/space.module.scss'
import accordionStyles from './accordion-styles.module.scss'

type StylingProps = {
  styleOverrides?: { [key: string]: string }
}

type AccordionBaseProps = {
  heading: ReactElement
  open?: boolean
  children?: ReactNode
  testId?: string
} & StylingProps

/*
 * Props:
 * - open {bool}, should we start open or closed
 * - toggle {ReactElement} what to display in the toggle
 * - children {ReactChildren} what to display in the sliding panel
 */
function AccordionBase({
  heading,
  open,
  children,
  testId = 'CollapsiblePanel',
  styleOverrides,
}: AccordionBaseProps) {
  const contentRef = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(open)
  const [accordionItemStyleState, setAccordionItemStyleState] = useState<
    string
  >()
  const [headingToggleStyleState, setHeadingToggleStyleState] = useState<
    string
  >()

  useEffect(() => {
    const contentEl = contentRef.current as HTMLDivElement

    if (isOpen) {
      // remove hidden = 'until-found' attribute from the accordion item container
      contentEl.removeAttribute('hidden')

      // setting height of the opeen accordion to content height
      setHeight(contentEl.scrollHeight)

      // applying item and heading override classes for open state
      setAccordionItemStyleState(accordionStyles.open)
      setHeadingToggleStyleState(accordionStyles.headingOpen)
    } else {
      // applying item and heading override classes for closed state
      setAccordionItemStyleState(accordionStyles.closed)
      setHeadingToggleStyleState(accordionStyles.headingClosed)

      // setting height of the closed accordion item container to 0
      setHeight(0)

      // adding hidden = 'until-found' attribute to the closed accordion item container
      // timeout of 0.4s to retain transition uniformity while closing the accordion
      setTimeout(() => {
        contentEl.setAttribute('hidden', 'until-found')
      }, 400)
    }

    // adding event handler for 'beforematch' event if a browser search is performed
    contentEl.addEventListener('beforematch', clickHandler)
    return () => {
      contentEl.removeEventListener('beforematch', clickHandler)
    }
  }, [isOpen])

  function clickHandler() {
    setIsOpen(!isOpen)
  }

  return (
    <div
      data-test-id={testId}
      className={clsx(
        spaceStyles.contain,
        'block',
        'CollapsiblePanel',
        styleOverrides && styleOverrides.contain
      )}
    >
      <div className={accordionStyles.accordion}>
        <div
          className={clsx(
            accordionStyles.accordionItem,
            accordionItemStyleState
          )}
        >
          <div
            className={clsx(
              accordionStyles.accordionItemTitle,
              headingToggleStyleState,
              styleOverrides && styleOverrides.accordionItemTitle
            )}
            onClick={clickHandler}
          >
            <div>{heading}</div>
          </div>
          <div
            ref={contentRef}
            className={clsx(
              accordionStyles.accordionItemContainer,
              styleOverrides && styleOverrides.body
            )}
            style={{ height }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccordionBase
