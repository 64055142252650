import { IOption } from '../types'
import {
  AppSearch_States,
  State_AppStart,
  State_BrowserError,
  State_Inert,
  State_Searching,
  Status,
} from './states'
import { AppSearch_Events, Event, Event_SelectProfession } from './events'

export type TypeaheadReducer = (
  state: AppSearch_States,
  event: AppSearch_Events
) => AppSearch_States

export const getInitialState: (
  preselectProfession?: IOption | false
) => State_Inert = preselectProfession => ({
  status: Status.Inert,
  selection: preselectProfession ? preselectProfession : undefined,
})

export const selectProfession = (option: IOption) =>
  ({
    type: Event.SelectProfession,
    selection: option,
  } as Event_SelectProfession)

export const searchAppReducer = (
  state: AppSearch_States,
  event: AppSearch_Events
) => {
  // when cookies are disabled, show a browser error
  if (event.type === Event.CookieCheck && event.enabled === false) {
    return <State_BrowserError>{ status: Status.BrowserError }
  }

  // when preselected profession isn't valid, ensure selection is empty
  if (event.type === Event.PreselectCheck && event.valid === false) {
    return <State_Inert>{
      ...state,
      selection: undefined,
    }
  }

  // after closing, go back to inert display, unset the selection
  if (event.type === Event.ModalClose && state.status === Status.Searching) {
    return <State_Inert>{
      ...state,
      selection: undefined,
      status: Status.Inert,
    }
  }

  // user selects cta from inert view, and there isn't a selection yet
  // show the search
  if (
    event.type === Event.SelectCTA &&
    state.status === Status.Inert &&
    !state.selection
  ) {
    return <State_Searching>{
      ...state,
      status: Status.Searching,
    }
  }

  // We have preselected profession
  // User selects CTA
  // This is a direct app start because we have a selection at the time of clicking the CTA
  // We are confirming selections, so we open search mode with the hint that this is a direct app
  // that isn't confirmed
  if (
    (state.status === Status.Inert || state.status === Status.Searching) &&
    event.type === Event.SelectCTA &&
    state.selection !== undefined
  ) {
    return <State_Searching>{
      status: Status.Searching,
      selection: state.selection,
      direct: true,
    }
  }

  // We've gotten a selection confirmation event
  // We have a selection to start an app with
  // Fire away
  if (
    state.status === Status.Searching &&
    event.type === Event.ConfirmSelection &&
    state.selection !== undefined
  ) {
    return <State_AppStart>{
      status: Status.AppStart,
      direct: state.direct || false,
      selection: state.selection,
    }
  }

  // We've gotten a reject selection event
  // Clear the selection
  if (
    state.status === Status.Searching &&
    event.type === Event.RejectSelection &&
    state.selection !== undefined
  ) {
    return <State_Searching>{
      ...state,
      status: Status.Searching,
      selection: undefined,
    }
  }

  // When we focus on the select box, and status is inert, transition to searching
  if (event.type === Event.SelectFocus && state.status === Status.Inert) {
    return <State_Searching>{
      ...state,
      status: Status.Searching,
    }
  }

  // when we open the modal and the status is inert, transition to searching
  if (event.type === Event.ModalOpen && state.status === Status.Inert) {
    return <State_Searching>{
      ...state,
      status: Status.Searching,
    }
  }

  // User selected profession
  if (
    event.type === Event.SelectProfession &&
    state.status === Status.Searching
  ) {
    return <State_Searching>{
      ...state,
      selection: event.selection,
    }
  }

  // after back navigation on mobile
  if (event.type == Event.Reset && state.status == Status.AppStart) {
    return <State_Inert>{
      ...state,
      status: Status.Inert,
    }
  }

  return state
}
