import React from 'react'
import Media from '../../components/Media'
import DesktopMenu from './DesktopMenu'
import TabletMenu from './TabletMenu'
import MobileMenu from './MobileMenu'

export const Navigation = () => {
  return (
    <Media
      desktop={() => <DesktopMenu />}
      tablet={() => <TabletMenu />}
      mobile={() => <MobileMenu />}
    />
  )
}

export default Navigation
