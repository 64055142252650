import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import tabStyles from '../tab.module.scss'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { TabListTabType } from '../TabList'

type Props = Pick<TabListTabType, 'href' | 'text'>

const TabNavLink: FC<Props> = ({ href, text }) => {
  return (
    <NavLink
      to={href || ''}
      className={({ isActive }) =>
        clsx(tabStyles.tab, styles.tab, isActive && tabStyles.active)
      }
      end
    >
      <span className={tabStyles.text}>{text}</span>
    </NavLink>
  )
}

export default React.memo(TabNavLink)
