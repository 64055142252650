import React, { FC } from 'react'
import Media from '../../../Media'
import Carousel from './Carousel'
import Tabs from './Tabs/Tabs'

// props
// tabs -- [{href, text}..] Array of tab descriptors

export type TabListTabType = {
  text?: string
  href: string
  active?: boolean
  onTabSelect?: () => void
}

export type TabListProps = {
  tabs: TabListTabType[]
  className?: string
}

const TabList: FC<TabListProps> = ({ tabs, className }) => {
  return (
    <Media
      // when we output the HTML source code of the page, keep the markup simple, using tabs
      source={() => <Tabs tabs={tabs} className={className} />}
      // if on a mobile phone, it will rehydrate into a slick carousel with a lot more going on
      mobile={() => <Carousel tabs={tabs} className={className} />}
      // at tablet and above, back to tabs
      tablet={() => <Tabs tabs={tabs} className={className} />}
    />
  )
}

export default TabList
