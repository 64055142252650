import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import LinkWrapper from '../../../../../components/LinkWrapper'

const MenuOptionLink = React.memo(({ text, title, href, className }) => (
  <LinkWrapper
    href={href}
    text={text}
    title={title}
    data-tag="tig-meganav"
    className={clsx(styles.root, className)}
  />
))

export default MenuOptionLink
