import React from 'react'
import {
  Image,
  Placeholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import SpecialList from './SpecialList'

import IsPageEditing from '../IsPageEditing'
import { ImageField } from '../../types/SitecoreAdapter'

type SpecialListProps = {
  fields: {
    icon: ImageField
  },
  rendering?: any
  testId?: string
}

const placeholderName = 'SpecialList-Placeholder'

const SpecialListAdapter = ({
  fields: { icon },
  rendering,
  testId
}: SpecialListProps) => {
  
  const placeholder = (
    <Placeholder name={placeholderName} rendering={rendering} />
  )
  
  const iconElement = <Image field={icon} />

  return (
    <IsPageEditing>
      {(isEditing: boolean) => {
        if (isEditing) {
          return (
            <div className="contain">
              {iconElement}
              {placeholder}
            </div>
        )}
        
        return <SpecialList icon={iconElement} testId={testId}>{placeholder}</SpecialList>
      }}
    </IsPageEditing>
  )
}

export default SpecialListAdapter
