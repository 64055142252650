import React from 'react'
import Cell from './Cell'
import CellHeader from './CellHeader'
import TableContainer from './TableContainer'
import styles from './styles.module.scss'
import SpacingContainer from '../SpacingContainer'
import { fieldsOfType } from '..'
import { range, take } from 'lodash-es'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import { TableRow } from '../types'
import clsx from 'clsx'

type MobileTableProps = {
  data: TableRow[]
  numberOfColumns: number
}

export const MobileTable = ({
  numberOfColumns = 3,
  data,
}: MobileTableProps) => {
  return (
    <SpacingContainer>
      <TableContainer
        className={clsx(
          styles.mobileTable,
          numberOfColumns <= 1 && styles.singleColumn
        )}
      >
        {range(numberOfColumns).map((_, cIndex) => (
          <div key={`col_${cIndex}`} className={styles.col}>
            {data.map(({ fields: row }, rIndex) => {
              const isHeaderRow = rIndex === 0
              const columns = fieldsOfType(row, 'column')
              const rowCells = take(columns, numberOfColumns)
              return (
                <div
                  key={`row_${rIndex}`}
                  className={styles[isHeaderRow ? 'hrow' : 'row']}
                >
                  {isHeaderRow ? (
                    <CellHeader>{rowCells[cIndex]}</CellHeader>
                  ) : (
                    <>
                      <CellHeader>
                        <RichText field={row.rowHeading_00} />{' '}
                      </CellHeader>
                      <Cell className={styles.cell}>{rowCells[cIndex]}</Cell>
                    </>
                  )}
                </div>
              )
            })}
          </div>
        ))}
      </TableContainer>
    </SpacingContainer>
  )
}
