import React, { FC } from 'react'
import { Text, Image } from '@sitecore-jss/sitecore-jss-react'
import HomePageValueProp from './HomePageValueProp'
import { ImageField, TextField } from '../../types/SitecoreAdapter'

type Props = {
  fields: {
    heading: TextField
    cards: {
      fields: {
        heading: TextField
        text?: TextField
        image?: ImageField
        caption?: TextField
      }
    }[]
  }
}

const HomePageValuePropAdapter: FC<Props> = ({
  fields: { heading, cards }
}) => {
  return (
    <HomePageValueProp
      heading={<Text field={heading} />}
      cards={cards.map(({ fields: { heading, text, image, caption } }) => ({
        heading: <Text field={heading} />,
        text: <Text field={text} />,
        image: <Image field={image} />,
        caption: <Text field={caption} />,
      }))}
    />
  )
}

export default HomePageValuePropAdapter
