import config from '../../temp/config'

type TempConfigJs = {
    env: string
    app: string
    professions: string
    typeahead: string
    typeaheadList: string
    sentryDsn: string
}

type EnvironmentSettings = {
    env: string
    endpoints:{
        app: string
        professions: string
        typeahead: string
        typeaheadList: string
        sentryDsn: string
    }
}

export default function getEnvironmentFromConfig(brandCode: string, busUnitId: string) : EnvironmentSettings {

    const {
        env,
        app,
        professions,
        typeahead,
        typeaheadList,
        sentryDsn
    }: TempConfigJs = config

    return {
        env: env,
        endpoints: {
            app: replaceTokens(app, brandCode, busUnitId),
            professions: replaceTokens(professions, brandCode, busUnitId),
            typeahead: replaceTokens(typeahead, brandCode, busUnitId),
            typeaheadList: replaceTokens(typeaheadList, brandCode, busUnitId),
            sentryDsn: replaceTokens(sentryDsn, brandCode, busUnitId),
        }
    }
}

function replaceTokens(url: string, brandCode: string, busUnitId: string) {
    const brandToken = '$BRAND'
    const busUnitToken = '$BUSUNITID'
    return url.replace(brandToken, brandCode).replace(busUnitToken, busUnitId)
}

