import React from 'react'
import Media from '../Media'
import clsx from 'clsx'
import styles from './styles.module.scss'

type TableProps = {
  rowHeadings: Array<any>
  data: Array<any>
}

const DesktopTable = ({
  rowHeadings,
  data,
  centerRowHeadings = true,
}: TableProps & { centerRowHeadings?: boolean }) => {
  const rowCount = data.reduce(
    (count, column) => Math.max(count, column.rows.length),
    0
  )
  const rows = []

  for (let i = 0; i < rowCount; i++) {
    rows.push(
      data.map((column, index) => (
        <div key={index} className={styles.cell}>
          {column.rows[i]}
        </div>
      ))
    )
  }

  return (
    <div className={styles.table}>
      <div className={styles.hrow}>
        <div className={clsx(styles.header, styles.subhead)} />
        {data.map((column, index) => (
          <div key={index} className={clsx(styles.header)}>
            {column.title}
          </div>
        ))}
      </div>
      {rows.map((row, index) => (
        <div key={index} className={styles.row}>
          <div
            className={clsx(
              styles.subhead,
              centerRowHeadings && styles.centered
            )}
          >
            {rowHeadings[index]}
          </div>
          {row}
        </div>
      ))}
    </div>
  )
}

const MobileTable = ({ rowHeadings, data }: TableProps) => {
  return (
    <div className={styles.table}>
      {data.map((column, index) => (
        <React.Fragment key={index}>
          <div className={styles.hrow}>
            <div key={index} className={clsx(styles.header)}>
              {column.title}
            </div>
          </div>
          {column.rows.map((row: any, index: number) => (
            <div key={index} className={styles.row}>
              <div className={styles.subhead}>{rowHeadings[index]}</div>
              <div className={styles.cell}>{row}</div>
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  )
}

const Table = ({ rowHeadings, data }: TableProps) => (
  <Media
    desktop={() => <DesktopTable rowHeadings={rowHeadings} data={data} />}
    mobile={() => <MobileTable rowHeadings={rowHeadings} data={data} />}
  />
)

export default Table
export { DesktopTable, MobileTable }
