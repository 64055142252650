import React, { useContext } from 'react'
import clsx from 'clsx'

import { moduleClassNames } from '../services/css-utils'

import CommonMenuBase, { MegaMenu } from '../CommonMenu/index.base'

import ModalCTA from '../../../components/Modal-CTA/ModalCTA'
import { NavigationContext } from '../services/context'

import commonStyles from '../CommonMenu/styles.module.scss'
import LinkWrapper from '../../../components/LinkWrapper'
import styles from './styles.module.scss'
import { useScreenWidth } from '../../../util/hooks/useScreenWidth'
import { NavViewportSize } from '../types'
import RoutingNumberButton from '../../../components/Lead-Routing-Number-Attribution/components/RoutingNumberButton'

export default function MobileMenu() {
  const { mobileLogo, menu } = useContext(NavigationContext)
  const { screenWidth } = useScreenWidth()
  return (
    <CommonMenuBase>
      {({
        showMenu,
        showSubMenu,
        activeSection,
        onToggleMenu,
        onCloseMenu,
        onShowSection,
      }) => {
        const menuClassName = clsx(
          commonStyles.menu,
          showMenu && commonStyles.isOpen
        )

        return (
          <nav
            className={clsx(commonStyles.nav, moduleClassNames(commonStyles))}
            data-test-context="Nav"
          >
            <div className={clsx(commonStyles.navbar, styles.navbar)}>
              <div className={commonStyles.left}>
                <div
                  data-test-id={'nav-hamburger-icon'}
                  className={menuClassName}
                  onClick={onToggleMenu}
                ></div>
                <LinkWrapper
                  image={mobileLogo.image}
                  href={mobileLogo.href}
                  title={mobileLogo.title}
                  imageStyles={clsx(commonStyles.logo, styles.logo)}
                  className={commonStyles.logoLink}
                />
              </div>
              <div className={commonStyles.right}>
                {/* Shows Call Us button only for Mobile */}
                {screenWidth <= NavViewportSize.xsMax && (
                  <RoutingNumberButton />
                )}
                <ModalCTA
                  buttonClassName={clsx('button', styles.modalButton)}
                  wrapClassName={styles.modalButtonWrap}
                  alignment={'Left'}
                  primary={true}
                  size="slim"
                >
                  {menu && menu.startQuote && menu.startQuote.title}
                </ModalCTA>
              </div>
            </div>
            <MegaMenu
              links={menu.links}
              activeSection={activeSection}
              isOpen={showMenu}
              menuClassName={styles.megaMenu}
              isSubMenuOpen={showSubMenu}
              onRequestClose={onCloseMenu}
              onShowSection={onShowSection}
              hideCallUs={screenWidth <= NavViewportSize.xsMax}
            />
          </nav>
        )
      }}
    </CommonMenuBase>
  )
}
