import React, { FC } from 'react'
import { Image } from './Image'
import MultiColumn from '../MultiColumn/MultiColumn'
import Media from '../Media'
import { StateSelection } from './StateSelection'
import { StateModuleProps } from '.'

export const StateModule: FC<StateModuleProps['fields']> = React.memo(
  ({
    heading,
    headingSize,
    descriptor,
    buttonLabel,
    options,
    image,
    imageAlt,
  }) => {
    const selection = (
      <StateSelection
        heading={heading}
        headingSize={headingSize}
        descriptor={descriptor}
        buttonLabel={buttonLabel}
        options={options}
      />
    )

    const img = <Image src={image} alt={imageAlt} />

    return (
      <Media
        desktop={() => <MultiColumn testId={'StateSelection-Module'} left={selection} right={img} />}
        tablet={() => <MultiColumn testId={'StateSelection-Module'} left={selection} right={img} />}
        mobile={() => <MultiColumn testId={'StateSelection-Module'} left={img} right={selection} />}
      />
    )
  }
)
