import React from 'react'
import {
  Text,
  Image,
  Placeholder,
  ComponentRendering,
  RouteData,
} from '@sitecore-jss/sitecore-jss-react'

import H3andIconBase from './H3andIcon'
import {
  ImageField,
  ImageFieldValue,
} from '@sitecore-jss/sitecore-jss-react/types/components/Image'

type H3IconAdapterProps = {
  fields: {
    icon: ImageField | ImageFieldValue
    heading: {
      value?: string
      editable?: string
    }
  }
  rendering: ComponentRendering | RouteData
}

const H3andIcon = ({ fields, rendering }: H3IconAdapterProps) => {
  const icon = <Image field={fields.icon} />
  const heading = <Text field={fields.heading} tag="h3" />
  const body = (
    <Placeholder name="H3andIcon-Placeholder" rendering={rendering} />
  )

  return <H3andIconBase icon={icon} heading={heading} body={body} />
}

export default H3andIcon
