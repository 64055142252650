import React from 'react'
import MegaMenu from './MegaMenu'

/**
 * Responsive navigation bar for tablet and mobile devices
 */
class CommonMenuBase extends React.PureComponent {
  state = {
    showMenu: false,
    activeSection: null
  }

  componentDidMount() {
    // this.fudgeFixedAndStickyElements()
  }

  fudgeFixedAndStickyElements = () => {
    const { sitecoreContext } = this.props

    if (sitecoreContext && sitecoreContext.pageEditing) {
      const ribbon = document.querySelector('#scWebEditRibbon')

      if (!ribbon || ribbon.offsetHeight === 0) {
        setTimeout(() => {
          this.fudgeFixedAndStickyElements()
        }, 1000)

        return
      }

      for (const element of document.body.getElementsByTagName('*')) {
        if (element === ribbon) {
          continue
        }

        const position = getComputedStyle(element).getPropertyValue('position')

        if (position === 'sticky' && element.offsetTop) {
          element.style.top = element.offsetTop + 'px'
        } else if (position === 'fixed' && element.offsetTop) {
          element.style.top = element.offsetTop + ribbon.offsetHeight + 'px'
        }
      }
    }
  }

  closeSubMenu(force = false) {
    clearTimeout(this.closeSubMenuTimeout)

    return new Promise(resolve => {
      if (force) {
        this.setState(({
          activeSection: null
        }), () => resolve())
      } else {
        this.closeSubMenuTimeout = setTimeout(() => {
          this.setState(state => ({
            activeSection: state.showMenu ? state.activeSection : null
          }), () => resolve())
        }, 500)
      }
    })
  }

  handleToggleMenu = () => {
    this.setState(state => ({
      showMenu: !state.showMenu
    }))

    return this.closeSubMenu()
  }

  handleCloseMenu = (force = false) => {
    this.setState(state => ({
      showMenu: false
    }))

    return this.closeSubMenu(force)
  }

  handleShowSection = section => {
    this.setState({
      activeSection: section
    })
  }

  render() {
    return this.props.children({
      ...this.state,
      onToggleMenu: this.handleToggleMenu,
      onShowSection: this.handleShowSection,
      onCloseMenu: this.handleCloseMenu
    })
  }
}

export default CommonMenuBase
export {
  MegaMenu
}

