import React from 'react'
import { NoticeProps } from 'react-select'
import { IOption } from '../../../types'
import LeadRoutingNumberAttribution from '../../../../../../components/Lead-Routing-Number-Attribution'
import styles from './styles.module.scss'

export default function NoOptionsMessage({
  selectProps: { inputValue, noResultsHeader, noResultsSuggestions },
}: NoticeProps<IOption, false>) {
  const header = noResultsHeader?.replace('{term}', inputValue)
  return (
    <div className={styles.message}>
      {inputValue && <strong>{header}</strong>}
      {noResultsSuggestions && (
        <div className={styles.helpText}>
          {noResultsSuggestions}
          <LeadRoutingNumberAttribution
            variant={'appStart'}
            className={styles.leadRoutingAttr}
          />
        </div>
      )}
    </div>
  )
}
