import { BooleanField, Item, TextField } from '../../../types/SitecoreAdapter'

export type CopyrightLinkFields = Item<{
  copyrightLink: Item<{
    href: TextField
    text: TextField
    title: TextField
    newWindow: BooleanField
  }>
}>

export default function copyrightLinkAdapter({
  fields: { copyrightLink },
}: CopyrightLinkFields) {
  if (!copyrightLink) {
    return null
  }

  return {
    href: copyrightLink.fields.href.value,
    text: copyrightLink.fields.text.value,
    title: copyrightLink.fields.title.value,
    newWindow: copyrightLink.fields.newWindow.value,
  }
}
