import React, { createElement, ReactNode } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

export const SIZES = {
  SLIM: 'slim',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
  XXLARGE: 'xxlarge',
  XXXLARGE: 'xxxlarge',
}

export type ButtonSize =
  | 'slim'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'

type ButtonProps = {
  children?: ReactNode
  onClick?: (ev: any) => void
  primary?: boolean
  secondary?: boolean
  download?: boolean
  disabled?: boolean
  error?: boolean
  normal?: boolean
  loading?: boolean
  size?: ButtonSize
  href?: string
  type?: string
  className?: string
  testId?: string
  title?: string
  fill?: boolean
}

const defaultProps = {
  primary: true,
  secondary: false,
  download: false,
  disabled: false,
  error: false,
  normal: false,
  loading: false,
  type: '',
}

const Button = (props: ButtonProps = {}) => {
  const {
    type,
    size,
    children,
    disabled,
    error,
    normal,
    onClick,
    title,
    className,
    href,
    loading,
    fill,
    testId,
  } = props

  let { primary, secondary, download } = props

  primary = type === 'primary' || primary
  secondary = type === 'secondary' || secondary
  download = type === 'download' || download

  let htmlAttributes: { 'data-test-id'?: string; disabled?: true } = {}

  const _onClick = (e: any) => {
    if (disabled) {
      return
    }

    if (onClick) {
      onClick(e)
    }
  }

  if (disabled || loading) {
    htmlAttributes['disabled'] = true
  }

  if (testId) {
    htmlAttributes['data-test-id'] = testId
  }

  const sizeClasses = {
    [styles.slim]: size === SIZES.SLIM,
    [styles.medium]: size === SIZES.MEDIUM,
    [styles.large]: size === SIZES.LARGE,
    [styles.xlarge]: size === SIZES.XLARGE,
    [styles.xxlarge]: size === SIZES.XXLARGE,
    [styles.xxxlarge]: size === SIZES.XXXLARGE,
    [styles.primary]: primary && !secondary && !download,
    [styles.secondary]: secondary,
    [styles.download]: download,
    [styles.enabled]: !disabled && !loading,
    [styles.error]: error,
    [styles.normal]: normal,
    [styles.fill]: fill,
  }

  const loadFunc = () => {
    return (
      <span className={styles.loadingContainer}>
        Loading
        <span className={styles.dot}>.</span>
        <span className={styles.dot}>.</span>
        <span className={styles.dot}>.</span>
      </span>
    )
  }

  const buttonBody = (
    <span className={styles.title}>{loading ? loadFunc() : children}</span>
  )

  // href is a fallback for behavior, for when js is disabled for example
  if (href) {
    const linkProps = Object.assign(
      {
        href: href,
        title: title,
        className: clsx(styles.button, sizeClasses, className),
      },
      htmlAttributes,
      { onClick: _onClick }
    )

    return createElement('a', linkProps, buttonBody)
  }

  const buttonProps = Object.assign(
    { className: clsx(styles.button, sizeClasses, className) },
    htmlAttributes,
    { onClick: _onClick }
  )

  return createElement('button', buttonProps, buttonBody)
}

Button.defaultProps = defaultProps

export default Button
