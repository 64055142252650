import React, { ReactElement } from "react"
import { Image } from "@sitecore-jss/sitecore-jss-react"
import RelatedPolicies from "./RelatedPolicies"
import { TextField, ImageField } from '../../types/SitecoreAdapter'

export type RelatedPoliciesLinkFields = {
  fields: {
    icon: ImageField
    linkText: TextField
    href: TextField
  }
}

export type RelatedPolicyLink = {
  linkText: string
  href: string
  icon: ReactElement
}

type RelatedPoliciesAdapterProps = {
  title: TextField
  linkItems: RelatedPoliciesLinkFields[]
}

const RelatedPoliciesAdapter = ({ title, linkItems }: RelatedPoliciesAdapterProps) => {
  const links: RelatedPolicyLink[] = linkItems.map(({ fields: { icon, linkText, href } }: RelatedPoliciesLinkFields) => ({
    linkText: linkText && linkText.value || '',
    href: href.value && href.value || '',
    icon: <Image field={icon} />
  }))

  return <RelatedPolicies title={title.value} links={links} />
}

export default React.memo(RelatedPoliciesAdapter)
