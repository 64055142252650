import React, { FC, ReactNode } from 'react'
import AuthorSetContent from './AuthorSetContent'

export type AuthorSetCardType = {
  title: string
  image: string
  imageAlt: string
  href: string
  tag: string
  description: ReactNode
}

type AuthorSetContentProps = {
  fields: {
    heading: string
    descriptor: any
    cards: AuthorSetCardType[]
    seeMoreHref: string
    seeMoreText: ReactNode
  }
}

const AuthorSetContentAdapter: FC<AuthorSetContentProps> = ({
  fields: { cards, descriptor, heading, seeMoreHref, seeMoreText },
}) => {
  return (
    <AuthorSetContent
      heading={heading}
      descriptor={descriptor}
      cards={cards}
      seeMore={{ href: seeMoreHref, body: seeMoreText }}
    />
  )
}

export default AuthorSetContentAdapter
