import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import { FC } from '../../../types/common'

type Props = {
  fullWidth?: boolean
  testId?: string
}

const CardList: FC<Props> = ({ fullWidth, children, testId }) => {
  return (
    <div data-test-id={testId} className={clsx(styles.list, fullWidth && styles.fullWidth)}>
      {children}
    </div>
  )
}

export default CardList
