/*globals screen*/

import React from 'react'

import Testimonial from '../Testimonial'

import Slider from 'react-slick'
import styles from './styles.module.scss'

import { CustomerTestimonialProps } from './domain'

import './slidedots.scss'
import TrustPilotWidget from '../TrustPilot-Widget/TrustPilotWidget'

export default function CustomerTestimonial(props: CustomerTestimonialProps) {
  const { testimonials, imageEl, widgetType, useTrustpilotWidget } = props

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidestoScroll: 1,
    slidesToShow: 1,
    className: styles.wrap,
    dotsClass: 'dots',
  }

  const testimonialEls = testimonials.map(Testimonial)

  return (
    <>
      {useTrustpilotWidget ? (
        <TrustPilotWidget type={widgetType} />
      ) : (
        <>
          <Slider {...settings}>{testimonialEls}</Slider>
          <div className={styles.img}>{imageEl}</div>
        </>
      )}
    </>
  )
}
