import React from 'react'

import { ImageWrapperProps } from './ImageTypes'

const ImageWrapper = ({
  src,
  alt,
  width,
  height,
  title,
  imageStyles,
  testId
}: ImageWrapperProps) => {
  return (
    <img
      className={imageStyles}
      src={src}
      alt={alt}
      width={width}
      height={height}
      title={title}
      data-test-id={testId}
    />
  )
}

export const ImageCloneWrapper = (
  wrapItem: JSX.Element | React.ReactElement,
  additionalProps: {
    [key: string]: string
  }
) => {
  return (
    React.createElement(wrapItem.type, {
      ...wrapItem.props,
      ...additionalProps
    })
  )
}

export default ImageWrapper
