import React, { useContext } from 'react'
import Media from '../../../../../components/Media'
import Mobile from './Mobile'
import Desktop from './Desktop'
import EscapeLink, { EscapeLinkStyles } from '../../components/EscapeLink'
import { AppStartSettingsContext } from '../../context'
import SearchAppStartContainer from '../../state-management/container'
import { Status } from '../../state-management/states'
import styles from './styles.module.scss'

export type CTASearchAppStartProps = {
  showEscapeLink?: Boolean
  styleOverrides?: { escapeLink: EscapeLinkStyles }
}

export default function CTASearchAppStart({
  showEscapeLink = true,
  styleOverrides,
}: CTASearchAppStartProps) {
  const { cookieError, escapeLinkText, escapeLinkHref, darkBg } = useContext(
    AppStartSettingsContext
  )

  return (
    <SearchAppStartContainer>
      {({ appState, dispatch, loadOptions, callbacks }) => {
        // cookie check fails
        if (appState.status === Status.BrowserError) {
          return cookieError
        }

        return (
          <div
            data-test-context="SearchAppStart"
            data-embed="CTA"
            className={styles.root}
          >
            <Media
              mobile={() => (
                <Mobile
                  appState={appState}
                  dispatch={dispatch}
                  loadOptions={loadOptions}
                  callbacks={callbacks}
                />
              )}
              tablet={() => (
                <Desktop
                  appState={appState}
                  dispatch={dispatch}
                  loadOptions={loadOptions}
                  callbacks={callbacks}
                />
              )}
            />
            {showEscapeLink && (
              <EscapeLink
                styleOverrides={styleOverrides && styleOverrides.escapeLink}
                darkBg={darkBg}
                escapeLinkHref={escapeLinkHref}
              >
                {escapeLinkText}
              </EscapeLink>
            )}
          </div>
        )
      }}
    </SearchAppStartContainer>
  )
}
