import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import { FC } from '../../../types/common'

type Props = {
  block?: boolean
  center?: boolean
  className?: string
  testId?: string
}

const BlogContain: FC<Props> = ({
  block,
  center,
  className,
  children,
  testId = 'BlogContain',
}) => {
  const rootClasses = clsx(
    styles.contain,
    block && styles.block,
    center && styles.center,
    className
  )
  return (
    <div data-test-id={testId} className={rootClasses}>
      {children}
    </div>
  )
}

export default BlogContain
