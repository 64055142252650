import React from 'react'
import loadable from '@loadable/component'
import {
  withSitecoreContext,
  Placeholder,
  RouteData,
} from '@sitecore-jss/sitecore-jss-react'

import HomePageHero from './HomePage-Hero'
import HomePageTagline, { TagLineProps } from './HomePage-Tagline'

const BottomCTA = loadable(() => import('../../page-components/Bottom-CTA/WithContext'))

type HomeProps = {
  sitecoreContext: {
    route: {
      fields: {
        tagline?: TagLineProps
      }
    } & RouteData
  }
}

function HomePage({ sitecoreContext: { route } }: HomeProps) {
  return (
    <>
      <HomePageHero applyDefaultImgSizing={true} />
      <Placeholder name="Hero-Placeholder" rendering={route} />
      {route.fields.tagline && <HomePageTagline {...route.fields.tagline} />}
      <div className="home copy">
        <Placeholder name="Home-Placeholder" rendering={route} />
        <BottomCTA />
      </div> 
    </>
  )
}

export default withSitecoreContext()(HomePage)
