import React from 'react'
import {
  withSitecoreContext,
  Text,
  RichText,
  ComponentConsumerProps,
} from '@sitecore-jss/sitecore-jss-react'

import DynamicFooter from './DynamicFooter'
import { BooleanField, Item, TextField } from '../../types/SitecoreAdapter'
import contactAdapter, { ContactFields } from './Adapters/ContactAdapter'
import columnAdapter, { ColumnFields } from './Adapters/ColumnAdapter'
import securityImagesAdapter, {
  SecurityFields,
} from './Adapters/SecurityAdapter'
import legalLinksAdapter, { LegalFields } from './Adapters/LegalLinksAdapter'
import copyrightLinkAdapter, {
  CopyrightLinkFields,
} from './Adapters/CopyrightLinkAdapter'

type FooterFields = ColumnFields &
  SecurityFields &
  LegalFields &
  CopyrightLinkFields &
  Item<{
    copyright: TextField
    disclaimer: TextField
    showTrustPilotWidget: BooleanField
  }>

type FooterAdapterProps = ComponentConsumerProps & {
  sitecoreContext: {
    route: Item<{
      contact: ContactFields
      footer: FooterFields
    }>
  }
}

const FooterAdapter = ({
  sitecoreContext: {
    route: {
      fields: { contact, footer },
    },
  },
}: FooterAdapterProps) => {
  const contactInfo = contactAdapter(contact)
  const columns = columnAdapter(footer)
  const securityImages = securityImagesAdapter(footer)
  const legalLinks = legalLinksAdapter(footer)
  const copyright = <Text field={footer.fields.copyright} />
  const copyrightLink = copyrightLinkAdapter(footer)
  const disclaimer = <RichText field={footer.fields.disclaimer} />

  return (
    <DynamicFooter
      contact={contactInfo}
      columns={columns}
      securityImages={securityImages}
      legalLinks={legalLinks}
      copyright={copyright}
      copyrightLink={copyrightLink || undefined}
      disclaimer={disclaimer}
      showTrustPilotWidget={
        footer.fields.showTrustPilotWidget &&
        footer.fields.showTrustPilotWidget.value
      }
    />
  )
}

export default withSitecoreContext()(FooterAdapter)
