import React, { FC, createElement } from 'react'

const baseHeadingClasses = 'block contain Heading'

type Props = {
  tag: keyof JSX.IntrinsicElements
}

/**
 * An abstract Heading component that takes a HeadingTag such as h1, h2
 * Make concrete components by writing H2 = props => <Heading tag="h2" {...props} />
 */
const Heading: FC<Props> = ({ tag, ...props }) => {
  const HeadingTag = tag

  return createElement(HeadingTag, { className: baseHeadingClasses, ...props })
}

export { Heading }

// 'jss run build' requires default exports.
export default {}
