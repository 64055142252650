import React, { FC } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import H4 from './H4'
import { TextField } from '../../types/SitecoreAdapter'

type Props = {
  fields: { text: TextField }
}

const Heading4: FC<Props> = ({ fields: { text } }) => {
  return (
    <H4>
      <Text field={text} />
    </H4>
  )
}

export default Heading4
