import React, { ReactNode, FC } from 'react'
import styles from './styles.module.scss'

type Props = {
  image: ReactNode
  heading: ReactNode
  body: ReactNode
  testId?: string
}

const ValueProp: FC<Props> = ({ image, heading, body, testId }) => (
  <div data-test-id={testId} className={styles.base}>
    <div className={styles.img}>{image}</div>
    <div className={styles.bodyWrap}>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.body}>{body}</div>
    </div>
  </div>
)

export default ValueProp
