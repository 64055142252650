import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import styles from './styles.module.scss'

export type TestimonialProps = {
  id?: string
  body: React.ReactNode,
  name: React.ReactNode,
  customerSince: React.ReactNode
}

const Testimonial = (props: TestimonialProps) => (
  <div className={styles.testimonial}>
    <div className={styles.inner}>
      <div className={styles.body}>
        {props.body}
      </div>
      <div className={styles.bottom}>
        <div className={styles.name}>
          {props.name}
        </div>
        <div>
          {props.customerSince}
        </div>
      </div>
    </div>
  </div>
)

export default Testimonial
