import React from 'react'

import Media from '../../components/Media'
import SidebarSection from '../../components/SidebarSection'
import BodySidebar from '../../page-components/Body-Sidebar'
import BlogCard from '../../components/Blog-Card'
import BlogCardList from '../../components/Blog-Card/List'
import TagList from '../../page-components/Tag-List/Tag-List'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

import clsx from 'clsx'
import ScrollToTopOnUpdate from '../../util/ScrollToTopOnUpdate'

type TagResultCardProps = {
  description: string
  href: string
  tag: string
  title: string
  image: string
  imageAlt: string
}

type RelatedTagsProps = {
  href: string
  label: string
}

type TagResultsProps = {
  tagName: string
  tagPath?: string
  cards: TagResultCardProps[]
  pagination: React.ReactNode
  contact: React.ReactNode
  relatedTags: RelatedTagsProps[]
  ctaBanner: React.ReactNode
}

function TagResults({
  tagName,
  cards,
  pagination,
  contact,
  relatedTags,
  ctaBanner,
}: TagResultsProps) {
  const divider = <div className={styles.divider}></div>

  const renderResults = () => (
    <div className={styles.body}>
      {cards && (
        <BlogCardList fullWidth>
          {cards.map(card => (
            <BlogCard
              key={card.href}
              title={card.title}
              href={card.href}
              tag={card.tag}
              image={card.image  && `${card.image}&mh=190&c=1&ch=190&cw=390`}
              imageAlt={card.imageAlt}
              horizontal
            >
              {card.description ? card.description : false}
            </BlogCard>
          ))}
        </BlogCardList>
      )}
      {pagination}
      {divider}
    </div>
  )

  const renderNoResults = () => (
    <div className={styles.noResults}>
      <span className={styles.noResultsText}>
        Sorry, no tags match this search
      </span>
    </div>
  )

  const body = cards && cards.length ? renderResults() : renderNoResults()

  const topic = <div className={styles.topic}>Topic</div>

  const heading = <h1>{tagName}</h1>

  const relatedTagsSection = relatedTags.length > 0 && (
    <SidebarSection title="Related Topics" className={styles.aside}>
      <TagList tags={relatedTags} />
    </SidebarSection>
  )

  const aside = (
    <>
      {relatedTagsSection}
      {contact}
    </>
  )

  const desktopAside = <div className={styles.desktopAside}>{aside}</div>

  return (
    <Media
      desktop={() => (
        <div className="copy-article">
          <ScrollToTopOnUpdate />
          <BodySidebar
            body={
              <article>
                {topic}
                {heading}
                {body}
                {ctaBanner}
              </article>
            }
            sidebar={desktopAside}
          />
        </div>
      )}
      mobile={() => (
        <div className="copy-article">
          <ScrollToTopOnUpdate />
          <div
            className={clsx('block', spaceStyles.contain, styles.article)}
          >
            <article>
              {topic}
              {heading}
              {body}
            </article>
          </div>
          {ctaBanner}
          <aside className={styles.creamyBg}>
            <div className={clsx(spaceStyles.contain, styles.article)}>
              {aside}
            </div>
          </aside>
        </div>
      )}
    />
  )
}

export default TagResults
