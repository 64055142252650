import React, { useState, ReactNode } from 'react'
import useSafariBackPageLoad from '../util/useSafariPageLoadPersist';
import {iPhoneScrollFix, iPhoneScrollRestore} from "../quotes/experiences/search-app-start/iPhoneScrollFix";

type ModalCTAContextProps = {
  children?: ReactNode
}

type ModalCTAContextSettings = {
  isVisible: boolean
  showModal: () => void
  hideModal: () => void
}

const initialSettings: ModalCTAContextSettings = {
  isVisible: false,
  showModal: () => {},
  hideModal: () => {}
}

export const ModalCTAContext = React.createContext(initialSettings);

export function ModalCTAProvider({ children }: ModalCTAContextProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log('[Modal CTA Provider] is modal open', isModalOpen)
  
  useSafariBackPageLoad(() => setIsModalOpen(false), 'Modal CTA state')

  const contextObj = {
    isVisible: isModalOpen,
    showModal: () => {
      iPhoneScrollFix()
      setIsModalOpen(true)
    },
    hideModal: () => {
      iPhoneScrollRestore()
      setIsModalOpen(false)
    }
  }

  return(
    <ModalCTAContext.Provider value={contextObj}>
      {children}
    </ModalCTAContext.Provider>
  )
}
