import React from 'react'
import clsx from 'clsx'
import { ImageField, TextField } from '../../types/SitecoreAdapter'
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

export type PolicyDescriptionFields = {
  icon: ImageField
  title: TextField
  body: TextField
}

export type PolicyDescriptionItem = {
  fields: PolicyDescriptionFields
}

const PolicyDescription = (props: PolicyDescriptionItem) => {
  return (
    <div
      className={clsx(spaceStyles.contain, styles.policyDescriptionContainer)}
    >
      <div className={styles.wrap}>
        <div className={styles.descHeaderSection}>
          <Image className={styles.img} field={props.fields.icon} />
          <h3 className={clsx(`text ${styles.title}`)}>
            {props.fields.title.value}
          </h3>
        </div>
        <dl className={styles.list}>
          <dd className={styles.body}>
            <RichText field={props.fields.body} />
          </dd>
        </dl>
      </div>
    </div>
  )
}

export default PolicyDescription
