import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { TextField } from '../../../types/SitecoreAdapter'

import HomePageTagline from './HomePage-Tagline'

export type TagLineProps = {
  fields: {
    heading: TextField
    descriptor: TextField
  }
}

const HomePageTagLineAdapter = ({
  fields: { heading, descriptor },
}: TagLineProps) => {
  return (
    <HomePageTagline
      heading={<Text field={heading} />}
      descriptor={<Text field={descriptor} />}
    />
  )
}

export default HomePageTagLineAdapter
