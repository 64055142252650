import { PureComponent, createElement } from 'react'
import { NavLink } from 'react-router-dom'

import clsx from 'clsx'
import { moduleClassNames } from '../services/css-utils'

import styles from './styles.module.scss'
import LinkWrapper from '../../../components/LinkWrapper'

// props
//   - internal - this means the link is in Sitecore and can be loaded via React Router without a full page refresh
export default class MenuOption extends PureComponent {
  render() {
    const {
      href,
      theme,
      label,
      title,
      isOpen,
      hasChildren,
      noFollow = false,
      internal = false,
    } = this.props
    const noFollowProps = noFollow ? { rel: 'nofollow' } : {}

    const openableTabSyle = isOpen && styles.tab
    const hasChildrenTabStyle = hasChildren && styles.tabArrow
    const differTabAndLogin =
      !hasChildren && theme !== 'login' && styles.notChildTab

    const cssClasses = clsx(
      styles.base,
      moduleClassNames(theme, styles),
      hasChildrenTabStyle,
      openableTabSyle,
      differTabAndLogin
    )

    if (internal) {
      return createElement(
        NavLink,
        {
          to: href,
          title: title,
          className: cssClasses,
          activeClassName: styles.active,
          exact: true,
          'data-tag': 'tig-meganav',
          ...noFollowProps,
        },
        label
      )
    }

    return createElement(
      LinkWrapper,
      {
        href: href,
        text: label,
        title: title,
        className: cssClasses,
        'data-tag': 'tig-meganav',
        ...noFollowProps,
      },
      label
    )
  }
}
