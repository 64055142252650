import React, { ReactNode } from 'react'
import clsx from 'clsx'
import Button from '../../../../../components/Button'
import Media from '../../../../../components/Media'
import styles from './styles.module.scss'

export type QuoteButtonProps = {
  onClick?: (event: any) => void
  disabled?: boolean
  loading?: boolean
  isInputVisible?: boolean
}

export interface OptionalChildrenProp {
  children?: ReactNode
}

export default function QuoteButton({
  onClick,
  disabled,
  loading,
  isInputVisible = true,
  children,
}: QuoteButtonProps & OptionalChildrenProp) {

  const DesktopButton = () => {
    return (
      <Button
        className={clsx(styles.desktopQuoteButton, (isInputVisible && styles.inputVisible))}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        testId="QuoteButton"
        size={ isInputVisible ? "xlarge" : "xxxlarge" }
      >
        {children}
      </Button>
    )
  }

  const MobileButton = () => {
    return (
      <Button
        className={styles.mobileQuoteButton}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        testId="QuoteButton"
        size="xlarge"
      >
        {children}
      </Button>
    )
  }

  return (
    <Media
      desktop={() => <DesktopButton />}
      mobile={() => <MobileButton />}
    />
  )
}
