import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

const Container = React.memo(({ children, isOpen }) => {
  return (
    <div className={clsx(styles.container, isOpen && styles.isOpen)}>
      {children}
    </div>
  )
})

export default Container
