import React from 'react'
import styles from './styles.module.scss'

import Column, { ColumnProps } from './Column'

type ColumnsProps = {
  columns: ColumnProps[]
}

const Columns = ({ columns }: ColumnsProps) => {
  return (
    <div className={styles.columns}>
      {columns.map((column, i) => (
        <Column
          items={column.items}
          type={column.type}
          heading={column.heading}
          key={i}
        />
      ))}
    </div>
  )
}
export default React.memo(Columns)
