import React, { useContext } from 'react'
import styles from './styles.module.scss'
import Button from '../../Button'
import { ModalCTAContext } from '../../../context/ModalCTAContext'

type ModalButtonProps = {
  getQuotesLabel: React.ReactElement
}

const ModalButton = ({ getQuotesLabel }: ModalButtonProps) => {
  const { showModal } = useContext(ModalCTAContext)

  return (
    <div
      className={styles.modalButtonContainer}
      // Prevents `Policy-Card` custom click implementation from firing
      // See `Policy-Card/useCardClick.ts`
      onMouseDown={e => e.stopPropagation()}
    >
      <Button
        className={styles.modalButton}
        type="primary"
        size="slim"
        onClick={showModal}
      >
        {getQuotesLabel}
      </Button>
    </div>
  )
}

export default React.memo(ModalButton)
