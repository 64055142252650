import React from 'react'
import Video, { VideoProps } from './Video'
import { BooleanField, Item, TextField } from '../../types/SitecoreAdapter'
import IsPageEditing from '../IsPageEditing'
import { getIdFromUrl, getEmbedUrlFromId } from './services/getEmbedUrl'
import EditingPlacholder from './EditingPlaceholder'

type VideoFields = {
  youtubeUrl: TextField
  lazyLoad: BooleanField
}
type VideoAdapterProps = Item<VideoFields>

const getProps = (fields: VideoFields) => {
  const id = getIdFromUrl(fields.youtubeUrl.value)

  if (!id) {
    throw new Error(
      `Unabled to process YouTube ID for url: "${fields.youtubeUrl.value}"`
    )
  }

  return {
    id: id,
    embedUrl: getEmbedUrlFromId(id),
    lazyLoad: fields.lazyLoad.value,
  }
}

const getVideo = (fields: VideoFields) => {
  let props: VideoProps
  try {
    props = getProps(fields)
  } catch (e) {
    const err = e as any
    console.error(`Malformed video template: ${err.message || err}`)
    return null
  }

  return <Video {...props} />
}

const getEditingPlaceholder = (fields: VideoFields) => {
  const id = getIdFromUrl(fields.youtubeUrl.value) || ''
  return <EditingPlacholder id={id} />
}

const getInnerComponent = (fields: VideoFields, isEditing: boolean) => {
  if (isEditing) {
    return getEditingPlaceholder(fields)
  }

  return getVideo(fields)
}

const VideoAdapter = ({ fields }: VideoAdapterProps) => {
  return (
    <IsPageEditing>
      {(isEditing: boolean) => getInnerComponent(fields, isEditing)}
    </IsPageEditing>
  )
}

export default VideoAdapter
