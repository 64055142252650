import React from 'react'
import PolicyCardList from './PolicyCardList'
import {
  RichText,
  Text,
  withPlaceholder,
} from '@sitecore-jss/sitecore-jss-react'
import { BooleanField, TextField } from '../../types/SitecoreAdapter'
import { useSitecoreContext } from '../../util/useSitecoreContext'
import { FC } from '../../types/common'
import { PlaceholderProps } from '@sitecore-jss/sitecore-jss-react/types/components/PlaceholderCommon'

const placeholderName = 'PolicyCardsList-Placeholder'

type PolicyCardAdapterProps = PlaceholderProps & {
  placeholder: Array<any>
  fields: {
    threeCol: BooleanField
    escapeText: TextField
    title: TextField
    descriptor: TextField
  }
  testId?: string
}

const PolicyCardListAdapter: FC<PolicyCardAdapterProps> = ({
  placeholder,
  fields: { threeCol, escapeText, title, descriptor }, testId
}) => {
  const { sitecoreContext } = useSitecoreContext()
  const descriptorElement = <RichText field={descriptor} />
  const headingElement = <Text field={title} />
  const escapeTextElement = escapeText.value ? (
    <RichText field={escapeText} />
  ) : null
  const pageEditing = sitecoreContext && sitecoreContext.pageEditing

  return (
    <PolicyCardList
      testId={testId}
      descriptor={descriptorElement}
      escapeText={escapeTextElement}
      heading={headingElement}
      forceSingleCol={pageEditing}
      threeCol={threeCol.value}
    >
      {placeholder}
    </PolicyCardList>
  )
}

export default withPlaceholder({
  placeholder: placeholderName,
  prop: 'placeholder',
})(PolicyCardListAdapter as FC<PlaceholderProps>)
