import React from 'react'
import PageDescription from './PageDescription'
import PolicyDescription from '../../../components/PolicyDescription'
import { withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react'
import { SitecoreContextType } from '../../../types/SitecoreAdapter'

type PolicyFields = SitecoreContextType<{
  pageDescription: any
  policy: {
    fields: any
  }
}>

// Descriptor will be either the Page Description or a Policy Card
export const Descriptor = withSitecoreContext()(
  React.memo(
    ({
      sitecoreContext: {
        route: {
          fields: { pageDescription, policy },
        },
      },
    }: PolicyFields) => {
      if (policy && policy.fields) {
        return <PolicyDescription fields={policy.fields} />
      }

      return <PageDescription body={<RichText field={pageDescription} />} />
    }
  )
)
