import React, { useState, useContext } from 'react'
import clsx from 'clsx'

import { moduleClassNames } from '../services/css-utils'

import MenuOption from '../MenuOption'
import MegaMenu from './MegaMenu'

import baseStyles from '../styles.module.scss'
import styles from './styles.module.scss'
import { NavigationContext } from '../services/context'
import QuoteButton from './QuoteButton'

import LinkWrapper from '../../../components/LinkWrapper'

const sectionHasChildren = section =>
  (section.links && section.links.length > 0) ||
  (section.children && section.children.length > 0)

export default function DesktopMenu() {
  const [activeSection, setActiveSection] = useState(null)
  const [menuTimeout, setMenuTimeout] = useState()

  const handleOpenMenu = section => {
    return () => {
      if (activeSection === null) {
        clearTimeout(menuTimeout)

        setMenuTimeout(
          setTimeout(() => {
            setActiveSection(section)
          }, 250)
        )
      } else {
        setActiveSection(section)
      }
    }
  }

  const handleCloseMenu = () => {
    clearTimeout(menuTimeout)
    setActiveSection(null)
  }

  const { 
    logo,
    login,
    noMenus,
    menu,
  } = useContext(
    NavigationContext
  )

  const topLinks = menu.links.map((section, index) => {
    // If menu property 'mobileOnly' exists and is true, don't show in the desktop menu.
    if (section.mobileOnly) {
      return null
    }

    const isOpen = activeSection === section
    const hasChildren = sectionHasChildren(section)

    const megaMenu = !noMenus && hasChildren && (
      <MegaMenu
        isOpen={isOpen}
        section={section}
        closeHandler={handleCloseMenu}
      />
    )

    return (
      <div
        key={index}
        className={clsx(styles.tab)}
        onMouseEnter={handleOpenMenu(section)}
      >
        <MenuOption
          label={section.text}
          title={section.title}
          href={section.href}
          internal={section.internal}
          theme={['topnav']}
          isOpen={isOpen}
          hasChildren={hasChildren}
        />
        {megaMenu}
      </div>
    )
  })

  return (
    <React.Fragment>
      <nav
        className={clsx(styles.nav, moduleClassNames(styles))}
        onMouseLeave={handleCloseMenu}
        data-test-context="Nav"
      >
        <div className={styles.navbar}>
          <div className={styles.left}>
            <LinkWrapper
              image={logo.image}
              href={logo.href}
              title={logo.title}
              imageStyles={baseStyles.logo}
              className={baseStyles.logoLink}
            />
            {topLinks}
          </div>
          <div className={styles.right}>
            {login && (
              <MenuOption
                label={menu.login.text}
                title={menu.login.title}
                href={menu.login.href}
                theme="login"
                noFollow
              />
            )}
            <QuoteButton />
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}
