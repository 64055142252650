import React from 'react'
import loadable from '@loadable/component'
import {
  withSitecoreContext,
  Placeholder,
  Text,
  RichText,
  RouteData,
} from '@sitecore-jss/sitecore-jss-react'

import clsx from 'clsx'

import TabList, { SitecoreTab } from '../../page-components/IconTabs/Adapter'
import MajorMinorHero, {
  HeroFields,
} from '../../page-components/MajorMinor-Hero'
import BreadcrumbsFromRoute from '../../page-components/Breadcrumb/BreadcrumbsFromRoute'
import BreadcrumbList from '../../page-components/Breadcrumb/BreadcrumbList'
import PolicyH1 from './PolicyH1'
import PolicyDescription from '../../components/PolicyDescription'
import { BottomCTAItem } from '../../page-components/Bottom-CTA'

import styles from './styles.module.scss'
import PlaceholderFactory from '../../page-components/PlaceholderFactory'
import { DateField } from '../../types/SitecoreAdapter'

const BottomCTA = loadable(() => import('../../page-components/Bottom-CTA'))
const UpdatedDate = loadable(() => import('../../page-components/UpdatedDate'))

type PolicyRoute = {
  fields: {
    policyBase: {
      fields: {
        hero: {
          fields: HeroFields
        }
        iconTabs: SitecoreTab[]
        bottomCTA: BottomCTAItem
      }
    }
    pageHeading: any
    pageDescription: any
    policyDescription: any
    lastPublishedDate: DateField
    updatedDate: DateField
  }
} & RouteData

type PolicyPageProps = {
  sitecoreContext: {
    route: PolicyRoute
  }
}

function PolicyPage({ sitecoreContext: { route } }: PolicyPageProps) {
  const {
    policyBase,
    pageHeading,
    pageDescription,
    policyDescription,
    lastPublishedDate,
    updatedDate,
  } = route.fields

  const iconTabs = policyBase.fields.iconTabs

  const description = policyDescription ? (
    <PolicyDescription fields={policyDescription.fields} />
  ) : (
    pageDescription.value && (
      <RichText field={pageDescription} className={styles.pageDescription} />
    )
  )

  const bottomCTAfields =
    policyBase.fields.bottomCTA && policyBase.fields.bottomCTA.fields

  const heroProps = {...policyBase.fields.hero, applyDefaultImgSizing: true}

  return (
    <>
      <MajorMinorHero headingTag={'div'} {...heroProps} />
      <PlaceholderFactory name="Hero-Placeholder" />
      <TabList tabs={iconTabs} className={styles.tabs} />
      <div className="copy-article">
        <div className="contain">
          <BreadcrumbsFromRoute>
            {breadcrumbs => (
              <BreadcrumbList
                showWrap={false}
                breadcrumbs={breadcrumbs}
                isSticky={false}
                className={clsx(styles.breadcrumb, 'policyCrumb')}
                showCurrentPageCrumb={false}
              />
            )}
          </BreadcrumbsFromRoute>
          <PolicyH1>
            <Text field={pageHeading} />
          </PolicyH1>
          {description}
        </div>
        <div>
          <Placeholder name="Policy-Placeholder" rendering={route} />
        </div>
        <UpdatedDate
          date={updatedDate}
          fallbackDate={lastPublishedDate}
        />
        <BottomCTA fields={bottomCTAfields} />
      </div>
    </>
  )
}

export default withSitecoreContext()(PolicyPage)
