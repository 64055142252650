import React from 'react'
import loadable from '@loadable/component'
import {
  withSitecoreContext,
  RouteData,
} from '@sitecore-jss/sitecore-jss-react'

import clsx from 'clsx'

import BreadcrumbsFromRoute from '../../page-components/Breadcrumb/BreadcrumbsFromRoute'
import BreadcrumbList from '../../page-components/Breadcrumb/BreadcrumbList'
import MajorMinorHero, {
  HeroFields,
} from '../../page-components/MajorMinor-Hero'
import PlaceholderFactory from '../../page-components/PlaceholderFactory'
import styles from './styles.module.scss'

const BottomCTA = loadable(() => import('../../page-components/Bottom-CTA/WithContext'))

type Route = {
  fields: {
    hero: {
      fields: HeroFields
    }
  }
} & RouteData

function MajorPage({
  sitecoreContext: { route },
}: {
  sitecoreContext: { route: Route }
}) {
  const heroProps = {...route.fields.hero, applyDefaultImgSizing: true}
  return (
    <>
      <MajorMinorHero {...heroProps} />
      <PlaceholderFactory name="Hero-Placeholder" />
      <div className="copy major">
        <BreadcrumbsFromRoute>
          {breadcrumbs => (
            <BreadcrumbList
              showWrap={true}
              breadcrumbs={breadcrumbs}
              isSticky={false}
              className={clsx(styles.breadcrumb, 'majorCrumb')}
              paddedLeft
            />
          )}
        </BreadcrumbsFromRoute>
        <div className="MajorPlaceholder">
          <PlaceholderFactory name="Major-Placeholder" />
        </div>
        <BottomCTA />
      </div>
    </>
  )
}

export default withSitecoreContext()(MajorPage)
