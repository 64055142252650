import React, { ReactNode } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import TrustPilotWidget from '../../components/TrustPilot-Widget/TrustPilotWidget'
import Media from '../../components/Media'

type MajorMinorHeroBaseProps = {
  heading: JSX.Element
  descriptor: JSX.Element
  backgroundImage: JSX.Element
  isDark: boolean
  showTrustPilotWidget?: boolean
  children?: ReactNode
}

const MajorMinorHeroBase = ({
  heading,
  backgroundImage,
  isDark,
  children,
  showTrustPilotWidget = true,
}: MajorMinorHeroBaseProps) => {
  const heroHeadingStyles = showTrustPilotWidget
    ? clsx(styles.heading, styles.headingWithTrustPilot)
    : styles.heading

  return (
    <div
      className={clsx(styles.hero, isDark && styles.dark)}
      data-test-context="Hero"
    >
      <div className={styles.imageWrapper}>{backgroundImage}</div>
      <div className={styles.body}>
        {React.createElement(heading.type, {
          ...heading.props,
          className: heroHeadingStyles,
        })}
        {showTrustPilotWidget && (
          <div
            className={styles.microTPWidgetContainer}
            data-test-id={'hero-tp-widget'}
          >
            <Media
              mobile={() => (
                <TrustPilotWidget isDark={isDark} type="MicroStar" />
              )}
              tablet={() => <TrustPilotWidget isDark={isDark} type="Micro" />}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

export default MajorMinorHeroBase
