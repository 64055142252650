import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import TrustPilotWidget from '../../../components/TrustPilot-Widget/TrustPilotWidget'
import Media from '../../../components/Media'

type AboutUsHeroProps = {
  backgroundImage: React.ReactNode
  heading: React.ReactNode
  subheading?: React.ReactElement
  isDark: boolean
  showTrustPilotWidget?: boolean
}

const AboutUsHero = ({
  backgroundImage,
  heading,
  subheading,
  isDark,
  showTrustPilotWidget = true,
}: AboutUsHeroProps) => {
  return (
    <div
      className={clsx(styles.hero, isDark && styles.dark)}
      data-test-context="Hero"
    >
      <div className={styles.imageWrapper}>{backgroundImage}</div>
      <div className={styles.body}>
        <h1 className={styles.heading}>{heading}</h1>
        {showTrustPilotWidget && (
          <div
            className={styles.aboutUsTPwidget}
            data-test-id={'about-us-hero-tp-widget'}
          >
            <Media
              mobile={() => (
                <TrustPilotWidget isDark={isDark} type="MicroStar" />
              )}
              tablet={() => <TrustPilotWidget isDark={isDark} type="Micro" />}
            />
          </div>
        )}
        {subheading && <div className={styles.subheading}>{subheading}</div>}
      </div>
    </div>
  )
}

export default AboutUsHero
