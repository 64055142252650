import { compact, get, isEmpty } from 'lodash-es'
import { Item, TextField } from '../../../types/SitecoreAdapter'
import hasValues from '../../../util/hasValues'

export type ContactFields = Item<{
  hours: TextField
  days: TextField
  phone: TextField
  callUsTitle: TextField
}>

export default function contactAdapter({ fields }: ContactFields) {
  const hours = get(fields, 'hours.value'),
    days = get(fields, 'days.value'),
    phone = get(fields, 'phone.value'),
    callUsTitle = get(fields, 'callUsTitle.value')
  const heading =
    !isEmpty(hours) || !isEmpty(days) ? compact([hours, days]).join(', ') : ''
  const cleanPhone = phone.replace(/[^\d]/g, '')
  const link = {
    href: cleanPhone,
    text: callUsTitle || (isEmpty(cleanPhone) ? '' : `Call ${phone}`),
  }

  return { heading, link }
}
