import React from 'react'
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import {
  TextField,
  ImageField,
  BooleanField,
  Item,
  SitecoreContextType,
} from '../../../types/SitecoreAdapter'
import ResponsiveImage, {
  ResponsiveImageProps,
} from '../../../page-components/Responsive-Image'

import Media from '../../../components/Media'

import Button from '../../../components/Button'

import HomePageHero from './HomePage-Hero'
import SearchAppStartContext from '../../../quotes/experiences/search-app-start/context'
import SearchAppStart from '../../../quotes/experiences/search-app-start/embeds/hero'

import styles from './styles.module.scss'
import { TrustPilotWidgetGlobalSettingsType } from '../../../types/common'
import { get } from 'lodash-es'

type ButtonFields = {
  fields: { body: TextField; href: TextField }
}

export type HomePageHeroFields = {
  image: ImageField
  heading: TextField
  descriptor: TextField
  buttonLink: null | ButtonFields
  isDark: BooleanField
} & ResponsiveImageProps

type ImageSizingProps = {
  applyDefaultImgSizing?: boolean
}

export type HomePageHeroAdapterProps = {
  hero: {
    fields: HomePageHeroFields
  }
  searchAppStart: Item<{}> | null
  tpWidgetGlobalSettings: TrustPilotWidgetGlobalSettingsType
}

type ButtonSectionProps = {
  buttonLink: ButtonFields | null
}

const getButtonText = ({ buttonLink }: ButtonSectionProps) => {
  return buttonLink && buttonLink.fields
    ? buttonLink.fields.body.value
    : undefined
}

const ButtonSection = ({ buttonLink }: ButtonSectionProps) => {
  return (
    <div className={styles.buttonWrapper}>
      <Media
        mobile={() => (
          <Button
            size="large"
            href={(buttonLink && buttonLink.fields.href.value) || ''}
            testId="SelectQuote"
            fill
          >
            {buttonLink && <Text field={buttonLink.fields.body} />}
          </Button>
        )}
        tablet={() => (
          <Button
            size="xlarge"
            href={(buttonLink && buttonLink.fields.href.value) || ''}
            testId="SelectQuote"
            fill
          >
            {buttonLink && <Text field={buttonLink.fields.body} />}
          </Button>
        )}
        wide={() => (
          <Button
            size="xxxlarge"
            href={(buttonLink && buttonLink.fields.href.value) || ''}
            testId="SelectQuote"
          >
            {buttonLink && <Text field={buttonLink.fields.body} />}
          </Button>
        )}
      />
    </div>
  )
}

export const HomePageHeroAdapter = ({
  hero: {
    fields: {
      heading,
      descriptor,
      buttonLink,
      isDark,
      desktopImage,
      tabletImage,
      mobileImage,
    },
  },
  tpWidgetGlobalSettings,
  searchAppStart,
  applyDefaultImgSizing,
}: HomePageHeroAdapterProps & ImageSizingProps) => {
  const searchAppStartSettings = searchAppStart
  const enableTrustPilotHeroWidget = get(
    tpWidgetGlobalSettings,
    'fields.enableTrustPilotHeroWidget.value'
  )

  const BodySection = () => {
    if (searchAppStartSettings) {
      return (
        <SearchAppStartContext quoteButtonText={getButtonText({ buttonLink })}>
          <SearchAppStart />
        </SearchAppStartContext>
      )
    }

    return <ButtonSection buttonLink={buttonLink} />
  }

  return (
    <HomePageHero
      showTrustPilotWidget={enableTrustPilotHeroWidget}
      backgroundImage={
        <ResponsiveImage
          desktopImage={desktopImage}
          tabletImage={tabletImage}
          mobileImage={mobileImage}
          applyDefaultImgSizing={applyDefaultImgSizing}
        />
      }
      heading={<Text field={heading} />}
      descriptor={<Text field={descriptor} />}
      isDark={isDark.value}
    >
      <BodySection />
    </HomePageHero>
  )
}

type HeroFromRouteProps = SitecoreContextType<HomePageHeroAdapterProps> &
  ImageSizingProps

export function HomepageHeroFromRoute({
  sitecoreContext: {
    route: { fields },
  },
  applyDefaultImgSizing,
}: HeroFromRouteProps) {
  return (
    <HomePageHeroAdapter
      {...fields}
      applyDefaultImgSizing={applyDefaultImgSizing}
    />
  )
}

export default withSitecoreContext()(HomepageHeroFromRoute)
