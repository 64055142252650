import React, { FC } from 'react'
import { StateModule } from './StateModule'
import { StateLinkType } from './Link'

export type StateModuleProps = {
  fields: {
    image: string
    imageAlt: string
    heading: string
    headingSize: string
    descriptor: string
    buttonLabel: string
    options: StateLinkType[]
  }
}

const StateModuleAdapter: FC<StateModuleProps> = ({ fields }) => {
  const {
    image,
    imageAlt,
    heading,
    headingSize,
    descriptor,
    buttonLabel,
    options,
  } = fields

  return (
    <StateModule
      heading={heading}
      headingSize={headingSize}
      descriptor={descriptor}
      buttonLabel={buttonLabel}
      options={options}
      image={image}
      imageAlt={imageAlt}
    />
  )
}

export default StateModuleAdapter
