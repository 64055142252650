import React, { FC } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import H3 from './H3'
import { TextField } from '../../types/SitecoreAdapter'

type Props = {
  fields: {
    text: TextField
  }
}

const Heading3: FC<Props> = ({ fields: { text } }) => {
  return (
    <H3>
      <Text field={text} />
    </H3>
  )
}

export default Heading3
