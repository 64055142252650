import React from 'react'
import clsx from 'clsx'
import { take } from 'lodash-es'

import Button from '../../../Button'
import Media from '../../../Media'

import { LinkButtonFields } from '../../types'

import styles from './styles.module.scss'
import ModalCTA from '../../../Modal-CTA/ModalCTA'

type ExploreMoreSectionProps = {
  text?: string
  title?: string
  href?: string
}

export type GridRouterProps = {
  heading?: React.ReactNode
  subhead?: React.ReactNode
  items: JSX.Element[]
  exploreMoreButton?: LinkButtonFields
  exploreMoreSection?: ExploreMoreSectionProps
  exploreMoreType: string
}

type PageProps = {
  pageType?: string
}

function ExploreMoreSectionAdapter({
  exploreMore,
  exploreMoreType,
}: {
  exploreMore: LinkButtonFields | undefined
  exploreMoreType: string
}) {
  if (!exploreMore) {
    return null
  }

  if (exploreMoreType === 'CTA') {
    return (
      <ModalCTA
        buttonClassName={clsx('button', styles.button, styles.link)}
        primary={true}
        size="medium"
        alignment={'Center'}
        testId={'UniGridRouter-ExploreMoreActionBtn'}
      >
        {exploreMore.text.value}
      </ModalCTA>
    )
  }

  return (
    <Button
      href={exploreMore.href.value}
      title={exploreMore.title.value}
      type="secondary"
      testId={'UniGridRouter-ExploreMoreActionBtn'}
    >
      {exploreMore.text.value}
    </Button>
  )
}

export default function GridRouterAdapter({
  heading,
  subhead,
  items,
  exploreMoreButton,
  exploreMoreSection,
  exploreMoreType,
  pageType,
}: GridRouterProps & PageProps) {
  const pageStyles =
    pageType === 'article' || pageType === 'policy'
      ? 'typeArticle contain'
      : pageType === 'home' || pageType === 'about'
      ? 'typeHome'
      : ''

  return (
    <div data-test-id={'UniversalRouter-Wrap'} className={clsx('GridRouter', pageStyles)}>
      {heading && (
        <div className={clsx('brick', styles.heading)}>{heading}</div>
      )}
      {subhead && <div className={styles.subheading}>{subhead}</div>}
      <div className={styles.items}>
        <Media mobile={() => take(items, 10)} tablet={() => items} />
      </div>
      {exploreMoreButton && (
        <div className={clsx(styles.foot)}>
          <ExploreMoreSectionAdapter exploreMore={exploreMoreButton} exploreMoreType={exploreMoreType} />
        </div>
      )}
      {exploreMoreSection && exploreMoreSection.href !== '' && (
        <div className={clsx(styles.foot)}>
          <Button
            href={exploreMoreSection.href}
            title={exploreMoreSection.title}
            type={exploreMoreType}
            testId={'UniGridRouter-ExploreMoreActionBtn'}
          >
            {exploreMoreSection.text}
          </Button>
        </div>
      )}
    </div>
  )
}
