import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import LinkWrapper from '../../../../../components/LinkWrapper'

const MenuOptionOpenSubmenu = React.memo(({ onSelect, title, href, text }) => {
  const handleClick = event => {
    event.preventDefault()
    onSelect()
  }

  return (
    <LinkWrapper
      onClick={handleClick}
      href={href}
      text={text}
      title={title}
      data-tag="tig-meganav"
      className={clsx(styles.root, styles.arrow)}
    />
  )
})

export default MenuOptionOpenSubmenu
