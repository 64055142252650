import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

type LinkedPageCrumbProps = {
  href: string
  title: JSX.Element
  linkTitle: string 
}

export function LinkedPageCrumb({ href, title, linkTitle }: LinkedPageCrumbProps) {
  if (!href || !title) return null
  const titleAttribute = linkTitle ? {title: linkTitle} : {}
  return (
    <span className={clsx(styles.crumb, styles.linkedPageCrumb)}>
      <a className={clsx(styles.breadcrumbLink, styles.linkTitle)} href={href} {...titleAttribute}>
        {title}
      </a>
    </span>
  )
}
