import React, { useState } from 'react'
import clsx from 'clsx'

import TabList from './components/TabList/TabList'
import { CustomImageCore } from '../CustomImage/CustomImage'

import styles from './styles.module.scss'
import TabbedFeatureButton, {
  TabbedFeatureButtonProps,
} from './TabbedFeatureButton'

type TabbedCardProps = {
  tabText: string
  heading: string
  body: string
  image: React.ReactElement
  imageCaption?: React.ReactElement | string
  cta?: TabbedFeatureButtonProps
}

type HeadingProps = {
  heading: string
}

export type TabbedFeatureBaseProps = {
  heading: string
  cards: TabbedCardProps[]
  initialSelectedIndex?: number
}

const Heading = ({ heading }: HeadingProps) => (
  <h2 className={styles.heading}>{heading}</h2>
)

const TabbedFeature = ({
  heading,
  cards,
  initialSelectedIndex = 0,
}: TabbedFeatureBaseProps) => {
  const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex)

  const tabs = cards.map((card, index) => ({
    text: card.tabText,
    href: '',
    active: index === selectedIndex,
    onTabSelect: () => setSelectedIndex(index),
  }))

  const content = cards[selectedIndex] || { image: '' }

  const image =
    typeof content.image === 'string' ? (
      <img src={content.image} alt="" />
    ) : (
      content.image
    )

  const leftColumnContent = (
    <div className={styles.leftColumnContent}>
      <h3 className={styles.leftColumnHeading}>{content.heading}</h3>
      <p className={styles.leftColumnText}>{content.body}</p>
      {content.cta && (
        <div className={styles.buttonWrapper}>
          <TabbedFeatureButton {...content.cta} />
        </div>
      )}
    </div>
  )

  const rightColumnContent = (
    <div className={styles.rightColumnContent}>
      <CustomImageCore
        image={React.createElement(image.type, {
          ...image.props,
          className: styles.image,
        })}
        caption={content.imageCaption}
        className=""
      />
    </div>
  )

  return (
    <div className={clsx(styles.container, 'block', 'TabbedFeature')}>
      <Heading heading={heading} />
      <TabList tabs={tabs} className="" />
      <div className={styles.columns}>
        {leftColumnContent}
        {rightColumnContent}
      </div>
      <div
        className={clsx(
          styles.bottomBorder,
          content.imageCaption && styles.captionPadding
        )}
      />
    </div>
  )
}

export { Heading }

export default TabbedFeature
