import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { LinkWrapperWithChildItems } from '../../LinkWrapper'
import ImageWrapper from '../../ImageWrapper'
import { ImageProps } from '../../ImageWrapper/ImageTypes'

import styles from './styles.module.scss'

export type CardProps = {
  title: ReactNode
  titleAttr: {
    href: string
    newWindow: boolean
  }
  description: ReactNode
  image?: ImageProps | null
  featuredLink: ReactNode
}

export default function Card({
  title,
  titleAttr,
  description,
  image,
  featuredLink
}: CardProps) {
  return (
    <div
      data-test-id="FeaturedContent-Card"
      className={clsx(
        styles.root,
        image ? undefined : styles.noThumbnail
      )}
    >
      {image && image.src && (
        <ImageWrapper
          src={image.src}
          imageStyles={styles.thumbnail}
          alt={image.alt}
        />
      )}
      <div className={styles.body}>
        <LinkWrapperWithChildItems
          href={titleAttr.href}
          newWindow={titleAttr.newWindow}
          className={clsx(styles.title, 'h3')}
        >
          {title}
        </LinkWrapperWithChildItems>
        <div className={styles.description}>{description}</div>
        <div className={styles.link}>{featuredLink}</div>
      </div>
    </div>
  )
}
