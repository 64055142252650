import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import LinkWrapper from '../../../../../components/LinkWrapper'

const SubmenuLink = React.memo(({ text, title, href, className }) => (
  <LinkWrapper
    href={href}
    text={text}
    title={title}
    className={clsx(styles.root, className && styles[className])}
    data-tag="tig-meganav"
  />
))

export default SubmenuLink
