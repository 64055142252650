import React from 'react'
import ArrowImage from './images/arrow.png'
import styles from './styles.module.scss'

type ArrowProps = {
  className?: string
  style?: { [key: string]: string }
  onClick?: () => void
}

export const NextArrow = (props: ArrowProps) => {
  const { className, style, onClick } = props
  return (
    <div className={styles.next}>
      <img
        src={ArrowImage}
        style={{ ...style, width: '3rem', height: '3rem' }}
        alt="Carousel next arrow"
        onClick={onClick}
        className={className}
      />
    </div>
  )
}

export const PrevArrow = (props: ArrowProps) => {
  const { className, style, onClick } = props
  return (
    <div className={styles.prev}>
      <img
        src={ArrowImage}
        style={{
          ...style,
          transform: 'scaleX(-1) translate(0,-50%)',
          width: '3rem',
          height: '3rem',
        }}
        alt="Carousel prev arrow"
        onClick={onClick}
        className={className}
      />
    </div>
  )
}