import React from 'react'
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import getLinkValues from '../../util/GetLinkValues'
import PolicyCard from './Policy-Card'
import PolicyCardButtons from './PolicyCardButtons'
import {
  ImageField,
  TextField,
  LinkObjectField,
} from '../../types/SitecoreAdapter'

type PolicyCardAdapterProps = {
  fields: {
    icon: ImageField
    link: LinkObjectField
    title: TextField
    body: TextField
    bestFor: TextField
    desktopButton: TextField
    mobileButton: TextField
    quoteButtonText: TextField
  }
  testId?: string
}

function learnMoreLabel(mobileButton: TextField, desktopButton: TextField) {
  return <Text field={mobileButton.value ? mobileButton : desktopButton} />
}

const PolicyCardAdapter = ({
  fields: {
    icon,
    link,
    title,
    body,
    bestFor,
    desktopButton,
    mobileButton,
    quoteButtonText,
  }, testId
}: PolicyCardAdapterProps) => {
  return (
    <PolicyCard
      testId={testId}
      icon={<Image field={icon} />}
      link={getLinkValues(link)}
      title={title.value}
      body={<RichText field={body} />}
      bestFor={<RichText field={bestFor} />}
      desktopButton={<Text field={desktopButton} />}
      mobileButton={
        <Text field={mobileButton.value ? mobileButton : desktopButton} />
      }
    >
      <PolicyCardButtons
        getQuotesLabel={<Text field={quoteButtonText} />}
        learnMoreLabel={learnMoreLabel(mobileButton, desktopButton)}
      />
    </PolicyCard>
  )
}

export default PolicyCardAdapter
