import React from 'react'
import Container from './Container'
import Overlay from './Overlay'
import SlideoutStage from './SlideoutStage'
import RootMenu from './RootMenu'
import AllSubmenus from './Submenu/AllSubmenus'

// onRequestClose (force : bool)
// onShowSection (section : MenuSection), sets new section as active
// menuClassName -- css module class for the slideout area
export default React.memo(function ({
  links,
  activeSection,
  isOpen,
  menuClassName,
  onRequestClose,
  onShowSection,
  hideCallUs,
}) {
  // because Google indexing with javascript is unpredictable,
  // we render all of the menu options, the root menu, and all submenus,
  // so they are present on the page, always.
  // this also helps a bit with css animation, because the DOM is present before the animation begins.)
  const linksWithoutCallUs = links
    .slice()
    .filter((item) => item.text.toLowerCase() !== 'call us')
  return (
    <Container isOpen={isOpen}>
      <Overlay onClick={onRequestClose} />
      <SlideoutStage className={menuClassName} isOpen={isOpen}>
        <RootMenu onShowSection={onShowSection} specs={links} isOpen={isOpen} />
        <AllSubmenus
          specs={hideCallUs ? linksWithoutCallUs : links}
          activeSection={activeSection}
          clearSection={() => onShowSection(null)}
        />
      </SlideoutStage>
    </Container>
  )
})
