import React from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react'
import { BooleanField, ImageField, Item, TextField } from '../../../types/SitecoreAdapter'

export type SecurityImageType = {
  href: TextField
  text: TextField
  title?: TextField
  newWindow?: BooleanField
  mobileImage: ImageField
  image: ImageField
}

export type SecurityFields = Item<{
  securityImages: Item<SecurityImageType>[]
}>

export default function securityImagesAdapter({
  fields: { securityImages },
}: SecurityFields) {
  return securityImages.map(
    ({ fields: { href, text, title, newWindow, mobileImage, image } }) => {
      return {
        text: text && text.value,
        href: href && href.value,
        title: title && title.value,
        newWindow: newWindow && newWindow.value,
        image: image && <Image field={image} />,
        mobileImage: mobileImage && <Image field={mobileImage} />,
      }
    }
  )
}
