import React from 'react'
import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'
import typeStyles from '../../styles/type.module.scss'
import clsx from 'clsx'

type SpecialListProps = {
  icon: React.ReactElement
  children?: any
  testId?: string
}

const SpecialList = ({ icon, children, testId }: SpecialListProps) => {
  return (
    <div
      data-test-id={testId}
      className={clsx(
        'block',
        'SpecialList',
        styles.wrap,
        spaceStyles.contain
      )}
    >
      <div className={styles.smallImg}>
        {icon}
      </div>
      <div className={styles.right}>
        <div className={clsx(styles.body, typeStyles.list)}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default SpecialList
