import React, { FC } from 'react'
import Helmet from 'react-helmet'
import {
  ComponentConsumerProps,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import { ImageField, TextField } from '../../types/SitecoreAdapter'

type Props = ComponentConsumerProps & {
  sitecoreContext: {
    route: {
      fields: {
        canonical: TextField
        description: TextField
        openGraphDescription: TextField
        openGraphTitle: TextField
        pageTitle: TextField
        robots: TextField
        socialSharing: ImageField
        twitterDescription: TextField
        twitterTitle: TextField
        twitterImage: ImageField
        openGraphImage: ImageField
      }
    }
  }
}

const Meta: FC<Props> = ({ sitecoreContext }) => {
  const {
    canonical,
    description,
    openGraphDescription,
    openGraphTitle,
    pageTitle,
    robots,
    socialSharing,
    twitterDescription,
    twitterTitle,
    twitterImage,
    openGraphImage,
  } = sitecoreContext.route.fields

  const socialSharingSrc = socialSharing.value && socialSharing.value.src
  const socialSharingAlt = socialSharing.value && socialSharing.value.alt

  const openGraphTitleData = openGraphTitle.value || pageTitle.value
  const twitterTitleData = twitterTitle.value || pageTitle.value
  const openGraphDescriptionData =
    openGraphDescription.value || description.value
  const twitterDescriptionData = twitterDescription.value || description.value

  const twitterImageSrc =
    (twitterImage.value && twitterImage.value.src) || socialSharingSrc
  const openGraphImageSrc =
    (openGraphImage.value && openGraphImage.value.src) || socialSharingSrc

  return (
    <Helmet>
      <title>{pageTitle.value}</title>
      <meta
        name="robots"
        content={robots.value ? 'index, follow' : 'noindex, nofollow'}
      />
      <meta name="description" content={description.value} />
      <link rel="canonical" href={canonical.value} />
      <meta property="og:title" content={openGraphTitleData} />
      <meta name="twitter:title" content={twitterTitleData} />
      <meta property="og:description" content={openGraphDescriptionData} />
      <meta name="twitter:description" content={twitterDescriptionData} />
      <meta property="og:image" content={openGraphImageSrc} />
      <meta name="twitter:image" content={twitterImageSrc} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@InsureTechBiz" />
      <meta name="twitter:creator" content="@InsureTechBiz" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonical.value} />
      <meta property="fb:app_id" content="998396643847891" />
      <meta name="twitter:image:alt" content={socialSharingAlt} />
    </Helmet>
  )
}

export default withSitecoreContext()(Meta)
