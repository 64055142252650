import React, { ReactNode, memo } from 'react'
import H2AndDescriptor from '../H2AndDescriptor/H2AndDescriptor'
import EscapeText from './EscapeText'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { FC } from '../../types/common'

type Props = {
  heading: ReactNode
  descriptor: ReactNode
  escapeText: ReactNode
  testId?: string
}

const CardSection: FC<Props> = ({
  heading,
  descriptor,
  escapeText,
  children,
  testId,
}) => {
  const sectionClasses = clsx(
    'block',
    styles.policyCardWrap,
    'PolicyCardSection'
  )

  return (
    <section className={sectionClasses}>
      <H2AndDescriptor
        heading={heading}
        descriptor={descriptor}
        testId={testId}
      />
      <div className={styles.list}>{children}</div>
      <EscapeText escapeText={escapeText} />
    </section>
  )
}

export default memo(CardSection)
