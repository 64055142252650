import React, { FC, ReactNode } from 'react'
import CardSection from './CardSection'
import Columns from './Columns'
import Media from '../Media'

export type PolicyCardListProps = {
  heading: ReactNode
  descriptor: ReactNode
  escapeText: ReactNode
  threeCol: boolean
  forceSingleCol?: boolean
  children: Array<ReactNode>
  testId?: string
}

const PolicyCardList = (props: PolicyCardListProps) => {
  const {
    heading,
    descriptor,
    escapeText,
    threeCol,
    forceSingleCol,
    children,
    testId
  } = props

  const Section = ({ columnCount }: { columnCount: number }) => (
    <CardSection
      testId={testId}
      escapeText={escapeText}
      heading={heading}
      descriptor={descriptor}
    >
      <Columns columnCount={columnCount}>{children}</Columns>
    </CardSection>
  )

  const Section1Column = React.memo(() => <Section columnCount={1} />)
  const Section2Column = React.memo(() => <Section columnCount={2} />)
  const Section3Column = React.memo(() => <Section columnCount={3} />)

  // page editor only works with single column
  if (forceSingleCol) {
    return <Section1Column />
  }

  return (
    <Media
      mobile={() => <Section1Column />}
      tablet={() => <Section2Column />}
      desktop={() => (threeCol ? <Section3Column /> : <Section2Column />)}
    />
  )
}

export default PolicyCardList
