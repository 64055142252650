import React, { ReactElement, ReactNode } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

type H2IconProps = {
  heading: ReactElement
  icon: ReactElement
  body: ReactNode
}

const H2andIcon = ({ heading, icon, body }: H2IconProps) => {
  return (
    <div data-test-id="H2AndIcon" className={clsx(spaceStyles.contain, 'block', styles.wrap)}>
      <div className={styles.icon}>
        {React.createElement(icon.type, {
          ...icon.props,
          width: '100%',
          height: '100%',
        })}
      </div>
      <div className={styles.right}>
        {React.createElement(heading.type, {
          ...heading.props,
          className: styles.heading,
        })}
        <div className={clsx(styles.body, 'Subsection', 'list-style')}>
          {body}
        </div>
      </div>
    </div>
  )
}

export default H2andIcon
