import React, { memo } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

export function TableContainer({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return <div className={clsx(styles.table, className)}>{children}</div>
}

export default memo(TableContainer)
