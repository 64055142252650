import React, { ReactNode } from 'react'
import styles from './styles.module.scss'
import { FC } from '../../types/common'

type Props = {
  heading: ReactNode
  descriptor: ReactNode
  testId?: string
}

const QuoteLanding: FC<Props> = ({ heading, descriptor, children, testId }) => {
  return (
    <div
      data-test-id={testId}
      className={styles.container}
      data-test-context="QuoteLanding"
    >
      <h1 className={styles.heading}>{heading}</h1>
      <div className={styles.descriptor}>{descriptor}</div>
      {children}
    </div>
  )
}

export default QuoteLanding
