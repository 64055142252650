import React, { ReactNode, useContext } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import Button from '../Button'
import { ModalCTAContext } from '../../context/ModalCTAContext'
import LinkWrapper from '../LinkWrapper'
import { Field, Image } from '@sitecore-jss/sitecore-jss-react'
import { ImageField } from '../../types/SitecoreAdapter'

export type BaseCTAButtonProps = {
  text: string
}

export type BaseLearnMoreProps = {
  href: string
  body: string
  title: string
} | null

export type InlineCTAProps = {
  heading: ReactNode
  button: BaseCTAButtonProps
  learnMore: BaseLearnMoreProps
  testId?: string
  direction?: Field<'ltr' | 'rtl'>
  ctaImage?: ImageField
}

const InlineCTA = ({
  heading,
  button,
  learnMore,
  ctaImage,
  direction,
}: InlineCTAProps) => {
  const { showModal } = useContext(ModalCTAContext)
  const CTADirection = direction?.value || 'ltr'
  const CTAImage = ctaImage?.value

  const learnMoreEl = learnMore && learnMore.body && (
    <div className={styles.learnMore}>
      <LinkWrapper
        href={learnMore.href}
        title={learnMore.title}
        text={learnMore.body}
      />
    </div>
  )

  const buttonEl = button && (
    <div className={styles.buttonWrapper}>
      <Button
        className={styles.button}
        testId="Inline-CTA-button"
        type="primary"
        size="large"
        onClick={showModal}
      >
        {button.text}
      </Button>
    </div>
  )

  return (
    <div
      data-test-id="Inline-CTA"
      className={clsx(styles.contain, 'block Inline-CTA')}
    >
      <div className={styles.grad} />
      <div className={clsx(styles.container, styles[CTADirection])}>
        <div className={styles.ctaContentWrap}>
          <div className={styles.head}>{heading}</div>
          {buttonEl}
          {learnMoreEl}
        </div>
        {CTAImage && (
          <div className={styles.ctaImageWrap}>
            <Image
              field={{
                ...CTAImage,
                width: 239,
                height: 157,
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(InlineCTA)
