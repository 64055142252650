import React from 'react'

import LinkWrapper from '../../components/LinkWrapper'
import { LinkWrapperProps } from '../../components/LinkWrapper/LinkTypes'

import styles from './styles.module.scss'

type CopyrightLinkWrapperProps = {
  copyrightLink?: LinkWrapperProps
}

type InfoSectionProps = CopyrightLinkWrapperProps & {
  copyright: React.ReactNode
  disclaimer: React.ReactNode
}

const CopyrightLinkWrapper = ({ copyrightLink }: CopyrightLinkWrapperProps) => {
  if (!copyrightLink || !copyrightLink.href || !copyrightLink.text) {
    return null
  }

  return (
    <LinkWrapper
      className={styles.copyrightLink}
      href={copyrightLink.href}
      text={copyrightLink.text}
      title={copyrightLink.title}
    />
  )
}

const Info = ({ copyright, copyrightLink, disclaimer }: InfoSectionProps) => {
  const currentYear = new Date().getFullYear()

  return (
    <div className={styles.info}>
      &copy; {currentYear}{' '} <CopyrightLinkWrapper copyrightLink={copyrightLink} />
      {copyright}
      <div className={styles.disclaimer}>{disclaimer}</div>
    </div>
  )
}
export default React.memo(Info)
