import React, { ReactNode } from 'react'

import Video from '../Video/Video'
import { getIdFromUrl, getEmbedUrlFromId } from '../Video/services/getEmbedUrl'

import clsx from 'clsx'

type VideoComponentWithListProps = {
  heading?: string
  videoUrl: string
  listPlaceholder: ReactNode
  className?: string
  testId?: string
}

type StylingProps = {
  styles: { [key: string]: string }
}

const VideoComponentWithList = ({
  heading,
  videoUrl,
  listPlaceholder,
  styles,
  className,
  testId
}: VideoComponentWithListProps & StylingProps) => {
  const videoId = getIdFromUrl(videoUrl) || ''
  const videoEmbedUrl = videoId ? getEmbedUrlFromId(videoId) : ''

  const videoEl = (
    <div className={styles.multiColVideoComponent}>
      <Video id={videoId} embedUrl={videoEmbedUrl} lazyLoad={true} />
    </div>
  )

  return (
    <div data-test-id={testId} className={clsx(styles.multiColumnVideoContainer, 'VideoComponentWithList')}>
      <div className={clsx(styles.multiColumnVideo)}>
        {heading && (
          <h2 className={clsx(styles.header)}>
            {heading}
          </h2>
        )}
        <div className={styles.columns}>
          <div className={styles.leftColumn}>
            {videoEl}
          </div>
          <div className={clsx(styles.col, styles.rightColumn)}>
            {listPlaceholder}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoComponentWithList
