import React from 'react'
import LinkWrapper from '../../../components/LinkWrapper'
import { LinkWrapperProps } from '../../../components/LinkWrapper/LinkTypes'
import styles from './styles.module.scss'
import TrustPilotWidget from '../../../components/TrustPilot-Widget/TrustPilotWidget'
import { isEmpty } from 'lodash-es'

type ContantBannerProps = {
  contact: {
    heading: string
    link: LinkWrapperProps
  }
  showTrustPilotWidget: boolean
}

const ContactBanner = ({
  contact: { heading, link },
  showTrustPilotWidget,
}: ContantBannerProps) => {
  const showHeading = !isEmpty(heading)
  const hasCallUsSection = !isEmpty(link.text) || !isEmpty(link.href)
  const showCallUsAsLink = !isEmpty(link.text) && !isEmpty(link.href)
  const callUsHref = `tel:+1${link.href}`
  if (!showHeading && !hasCallUsSection && !showTrustPilotWidget) return null
  return (
    <div className={styles.banner}>
      {showTrustPilotWidget && (
        <TrustPilotWidget
          type="line"
          externalStyles={
            !hasCallUsSection && !showHeading ? styles.trustPilotLine : ''
          }
        />
      )}
      {hasCallUsSection &&
        (showCallUsAsLink ? (
          <LinkWrapper text={link.text} href={callUsHref} data-tag="footer" />
        ) : (
          <span className={styles.callUsTitle}>{link.text || callUsHref}</span>
        ))}
      {showHeading && <span className={styles.time}>{heading}</span>}
    </div>
  )
}
export default React.memo(ContactBanner)
