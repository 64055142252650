import React, { FC } from 'react'
import {
  RichText,
  Text,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import PullQuote from './PullQuote'
import { TextField } from '../../types/SitecoreAdapter'

function getAttributionText(attribution: TextField) {
  if (!attribution || !attribution.value) {
    return null
  }

  return <Text field={attribution} />
}

type Props = {
  fields: {
    body: TextField
    attribution: TextField
  }
  testId?: string
  sitecoreContext: any
}

const PullQuoteAdapter: FC<Props> = ({ fields: { body, attribution }, testId }) => {
  const bodyText = <RichText field={body} />
  const attributionText = getAttributionText(attribution)
  return <PullQuote testId={testId} body={bodyText} attribution={attributionText} />
}

export default withSitecoreContext()(PullQuoteAdapter)
