// Not only desktop
// It's also used to render a responsive row of tabs for Tag Results
import React, { FC } from 'react'
import TabNavLink from './TabNavLink'
import TabButton from './TabButton'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { TabListProps } from '../TabList'

const Tabs: FC<TabListProps> = ({ tabs, className }) => {
  const tabElements = tabs.map((tab, index) => {
    const { onTabSelect, text, active, href } = tab
    return href ? (
      <TabNavLink key={index} text={text} href={href} />
    ) : (
      <TabButton
        key={index}
        text={text}
        active={active}
        onTabSelect={onTabSelect}
      />
    )
  })

  return (
    <div className={clsx(styles.base)}>
      <div className={clsx(styles.inner, className)}>{tabElements}</div>
    </div>
  )
}

export default Tabs
