import React, { memo, ReactNode } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

export type EscapeLinkStyles = { root: string }

type EscapeLinkProps<ClickAnalytics> = {
  onClickAwait?: () => Promise<ClickAnalytics>
  darkBg?: Boolean
  escapeLinkHref: string
  children?: ReactNode
  styleOverrides?: EscapeLinkStyles
}

export default function EscapeLink<ClickAnalytics>({
  onClickAwait,
  darkBg = true,
  escapeLinkHref,
  children,
  styleOverrides,
}: EscapeLinkProps<ClickAnalytics>) {
  const handleClick = () => {
    if (onClickAwait) {
      onClickAwait().finally(() => {
        console.log('[Search App] Espcape Link waited for analytics')
        window.location.href = escapeLinkHref
      })

      // don't follow the link by default
      return false
    } else {
      return true
    }
  }

  return (
    <div className={clsx(styles.root, styleOverrides && styleOverrides.root)}>
      <a
        href={escapeLinkHref}
        className={clsx(styles.link, darkBg && styles.inverse)}
        onClick={handleClick}
      >
        {children}
      </a>
    </div>
  )
}
