import React from 'react'
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import {
  Item,
  TextField,
  ImageField,
  BooleanField,
  LinkField,
} from '../../types/SitecoreAdapter'
import CostModule, { CostModuleProps } from './CostModule'

type CostModuleFields = {
  heading: TextField
  image: ImageField
  body: TextField
  button: TextField
  mobileButton: TextField
  linkText: LinkField
  linkTitle: TextField
  isModalCTA: BooleanField
  buttonType: TextField
}

type CostModuleAdapterProps = Item<CostModuleFields>

const getProps = (fields: CostModuleFields) => {
  const { button, mobileButton, buttonType, isModalCTA } = fields

  const heading = <Text field={fields.heading} />
  const image = <Image field={fields.image} />
  const body = <RichText field={fields.body} />
  const desktopText = <Text field={button} />
  const mobileText = mobileButton.value ? <Text field={mobileButton} /> : desktopText
  const isCTA = isModalCTA.value || (fields.linkText && fields.linkText.value.href === '/start-quote')

  return {
    heading,
    image,
    body,
    cta: {
      href: (fields.linkText && fields.linkText.value.href),
      title: (fields.linkTitle && fields.linkTitle.value),
      mobileText,
      desktopText,
      type: (buttonType.value === 'Secondary') ? 'secondary' : 'primary',
      isCTA: isCTA
    }
  }
}

const CostModuleAdapter = ({ fields }: CostModuleAdapterProps) => {
  let props: CostModuleProps
  try {
    props = getProps(fields)
  } catch (e) {
    //@ts-ignore
    console.error(`Malformed CostModule template: ${e.message || e}`)
    return null
  }

  return <CostModule {...props} />
}

export default CostModuleAdapter