import { useEffect, useState } from 'react'

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(
    (typeof window !== 'undefined' && window.innerWidth) || 0
  )

  useEffect(() => {
    if (typeof window !== 'undefined' && window.document) {
      setScreenWidth(window.innerWidth)

      const handleResizeWindow = () => setScreenWidth(window.innerWidth)
      // subscribe to window resize event "onComponentDidMount"
      window.addEventListener('resize', handleResizeWindow)
      return () => {
        // unsubscribe "onComponentDestroy"
        window.removeEventListener('resize', handleResizeWindow)
      }
    }
  }, [])

  return { screenWidth }
}
