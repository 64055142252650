import React from 'react'
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react'

import {
  Item,
  ImageField,
  TextField,
  BooleanField,
} from '../../types/SitecoreAdapter'

import TabbedFeatureBase, { Heading } from './TabbedFeature'

import IsPageEditing from '../IsPageEditing'

type TabbedCardAdapterProps = {
  fields: {
    tabText: TextField
    heading: TextField
    body: TextField
    image: ImageField
    imageCaption: TextField
    buttonLinkText: TextField
    mobileButtonText: TextField
    buttonLinkHref: TextField
    buttonLinkTitle: TextField
    buttonType: TextField
    isModalCTA: BooleanField
  }
}

type TabbedFeatureAdapterFields = {
  heading: TextField
  cards: TabbedCardAdapterProps[]
}

export type TabbedFeatureAdapterProps = Item<TabbedFeatureAdapterFields>

const TabbedFeatureAdapter = ({ fields }: TabbedFeatureAdapterProps) => {
  const { heading, cards } = fields

  const cardsData = cards.map(card => {
    return {
      tabText: card.fields.tabText.value,
      heading: card.fields.heading.value,
      body: card.fields.body.value,
      image: (
        <img
          src={card.fields.image.value.src}
          alt={card.fields.image.value.alt}
          width={card.fields.image.value.width}
          height={card.fields.image.value.height}
          loading={'lazy'}
        />
      ),
      imageCaption: card.fields.imageCaption.value && (
        <RichText field={card.fields.imageCaption} />
      ),
      cta: {
        desktopText: card.fields.buttonLinkText.value,
        mobileText:
          (card.fields.mobileButtonText &&
            card.fields.mobileButtonText.value) ||
          card.fields.buttonLinkText.value,
        href: card.fields.buttonLinkHref.value,
        title: card.fields.buttonLinkTitle.value,
        type:
          card.fields.buttonType && card.fields.buttonType.value === 'Secondary'
            ? 'secondary'
            : 'primary',
        isCTA: card.fields.isModalCTA && card.fields.isModalCTA.value,
      },
    }
  })

  const TabbedFeatureMeta = () => {
    return (
      <div data-name="tabbedFeatureMeta" style={{ display: 'none' }}>
        {cards.map((card, index) => (
          <div
            tabIndex={index}
            key={index}
            data-name={card.fields.tabText.value}
          >
            <div className="tabHeading">{card.fields.heading.value}</div>
            <div className="tabBodyText">{card.fields.body.value}</div>
            {card.fields.buttonType &&
              card.fields.buttonType.value === 'Secondary' && (
                <>
                  <div className="tabButtonText">
                    {card.fields.buttonLinkText.value}
                  </div>
                  <div className="tabButtonLink">
                    {card.fields.buttonLinkHref.value}
                  </div>
                </>
              )}
            <div className="tabImageCaption">
              {card.fields.imageCaption.value || ''}
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <IsPageEditing>
      {(isEditing: boolean) => {
        if (isEditing) {
          return (
            <React.Fragment>
              <Heading heading={heading.value} />
              {cardsData.map((_, index) => (
                <TabbedFeatureBase
                  heading={''}
                  initialSelectedIndex={index}
                  cards={cardsData}
                  key={index}
                />
              ))}
            </React.Fragment>
          )
        }

        return (
          <>
            <TabbedFeatureBase heading={heading.value} cards={cardsData} />
            <TabbedFeatureMeta />
          </>
        )
      }}
    </IsPageEditing>
  )
}

export default TabbedFeatureAdapter
