import React from 'react'
import styles from './nav.module.scss'

export const SubmenuBackButton = React.memo(({ onClick }) => (
  <button onClick={onClick} className={styles.backButton} />
))

export const SubmenuLinkHeading = React.memo(({ title, href }) => (
  <a href={href} className={styles.header}>
    {title}
  </a>
))

export const SubmenuNavBar = React.memo(({ backButton, heading }) => (
  <div className={styles.nav}>
    {backButton}
    {heading}
  </div>
))
