import React, { useMemo } from 'react'
import TableBase from './Table'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { TextField } from '../../types/SitecoreAdapter'

const fieldsOfType = (fields: any, type: any) => {
  return Object.entries(fields) // force-break
    .filter(([name, value]) => name.startsWith(type))
    .sort(([firstName], [secondName]) => firstName.localeCompare(secondName))
    .map(([, value]) => <Text field={value as TextField} />)
}

const getTableData = (
  numColumns: any,
  numRows: any,
  columnHeadings: JSX.Element[],
  dataFields: JSX.Element[]
) => {
  const data = []
  for (let column = 0; column < numColumns; column++) {
    const rows = []

    for (let row = 0; row < numRows; row++) {
      rows.push(dataFields[column * numRows + row])
    }

    data.push({
      title: columnHeadings[column],
      rows: rows,
    })
  }
  return data
}

const Table = ({ fields }: any) => {
  const columnHeadings = fieldsOfType(fields, 'columnHeading')
  const rowHeadings = fieldsOfType(fields, 'rowHeading')
  const dataFields = fieldsOfType(fields, 'cellText')
  const {
    numColumns: { value: numColumns },
    numRows: { value: numRows },
  } = fields
  const data = useMemo(
    () => getTableData(numColumns, numRows, columnHeadings, dataFields),
    [numColumns, numRows, columnHeadings, dataFields]
  )

  return <TableBase rowHeadings={rowHeadings} data={data} />
}

export default Table
