import React from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

type PageEditingProps = {
  children: (pageType: string) => JSX.Element | null
}

const pageSet = new Set(['Article Page', 'Policy Page'])

// To check if the page uses copy/article typography
function PageTypography({ children }: PageEditingProps) {
  const {
    sitecoreContext: { route },
  } = useSitecoreContext()
  switch (route?.templateName) {
    case 'Policy Page':
      return children('policy')
    case 'Home Page':
      return children('home')
    case 'Article Page':
      return children('article')
    case 'About Page':
      return children('about')
    default:
      return children('copy')
  }
}

// there's a bug in the type definitions for this version of JSS that incorrect flags
// any props other than sitecoreContext to a component as an error
// @ts-ignore
export default PageTypography
