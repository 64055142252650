import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import TestimonialBase from './base'
import { TextField } from '../../types/SitecoreAdapter'

export type TestimonialAdapterProps = {
  id?: string
  fields: {
    body: TextField
    name: TextField
    customerSince: TextField
  }
}

const Testimonial = ( { id, fields }: TestimonialAdapterProps) => (
  <TestimonialBase
    id={id}
    key={id}
    body={<Text field={fields.body} />}
    name={<Text field={fields.name} />}
    customerSince={<Text field={fields.customerSince} />} />
);

export default Testimonial;
