import React from 'react'
import {
  Text,
  withSitecoreContext,
  ComponentConsumerProps,
} from '@sitecore-jss/sitecore-jss-react'
import {
  TextField,
  ImageField,
  BooleanField,
  HeadingTag,
  Item,
} from '../../types/SitecoreAdapter'
import MajorMinorHero from './MajorMinor-Hero'
import SearchAppStartContext from '../../quotes/experiences/search-app-start/context'
import SearchAppStart from '../../quotes/experiences/search-app-start/embeds/cta'
import ResponsiveImage, { ResponsiveImageProps } from '../Responsive-Image'
import styles from './styles.module.scss'
import { TrustPilotWidgetGlobalSettingsType } from '../../types/common'
import { get } from 'lodash-es'

export type HeroFields = {
  heading: TextField
  descriptor: TextField
  backgroundImage: ImageField
  isDark: BooleanField
} & ResponsiveImageProps

type ImageSizingProps = {
  applyDefaultImgSizing?: boolean
}

type MajorMinorHeroAdapterProps = ComponentConsumerProps & {
  fields: HeroFields
  headingTag?: HeadingTag
  sitecoreContext: {
    route: {
      fields: {
        appStartDomain: TextField
        appStartPath: TextField
        searchAppStart: Item<{}> | null
        tpWidgetGlobalSettings: TrustPilotWidgetGlobalSettingsType
      }
    }
  }
} & ImageSizingProps

export type CardFields = {
  fields: {
    topText: TextField
    image: ImageField
    text: TextField
    buttonLink: Item<{
      body: TextField
      href: TextField
    }>
    caption: TextField
  }
}

const MajorMinorHeroAdapter = ({
  fields,
  headingTag = 'h1',
  sitecoreContext: {
    route: {
      fields: {
        searchAppStart,
        tpWidgetGlobalSettings,
      },
    },
  },
  applyDefaultImgSizing,
}: MajorMinorHeroAdapterProps & ImageSizingProps) => {
  const {
    heading,
    descriptor,
    isDark,
    desktopImage,
    tabletImage,
    mobileImage,
  } = fields

  const searchAppStartSettings = searchAppStart
  const enableTrustPilotHeroWidget = get(
    tpWidgetGlobalSettings,
    'fields.enableTrustPilotHeroWidget.value'
  )
  const BodySection = () => {
    if (searchAppStartSettings) {
      return (
        <SearchAppStartContext>
          <SearchAppStart
            styleOverrides={{
              escapeLink: { root: styles.searchAppEscapeLink },
            }}
          />
        </SearchAppStartContext>
      )
    }

    return null;
  }

  return (
    <MajorMinorHero
      showTrustPilotWidget={enableTrustPilotHeroWidget}
      heading={<Text field={heading} tag={headingTag} />}
      descriptor={
        <div>
          <Text field={descriptor} />
        </div>
      }
      backgroundImage={
        <ResponsiveImage
          desktopImage={desktopImage}
          tabletImage={tabletImage}
          mobileImage={mobileImage}
          applyDefaultImgSizing={applyDefaultImgSizing}
        />
      }
      isDark={isDark.value}
    >
      <BodySection />
    </MajorMinorHero>
  )
}

export default withSitecoreContext()(MajorMinorHeroAdapter)
