import React from 'react'
import Container from './Container'
import Heading from './Heading'
import LinkSection from './LinkSection'

import styles from './styles.module.scss'

const Columns = React.memo(({ children }) => (
  <div className={styles.columns}>{renderChildren(children)}</div>
))

const MegaMenu = React.memo(({ section, closeHandler, isOpen }) => {
  const childElements = section.children && renderChildren(section.children)

  return (
    <Container isOpen={isOpen} onClose={closeHandler}>
      {childElements}
    </Container>
  )
})

function renderChildren(children) {
  if (!children) {
    return null
  }

  return children.map((child, index) => {
    if (child.type === 'Heading') {
      return <Heading {...child} key={index} />
    }

    if (child.type === 'LinkSection') {
      return <LinkSection {...child} key={index} />
    }

    if (child.type === 'Columns') {
      return <Columns {...child} key={index} />
    }

    return null
  })
}

export default MegaMenu
