import React, { ReactNode, memo } from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import styles from './styles.module.scss'

export type IconTabProps = {
  href: string
  title: string
  children: ReactNode
  icon?: JSX.Element
  isActive?: () => boolean
}

export function IconTab({ href, children, icon, title, isActive }: IconTabProps) {
  // if there's a callback for marking a tab active, use it
  // otherwise, read location exactly
  const activeProps = isActive ? { isActive: isActive } : { }

  return (
    <NavLink
      to={href}
      className={({ isActive }) => clsx(styles.tab, isActive && styles.active)}
      key={href}
      title={title}
      {...activeProps}
      end
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.label}>{children}</div>
    </NavLink>
  )
}

export default memo(IconTab)
