import React from 'react'
import RelatedPolicies, {
  RelatedPoliciesLinkFields,
} from '../../../components/RelatedPolicies'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { SitecoreContextType, TextField } from '../../../types/SitecoreAdapter'

type RelatedPoliciesAdapterProps = SitecoreContextType<{
  relatedPoliciesTitle: TextField
  relatedPolicies: RelatedPoliciesLinkFields[]
}>

const RelatedPoliciesAdapter = ({
  sitecoreContext: {
    route: {
      fields: { relatedPoliciesTitle, relatedPolicies },
    },
  },
}: RelatedPoliciesAdapterProps) => {
  return (
    <RelatedPolicies title={relatedPoliciesTitle} linkItems={relatedPolicies} />
  )
}

export default withSitecoreContext()(React.memo(RelatedPoliciesAdapter))
