import React, { ReactNode } from 'react'
import BlogCard from '../Blog-Card'
import BlogContain from '../Blog-Card/Contain'
import BlogCardList from '../Blog-Card/List'
import Media from '../Media'
import SeeMoreLink from './SeeMoreLink'

import styles from './styles.module.scss'
import { AuthorSetCardType } from '.'
import { FC } from '../../types/common'

type Props = {
  heading: string
  descriptor: any
  cards: AuthorSetCardType[]
  seeMore: { href: string; body: ReactNode }
}

const AuthorSetContent: FC<Props> = ({
  heading,
  descriptor,
  cards,
  seeMore,
}) => {
  const hasSeeMoreLink = seeMore && seeMore.href && seeMore.body

  const desktopSeeMore = hasSeeMoreLink && (
    <Media
      mobile={false}
      tablet={() => (
        <SeeMoreLink
          href={seeMore.href}
          body={seeMore.body}
          className={styles.desktopSeeMore}
        />
      )}
    />
  )

  const mobileSeeMore = hasSeeMoreLink && (
    <Media
      mobile={() => (
        <div className={styles.linkPosition}>
          <SeeMoreLink href={seeMore.href} body={seeMore.body} />
        </div>
      )}
      tablet={false}
      desktop={false}
      wide={false}
    />
  )

  const headingEl = heading && <h2 className={styles.title}>{heading}</h2>

  const descriptorEl = descriptor && (
    <div className={styles.descriptor}>{descriptor}</div>
  )

  return (
    <BlogContain testId={'AuthorSetContent'} block className="AuthorSetContent">
      <div className={styles.headingWrap}>
        {headingEl}
        {descriptorEl}
        {desktopSeeMore}
      </div>
      <BlogCardList testId={'AuthorSetContent-Cards'}>
        {cards &&
          cards.map((card) => (
            <BlogCard
              key={card.title}
              image={card.image && `${card.image}&mh=190&c=1&ch=190&cw=390`}
              imageAlt={card.imageAlt}
              title={card.title}
              href={card.href}
              tag={card.tag}
              horizontal={false}
            >
              {card.description}
            </BlogCard>
          ))}
      </BlogCardList>
      {mobileSeeMore}
    </BlogContain>
  )
}

export default AuthorSetContent
