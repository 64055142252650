import clsx from 'clsx'
import React, { FC } from 'react'

type Props = {
  className?: string
  style?: object
  onClick?: () => void
  image: string
  arrowPosition: string
}

const CarouselArrow: FC<Props> = ({
  className,
  style,
  onClick,
  image,
  arrowPosition,
}) => (
  <img
    src={image}
    style={{ ...style, width: '32px', height: '32px' }}
    onClick={onClick}
    className={clsx(className, arrowPosition)}
    alt=""
  />
)

export { CarouselArrow }
