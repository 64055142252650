import React, { useContext } from 'react'
import Media from '../../../../../components/Media'
import Mobile from './Mobile'
import Desktop from './Desktop'
import { AppStartSettingsContext } from '../../context'
import SearchAppStartContainer from '../../state-management/container'
import { Status } from '../../state-management/states'

export default function CTASearchAppStart() {
  const { cookieError } = useContext(AppStartSettingsContext)

  return (
    <SearchAppStartContainer>
      {({ appState, dispatch, loadOptions, callbacks }) => {
        // cookie check fails
        if (appState.status === Status.BrowserError) {
          return cookieError
        }

        return (
          <div data-test-context="SearchAppStart" data-embed="Hero">
            <Media
              mobile={() => (
                <Mobile
                  appState={appState}
                  dispatch={dispatch}
                  loadOptions={loadOptions}
                  callbacks={callbacks}
                />
              )}
              tablet={() => (
                <Desktop
                  appState={appState}
                  dispatch={dispatch}
                  loadOptions={loadOptions}
                  callbacks={callbacks}
                />
              )}
            />
          </div>
        )
      }}
    </SearchAppStartContainer>
  )
}
