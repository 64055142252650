import React from 'react'
import { Field, Text } from '@sitecore-jss/sitecore-jss-react'

import InlineCTA, { BaseCTAButtonProps, BaseLearnMoreProps } from './Inline-CTA'

import { ImageField, TextField } from '../../types/SitecoreAdapter'

export type InlineCTAProps = {
  fields: {
    heading: TextField
    ctaButtonText: TextField
    learnMoreText?: TextField
    learnMoreTitle?: TextField
    learnMoreHref?: TextField
    direction: Field<'ltr' | 'rtl'>
    ctaImage: ImageField
  }
}

const InlineCTAAdapter = ({
  fields: {
    heading,
    ctaButtonText,
    learnMoreText,
    learnMoreTitle,
    learnMoreHref,
    ctaImage,
    direction,
  },
}: InlineCTAProps) => {

  const ctaButtonProps: BaseCTAButtonProps = {
    text: ctaButtonText && ctaButtonText.value,
  }

  const newLearnMoreProps: BaseLearnMoreProps =
    learnMoreText && learnMoreText.value
      ? {
          href: (learnMoreHref && learnMoreHref.value) || '',
          body: learnMoreText && learnMoreText.value,
          title: (learnMoreTitle && learnMoreTitle.value) || '',
        }
      : null

  return (
    <InlineCTA
      heading={<Text field={heading} />}
      button={ctaButtonProps}
      learnMore={newLearnMoreProps}
      ctaImage={ctaImage}
      direction={direction}
    />
  )
}

export default InlineCTAAdapter
