import React, { ReactNode } from 'react'
import { withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react'
import CookieErrorCms from './components/CookieError/CookieErrorCms'
import * as D from '../../../types/SitecoreAdapter'
import { IOption } from './types'

type PreselectItem = {
  fields: {
    industry: null | D.Item<{ id: D.IValue<string>; name: D.IValue<string> }>
    profession: null | D.Item<{ id: D.IValue<string>; name: D.IValue<string> }>
    hideProfession: D.BooleanField
  }
}

export type SearchAppStartItem = {
  fields: {
    placeholder: D.TextField
    mobilePlaceholder: D.TextField
    searchingPlaceholder: D.TextField
    modalHeading: D.TextField
    noResultsHeader: D.TextField
    noResultsSuggestions: D.TextField
    buttonText: D.TextField
    escapeLinkText: D.TextField
    escapeLinkHref: D.TextField
    errorMessage: D.TextField
    confirmButtonLabel: D.TextField
    cookieErrorMessage: D.TextField
  }
}

type SettingsContextProps = D.SitecoreContextType<{
  searchAppStart: null | SearchAppStartItem
  preselect: null | PreselectItem
}> & {
  children?: ReactNode
  darkBg?: boolean
  quoteButtonText?: string
}

export type PreselectContext = {
  preselectIndustry: false | string
  preselectProfession: false | IOption
  preselectShowInput: boolean
}

type AppStartSettings = {
  darkBg: boolean
  placeholder: string
  mobilePlaceholder: string
  searchingPlaceholder: string
  modalHeading: JSX.Element
  noResultsHeader: string
  noResultsSuggestions?: string
  buttonText: string
  escapeLinkText: string
  escapeLinkHref: string
  errorMessage: string
  cookieError: JSX.Element
  confirmButtonLabel: string
  reset?: () => void
} & PreselectContext

let isReset = false

const emptyPreselect: PreselectContext = {
  preselectIndustry: false,
  preselectProfession: false,
  preselectShowInput: true,
}

function doReset() {
  isReset = true
}

const initialSettings: AppStartSettings = {
  darkBg: true,
  placeholder: '',
  mobilePlaceholder: '',
  searchingPlaceholder: '',
  modalHeading: <></>,
  noResultsHeader: '',
  buttonText: '',
  escapeLinkText: '',
  escapeLinkHref: '',
  errorMessage: '',
  cookieError: <></>,
  confirmButtonLabel: '',
  reset: doReset,
  ...emptyPreselect,
}

export const AppStartSettingsContext = React.createContext(initialSettings)

function SearchAppStartSettings({
  sitecoreContext,
  children,
  darkBg = true,
  quoteButtonText,
}: SettingsContextProps) {
  const { route } = sitecoreContext

  if (!route.fields.searchAppStart || !route.fields.searchAppStart.fields) {
    return null
  }

  const { fields } = route.fields.searchAppStart
  const placeholder = fields.placeholder.value
  const mobilePlaceholder = fields.mobilePlaceholder.value
  const searchingPlaceholder = fields.searchingPlaceholder.value
  const modalHeading = <RichText field={fields.modalHeading} />
  const noResultsHeader = fields.noResultsHeader.value
  const noResultsSuggestions = fields.noResultsSuggestions.value
  const buttonText = quoteButtonText || fields.buttonText.value
  const escapeLinkText = fields.escapeLinkText.value
  const escapeLinkHref = fields.escapeLinkHref.value
  const errorMessage = fields.errorMessage.value
  const cookieError = <CookieErrorCms darkBg={darkBg} />
  const confirmButtonLabel =
    fields.confirmButtonLabel && fields.confirmButtonLabel.value

  const settings: AppStartSettings = {
    darkBg,
    placeholder,
    mobilePlaceholder,
    searchingPlaceholder,
    modalHeading,
    noResultsHeader,
    noResultsSuggestions,
    buttonText,
    escapeLinkText,
    escapeLinkHref,
    errorMessage,
    cookieError,
    confirmButtonLabel,
    reset: doReset,
    ...getPreselect(route.fields.preselect, isReset),
  }

  return (
    <AppStartSettingsContext.Provider value={settings}>
      {children}
    </AppStartSettingsContext.Provider>
  )
}

export default withSitecoreContext()(SearchAppStartSettings)

function getPreselect(
  preselect: PreselectItem | null,
  isReset: boolean
): PreselectContext {
  if (!preselect || isReset) {
    isReset = false
    return emptyPreselect
  }

  const preselectIndustry = preselect.fields.industry
    ? preselect.fields.industry.fields.id.value
    : false

  const professionItem = preselect.fields.profession
  const preselectProfession = professionItem
    ? {
        value: professionItem.fields.id.value,
        label: professionItem.fields.name.value,
      }
    : false
  const hideProfession = preselect.fields.hideProfession.value

  const preselectShowInput: boolean = !hideProfession

  return {
    preselectIndustry,
    preselectProfession,
    preselectShowInput,
  }
}
