import React from 'react'
import SidebarSection from '../SidebarSection'
import styles from './styles.module.scss'

type ContactSidebarProps = {
  phone: string
  hours: string
  days: string
}

const ContactSidebar = ({phone, hours, days }: ContactSidebarProps) => {
  return(
    <SidebarSection title="Contact Us" className="Contact" testId="ContactSidebar">
      <div className={styles.text}>Call {phone}</div>
      <div className={styles.text}>
        {hours}, {days}
      </div>
    </SidebarSection>
  )
}

export default React.memo(ContactSidebar)
