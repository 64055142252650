import { AppStartSearchEvent, EventName, LabeledEvent } from './events'

const GoogleAnalyticsCustomEventTrigger = 'TrackEvent'

const GoogleAnalyticsCategory = 'AppStartSearch'

export function getEvent(event: EventName): AppStartSearchEvent {
  return <AppStartSearchEvent>{
    event: GoogleAnalyticsCustomEventTrigger,
    category: GoogleAnalyticsCategory,
    action: event,
    label: '',
  }
}

export function getEventWithValue(
  event: LabeledEvent,
  value: string
): AppStartSearchEvent {
  return {
    event: GoogleAnalyticsCustomEventTrigger,
    category: GoogleAnalyticsCategory,
    action: event,
    label: value,
  }
}
