import React from 'react'
import LinkWrapper from '../../components/LinkWrapper'
import { LinkWrapperProps } from '../../components/LinkWrapper/LinkTypes'
import styles from './styles.module.scss'

type LegalLinksProps = {
  legalLinks: LinkWrapperProps[]
}

const Separator = () => <span className={styles.linkSeparator}>|</span>

const LegalLinks = ({ legalLinks }: LegalLinksProps) => {
  const maxLen = legalLinks.length - 1

  return (
    <div className={styles.links}>
      {legalLinks.map(({ href, text, title, newWindow }, i) => {
        return (
          <React.Fragment key={i}>
            <LinkWrapper
              key={i}
              text={text}
              href={href}
              title={title}
              newWindow={newWindow}
              className={styles.legalLink}
            />
            {i < maxLen && <Separator />}
          </React.Fragment>
        )
      })}
    </div>
  )
}
export default React.memo(LegalLinks)
