import React, { useContext } from 'react'
import clsx from 'clsx'

import Button from '../Button'
import Video from '../Video/Video'
import { getIdFromUrl, getEmbedUrlFromId } from '../Video/services/getEmbedUrl'

import { ModalCTAContext } from '../../context/ModalCTAContext'

type MultiColumnVideoProps = {
  heading?: string
  headingSize?: string
  descriptor: React.ReactElement
  videoUrl: string
  ctaButtonText?: string
  isBottomDescriptor?: boolean
  useBodyText?: Boolean
  testId?: string
}

export type AlignmentOption = 'left' | 'right'

type StylingProps = {
  styles: { [key: string]: string }
}

type AlignmentProps = {
  alignment: AlignmentOption
}

const MultiColumnVideo = ({
  heading,
  headingSize,
  descriptor,
  videoUrl,
  ctaButtonText,
  alignment,
  isBottomDescriptor,
  useBodyText,
  styles,
  testId
}: MultiColumnVideoProps & StylingProps & AlignmentProps) => {
  const { showModal } = useContext(ModalCTAContext)
  const videoId = getIdFromUrl(videoUrl) || ''
  const videoEmbedUrl = videoId ? getEmbedUrlFromId(videoId) : ''
  const headingSizeClass = headingSize ? headingSize.toLowerCase() : 'h2'
  const descriptorStyleClass = useBodyText ? 'BodyText': styles.multiColDescriptor

  const descriptorCtaEl = (
    <div className={styles.descCtaComponent}>
      {descriptor && (
        <div className={descriptorStyleClass}>{descriptor}</div>
      )}
      {ctaButtonText && (
        <div className={styles.multiColCtaButtonComponent}>
          <Button
            className={clsx('button', styles.ctaButton)}
            type="primary"
            onClick={showModal}
          >
            {ctaButtonText}
          </Button>
        </div>
      )}
    </div>
  )

  const videoEl = (
    <div className={styles.multiColVideoComponent}>
      <Video id={videoId} embedUrl={videoEmbedUrl} lazyLoad={true} />
    </div>
  )

  return (
    <div data-test-id={testId} className={styles.multiColumnVideo}>
      {heading && headingSize === 'h3' ? (
        <h3 className={clsx(styles.header)}>
          {heading}
        </h3>
      ): (
        <h2 className={clsx(styles.header)}>
          {heading}
        </h2>
      )}
      <div className={styles.columns}>
        <div className={styles.leftColumn}>
          {alignment === 'right' ? descriptorCtaEl : videoEl}
        </div>
        <div className={styles.rightColumn}>
          {alignment === 'left' ? descriptorCtaEl : videoEl}
        </div>
      </div>
    </div>
  )
}

export default MultiColumnVideo
