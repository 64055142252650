import * as React from 'react'
import * as CMS from '../../types/SitecoreAdapter'
import { BreadcrumbLink } from './BreadcrumbList'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

type BreadcrumbRoute = CMS.SitecoreContextRouteFields<{
  breadcrumbs: BreadcrumbLink[]
}>

// Pull breadcrumbs from route information
function BreadcrumbsFromRoute({
  children,
}: {
  children: (br: BreadcrumbLink[]) => JSX.Element
}) {
  const {
    sitecoreContext: { route },
  } = useSitecoreContext()
  const {
    fields: { breadcrumbs },
  } = route as BreadcrumbRoute
  return children(breadcrumbs)
}

export default BreadcrumbsFromRoute
