import React, { ReactNode } from 'react'

import styles from './styles.module.scss'

type HeadingProps = {
  children?: ReactNode
}

const PolicyH1 = ({ children }: HeadingProps) => (
  <h1 className={styles.pageHeading}>{children}</h1>
)

export default PolicyH1
