import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

const SubmenuHeading = React.memo(({ heading }) => (
  <div className={clsx(styles.root, styles.sectionHeading)}>
    {heading}
  </div>
))

export default SubmenuHeading
