import { BooleanField, Item, TextField } from '../../../types/SitecoreAdapter'

export type LegalLinkType = {
  href: TextField
  text: TextField
  title: TextField
  newWindow: BooleanField
}

export type LegalFields = Item<{
  legalLinks: Item<LegalLinkType>[]
}>

export default function legalLinksAdapter({
  fields: { legalLinks },
}: LegalFields) {
  return legalLinks.map(({ fields: { href, text, title, newWindow } }) => {
    return {
      text: text && text.value,
      href: href && href.value,
      title: title && title.value,
      newWindow: newWindow && newWindow.value,
    }
  })
}
