import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import ResponsiveImage, {
  ResponsiveImageProps,
} from '../../../page-components/Responsive-Image'
import { TextField } from '../../../types/SitecoreAdapter'

import AboutUsHero from './AboutUs-Hero'

export type AboutUsHeroAdapterProps = {
  heading: TextField
  isDark: boolean
  showTrustPilotWidget?: boolean
} & ResponsiveImageProps

type ImageSizingProps = {
  applyDefaultImgSizing?: boolean
}

const AboutUsHeroAdaptor = ({
  heading,
  isDark,
  desktopImage,
  tabletImage,
  mobileImage,
  applyDefaultImgSizing,
  showTrustPilotWidget,
}: AboutUsHeroAdapterProps & ImageSizingProps) => {
  return (
    <AboutUsHero
      showTrustPilotWidget={showTrustPilotWidget}
      backgroundImage={
        <ResponsiveImage
          desktopImage={desktopImage}
          tabletImage={tabletImage}
          mobileImage={mobileImage}
          applyDefaultImgSizing={applyDefaultImgSizing}
        />
      }
      heading={<Text field={heading} />}
      isDark={isDark}
    />
  )
}

export default AboutUsHeroAdaptor
