import React, { FC } from 'react'
import clsx from 'clsx'

type Props = {
  heading: React.ReactNode
  descriptor: React.ReactNode
  testId?: string
}

const H2AndDescriptor: FC<Props> = ({ heading, descriptor, testId }) => {
  const headingClasses = 'block contain'

  return (
    <>
      <div data-test-id="H2AndDescriptor" className={clsx(headingClasses, 'H2AndDescriptor')}>
        <h2>{heading}</h2>
        <div className="descriptor">{descriptor}</div>
      </div>
    </>
  )
}
export default H2AndDescriptor
