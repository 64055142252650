import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import Button from '../../Button'
import ModalButton from './ModalButton'

type PolicyCardButtonProps = {
  getQuotesLabel: React.ReactElement
  learnMoreLabel: React.ReactElement
}

const PolicyCardButtons = ({
  getQuotesLabel,
  learnMoreLabel,
}: PolicyCardButtonProps) => {
  return (
    <div className={styles.buttonGroup}>
      <ModalButton getQuotesLabel={getQuotesLabel} />
      <div className={styles.learnButtonContainer}>
        <Button
          className={clsx('button', styles.learMoreButton)}
          type="secondary"
          size="slim"
        >
          {learnMoreLabel}
        </Button>
      </div>
    </div>
  )
}

export default React.memo(PolicyCardButtons)
