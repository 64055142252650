import React, { useContext } from 'react'
import clsx from 'clsx'
import Button from '../Button'
import { ModalCTAContext } from '../../context/ModalCTAContext'
import Media from '../Media'
import styles from './styles.module.scss'

export type CostModuleButtonProps = {
  href: string
  title: string
  mobileText: JSX.Element
  desktopText: JSX.Element
  type: string
  isCTA: boolean
}

type ModalButtonProps = {
  mobileText: JSX.Element
  desktopText: JSX.Element
  title: string
  type: string
}

const CostModuleButton = ({
  href,
  title,
  mobileText,
  desktopText,
  type,
  isCTA,
}: CostModuleButtonProps) => {
  return isCTA ? (
    <ModalButton
      mobileText={mobileText}
      desktopText={desktopText}
      title={title}
      type={type}
    />
  ) : (
    getDefaultButton(type, href, title, mobileText, desktopText)
  )
}

export default React.memo(CostModuleButton)

function ModalButton({
  mobileText,
  desktopText,
  title,
  type,
}: ModalButtonProps) {
  const { showModal } = useContext(ModalCTAContext)

  return (
    <Button
      testId="CostModuleButton"
      className={clsx('button', styles.costModuleButton, styles.link)}
      title={title}
      type={type}
      size="medium"
      onClick={showModal}
    >
      <Media mobile={() => mobileText} desktop={() => desktopText} />
    </Button>
  )
}

function getDefaultButton(
  type: string,
  href: string,
  title: string,
  mobileText: JSX.Element,
  desktopText: JSX.Element
) {
  return (
    <Button
      testId="CostModuleButton"
      className={clsx('button', styles.costModuleButton, styles.link)}
      type={type}
      size="medium"
      href={href}
      title={title}
    >
      <Media mobile={() => mobileText} desktop={() => desktopText} />
    </Button>
  )
}
