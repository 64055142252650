import React from 'react'
import clsx from 'clsx'
import BottomCTABase from './Bottom-CTA'
import Media from '../../components/Media'
import ModalCTA from '../../components/Modal-CTA/ModalCTA'
import {
  TextField,
  ImageField,
  LinkObjectField,
} from '../../types/SitecoreAdapter'

import styles from './styles.module.scss'

// This is the shape of the Sitecore Bottom CTA template
export type BottomCTAItem = {
  fields: {
    button: LinkObjectField
    image: ImageField
    escape: LinkObjectField
    descriptor: TextField
    heading: TextField
  }
}

const BottomCTAAdapter = ({ fields }: BottomCTAItem) => {
  if (!fields) {
    return null
  }

  const { button, image, escape, descriptor, heading } = fields

  const { body, href } = button.fields
  const { src, alt } = image.value

  const CustomButton = (
    <Media
      desktop={() => (
        <ModalCTA
          buttonClassName={clsx('button', styles.modalButton)}
          wrapClassName={styles.modalButtonWrap}
          alignment={'Center'}
          primary={true}
          size='xxxlarge'
        >
          {body.value}
        </ModalCTA>
      )}
      mobile={() => (
        <ModalCTA
          buttonClassName={clsx('button', styles.modalButton)}
          wrapClassName={styles.modalButtonWrap}
          alignment={'Center'}
          primary={true}
          size='large'
        >
          {body.value}
        </ModalCTA>
      )}
    />
  )

  const ImageComponent = <img src={src} alt={alt} loading={'lazy'} />

  return (
    <BottomCTABase
      heading={heading.value}
      descriptor={descriptor.value}
      escapeHref={escape.fields.href.value}
      escapeText={escape.fields.body.value}
      image={ImageComponent}
      children={CustomButton}
    />
  )
}

export default BottomCTAAdapter