import React from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

type PageEditingProps = {
  children: (isEditing: boolean) => JSX.Element | null
}

function IsEditing({ children }: PageEditingProps) {
  const {
    sitecoreContext: { pageEditing = false },
  } = useSitecoreContext()
  return children(pageEditing)
}

// there's a bug in the type definitions for this version of JSS that incorrect flags
// any props other than sitecoreContext to a component as an error
// @ts-ignore
export default IsEditing
