import React from 'react'
import clsx from 'clsx'
import {
  withSitecoreContext,
  Placeholder,
  Text,
  RichText,
} from '@sitecore-jss/sitecore-jss-react'

import styles from './styles.module.scss'

type LegalPageProps = {
  sitecoreContext: {
    route: any
  }
}

function LegalPage({ sitecoreContext: { route } }: LegalPageProps) {
  return (
    <>
      <div className="copy legal">
        <div className={clsx(styles.h1Descriptor, 'contain block')}>
          <h1 className={styles.pageHeading}>
            <Text field={route.fields.heading} />
          </h1>
          <div className="descriptor">
            <RichText field={route.fields.legalPageDescription} />
          </div>
        </div>
        <div>
          <Placeholder name="Legal-Placeholder" rendering={route} />
        </div>
      </div>
    </>
  )
}

export default withSitecoreContext()(LegalPage)
