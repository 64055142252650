import clsx from 'clsx'
import React from 'react'
import styles from './styles.module.scss'
import { TP_AdditionalProps } from '../../TrustPilotWidget'
import { get } from 'lodash-es'

// TP Widget Name: Horizontal TrustBox Widget
const FooterWidget = React.forwardRef(
  (props: TP_AdditionalProps, ref: React.Ref<HTMLDivElement>) => {
    const externalStyles = get(props, 'externalStyles')
    return (
      <div
        className={'TrustPilotFooterWidget'}
        data-test-id={'footer-tp-widget'}
      >
        <div
          className={clsx(styles.widgetContainer, externalStyles)}
          id="widgetContainer"
        >
          <div
            id={'trustBox'}
            ref={ref}
            className={styles.trustPilotWidget}
            data-locale="en-US"
            data-template-id="5406e65db0d04a09e042d5fc"
            data-businessunit-id="545aa5ea00006400057b5df9"
            data-style-height="28px"
            data-style-width="100%"
            data-theme={props.isDark ? 'dark' : 'light'}
          ></div>
        </div>
      </div>
    )
  }
)

export default FooterWidget
