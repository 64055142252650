import React from 'react'
import styles from './styles.module.scss'
import spaceStyles from '../../../../styles/space.module.scss'
import clsx from 'clsx'

type MainHeadingProps = {
  children: React.ReactNode
}

const MainHeading = ({ children }: MainHeadingProps) => (
  <h1 className={clsx(styles.mainHeading, spaceStyles.contain)}>
    {children}
  </h1>
)

export default React.memo(MainHeading)
