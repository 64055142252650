import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { WithSitecoreContextProps } from '@sitecore-jss/sitecore-jss-react/types/enhancers/withSitecoreContext'
import ModalCTA from './ModalCTA'
import { TextField } from '../../types/SitecoreAdapter'
import { Alignment, Size } from './components/CMS-Button/LayoutWrapper'

interface IModalButtonAdapter {
  fields: {
    title: TextField
    alignment: TextField
    type: TextField
    size: TextField
  }
}

interface IModalButtonFields {
  title: string
  alignment: Alignment
  type: string
  size: string
}

const Button = ({
  fields,
  sitecoreContext: { pageEditing = false },
}: IModalButtonAdapter & WithSitecoreContextProps) => {
  if (!fields || (!pageEditing && !fields.title.value)) {
    return null
  }

  const { title, alignment, type, size } = Object.keys(fields).reduce(
    (acc, k) => {
      if (!fields.hasOwnProperty(k)) {
        return acc
      }
      return { ...acc, [k]: fields[k as keyof IModalButtonFields].value }
    },
    {} as IModalButtonFields
  )

  // defaults to primary, so if type is blank or not secondary, use primary
  const primary = type !== 'Secondary'

  // defaults size to large
  const normalizedSize = getSize(size || 'Large')

  // defaults alignment to left
  const normalizedAlignment = alignment || 'Left'

  const normalizedTitle = getTitle(pageEditing, title)

  return (
    <ModalCTA
      primary={primary}
      size={normalizedSize}
      alignment={normalizedAlignment}
    >
      {normalizedTitle}
    </ModalCTA>
  )
}
const getTitle = (isEditing: boolean, title: string) => {
  return !title && isEditing ? 'Edit Button' : title
}

// map dropdown options to button size props
const getSize = (size: string) => {
  type SizeMap = { [key: string]: Size }

  const sizes: SizeMap = {
    Slim: 'slim',
    Medium: 'medium',
    Large: 'large',
    'Extra Large': 'xlarge',
  }

  return sizes[size as keyof SizeMap]
}

export default withSitecoreContext()(Button)
