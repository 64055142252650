import React, {FC} from 'react'
import styles from './styles.module.scss'

export type StateLinkType = {
  body: string
  href: string
  title: string
}

export const Link: FC<StateLinkType> = React.memo(({ href, title, body }) => (
  <a href={href} title={title} className={styles.link}>
    {body}
  </a>
))
