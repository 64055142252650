import React from 'react'
import { TableRow } from '../types'
import { BodyRows } from './BodyRows'
import SpacingContainer from '../SpacingContainer'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { fieldsOfType } from '..'
import { take } from 'lodash-es'
import { RichText } from '@sitecore-jss/sitecore-jss-react'

type DesktopTableProps = {
  data: TableRow[]
  boldRowHeadings?: boolean
  largerFirstColumn?: boolean
  numberOfColumns: number
}

export const DesktopTable = ({
  data,
  boldRowHeadings = true,
  largerFirstColumn = false,
  numberOfColumns = 3,
}: DesktopTableProps) => {
  const [headerRow, ...bodyRows] = data
  return (
    <SpacingContainer>
      <table
        className={clsx(
          styles.table,
          largerFirstColumn && styles.largerFirstColumn,
          numberOfColumns === 1 && styles.firstColumnOverride
        )}
      >
        <thead>
          <tr>
            <th>
              <RichText field={headerRow.fields.rowHeading_00} />
            </th>
            {getColumns(headerRow, numberOfColumns).map((col, index) => (
              <th key={`col_${index}`}>{col}</th>
            ))}
          </tr>
        </thead>
        <BodyRows
          numberOfColumns={numberOfColumns}
          bold={boldRowHeadings}
          rows={bodyRows}
        />
      </table>
    </SpacingContainer>
  )
}

export const getColumns = (row: TableRow, numberOfColumns: number) => {
  const columns = fieldsOfType(row.fields, 'column')
  const rowCells = take(columns, numberOfColumns)
  return rowCells
}

export default DesktopTable
