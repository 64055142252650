import React from 'react'
import loadable from '@loadable/component'

import BreadcrumbsFromRoute from '../../page-components/Breadcrumb/BreadcrumbsFromRoute'
import BreadcrumbList from '../../page-components/Breadcrumb/BreadcrumbList'

import ArticleHero from './ArticleHero'
import ArticlePlaceholder from './components/ArticlePlaceholder'
import RelatedPoliciesAdapter from './components/RelatedPoliciesAdapter'
import MainHeadingAdapter from './components/MainHeading'
import { Descriptor } from './components/Descriptor'
import LearnMoreAdapter from './components/LearnMore'
import { Topics } from './components/Topics'

import styles from './styles.module.scss'
import PlaceholderFactory from '../../page-components/PlaceholderFactory'
import { DateField } from '../../types/SitecoreAdapter'
import {
  RouteData,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'

const BottomCTA = loadable(
  () => import('../../page-components/Bottom-CTA/WithContext')
)
const SocialSharingSection = loadable(
  () => import('./components/SocialSharingSection')
)
const UpdatedDate = loadable(() => import('../../page-components/UpdatedDate'))

type ArticleRoute = {
  fields: {
    lastPublishedDate: DateField
    updatedDate: DateField
  }
} & RouteData

type ArticlePageProps = {
  sitecoreContext: {
    route: ArticleRoute
  }
}

function ArticlePage({ sitecoreContext: { route } }: ArticlePageProps) {
  return (
    <>
      <ArticleHero />
      <PlaceholderFactory name="Hero-Placeholder" />

      <div className="copy-article article">
        <BreadcrumbsFromRoute>
          {(breadcrumbs) => (
            <BreadcrumbList
              showWrap={true}
              breadcrumbs={breadcrumbs}
              isSticky={true}
              className={styles.breadcrumb}
              showCurrentPageCrumb={false}
            />
          )}
        </BreadcrumbsFromRoute>
        <article>
          <MainHeadingAdapter />
          <Descriptor />
          <ArticlePlaceholder />
          <UpdatedDate
            date={route.fields.updatedDate}
            fallbackDate={route.fields.lastPublishedDate}
          />
          <BottomCTA />
          <LearnMoreAdapter />
          <RelatedPoliciesAdapter />
          <Topics />
          <SocialSharingSection />
        </article>
      </div>
    </>
  )
}

export default withSitecoreContext()(ArticlePage)
