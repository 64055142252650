import React from 'react'
import styles from './styles.module.scss'
import spaceStyles from '../../../styles/space.module.scss'
import clsx from 'clsx'

type PageDescriptionProps = {
  body: React.ReactNode
}

export default React.memo(({ body }: PageDescriptionProps) => (
  <div className={clsx(styles.pageDescription, spaceStyles.contain, 'Descriptor')}>
    {body}
  </div>
))
