import { head } from 'lodash-es'

type Tracker = {
  get: (paramName: string) => string
}

type GA = {
  getAll(): Tracker[]
}

type GAOnReadyCallback = (callback: () => void) => void

declare global {
  interface Window {
    // ga on the window is either undefined, or accepts a callback which will
    // fire when ga is ready
    ga: undefined | GAOnReadyCallback | GA
  }
}

export default function() {
  return getInitializedGa()
    .then(getGaTracker)
    .then(getLinkerParam)
}

function getInitializedGa() : Promise<GA> {
  return new Promise((resolve, reject) => {
    if (typeof window === 'undefined') {
      reject('Google Analytics Linker: no window')
    }

    if (window['ga'] === undefined) {
      reject('Google Analytics Linker: no ga found on page')
    }

    // callback fires when google analytics is ready
    // this might be a reference to classic analytics
    if (typeof window['ga'] === 'function') {
      (window['ga'] as GAOnReadyCallback)(function() {
        resolve(window['ga'] as GA)
      })
    }
  })
}

function getGaTracker(ga: GA): Promise<Tracker> {
  return new Promise((resolve, reject) => {
    const tracker: Tracker | undefined = head(ga.getAll())

    if (tracker !== undefined) {
      resolve(tracker)
    }

    reject('Google Analytics Linker: no tracker found')
  })
}

function getLinkerParam(tracker: Tracker): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const linker = tracker.get('linkerParam')
      if (typeof linker === 'string') {
        resolve(linker)
      } else {
        reject(`Google Analytics Linker: linker param isn't string`)
      }
    } catch (err) {
      reject('Google Analytics Linker: unable to get linker param')
    }
  })
}

// test in browser with a mock by running these in the console before starting an app
// window.ga = function(cb) { return cb() }
// window.ga.getAll = function() { return [ { get: () => 'PARAM' } ]}
