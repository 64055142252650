import React, { useContext } from 'react'
import { ModalCTAContext } from '../context/ModalCTAContext'
import Modal from '../page-components/Modal'
import SearchAppStartContext from '../quotes/experiences/search-app-start/context'
import SearchAppStart from '../quotes/experiences/search-app-start/embeds/modal'

const AppStartModalContent = () => {
  const { isVisible, hideModal } = useContext(ModalCTAContext)

  if (!isVisible) {
    return null
  }

  return (
    <Modal onClose={hideModal} desktopSize="md">
      <SearchAppStartContext darkBg={false}>
        <SearchAppStart />
      </SearchAppStartContext>
    </Modal>
  )
}

export default React.memo(AppStartModalContent)
