import React, { ReactNode } from 'react'

import clsx from 'clsx'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

type SidebarSectionProps = {
  title: string
  children?: ReactNode
  className?: string
  bottomMargin?: string
  testId?: string
}

const SidebarSection = ({
  title,
  children,
  className,
  bottomMargin,
  testId
}: SidebarSectionProps) => (
  <section
    data-test-id={testId}
    className={clsx(
      styles.base,
      styles[`base-${bottomMargin}`],
      className,
      spaceStyles.contain,
      'SidebarSection'
    )}
  >
    {title && <div className={styles.title}>{title}</div>}
    <div>{children}</div>
  </section>
)

export default SidebarSection
