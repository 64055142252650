import React from 'react'
import styles from './../styles.module.scss'
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react'
import LinkWrapper from '../../LinkWrapper'
import { AdditionalPolicyItemType } from '../types'
import AccordionBase from '../../Accordion/base'
import { get } from 'lodash-es'

const Policy: React.FC<{ policy: AdditionalPolicyItemType }> = ({ policy }) => {
  const policyIcon = get(policy, 'fields.iconOverride'),
    policyTitle = get(policy, 'fields.titleOverride.value'),
    policyOverviewInfo = get(policy, 'fields.bodyOverride'),
    policyLinkHref = get(policy, 'fields.policyLinkHref.value'),
    policyLinkText = get(policy, 'fields.policyLinkText.value'),
    policyLinkTitle = get(policy, 'fields.policyLinkTitle.value')

  return (
    <AccordionBase
      styleOverrides={{
        contain: styles.policyAccordionWrap,
        body: styles.policyAccordionDetailsWrap,
        accordionItemTitle: styles.accordionItemTitle,
      }}
      heading={
        <div className={styles.policyAccrdSummaryWrap}>
          <div className={styles.policyIcon}>
            <Image field={policyIcon} />
          </div>
          <h3 className={styles.policyTitle}>{policyTitle}</h3>
        </div>
      }
    >
      <div className={styles.policyDetailsSectionWrap}>
        <div className={styles.policyOverviewInfo}>
          <RichText field={policyOverviewInfo} />
        </div>
        <div className={styles.viewMoreInfoWrap}>
          <LinkWrapper
            href={policyLinkHref}
            text={policyLinkText}
            title={policyLinkTitle}
          />
        </div>
      </div>
    </AccordionBase>
  )
}

export default Policy
