import React, { FC } from 'react'
import tabStyles from '../tab.module.scss'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { TabListTabType } from '../TabList'

type Props = Omit<TabListTabType, 'href'>

const TabButton: FC<Props>= ({ text, active, onTabSelect }) => {
  return (
    <button
      onClick={onTabSelect}
      className={clsx(tabStyles.tab, styles.tab, active && tabStyles.active)}
    >
      <span className={tabStyles.text}>{text}</span>
    </button>
  )
}

export default React.memo(TabButton)