import React, { ReactNode } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

const ALIGNMENTS = {
  Left: '',
  Center: styles.center,
  Right: styles.right,
}

export type Alignment = 'Left' | 'Center' | 'Right'
export type Size = 'slim' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge'

export interface ILayoutWrapperProps {
  alignment: Alignment
  size: Size
  wrapClassName?: string
}

type LayoutWrapperProps = {
  children?: ReactNode
} & ILayoutWrapperProps

const LayoutWrapper = ({
  alignment,
  wrapClassName,
  size,
  children,
}: LayoutWrapperProps) => {
  const alignmentClass = ALIGNMENTS[alignment]

  return (
    <div
      className={clsx(
        alignmentClass,
        styles[size],
        styles.root,
        wrapClassName
      )}
    >
      {children}
    </div>
  )
}

export default LayoutWrapper
