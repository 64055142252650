import React from 'react'
import ListItem from './ListItem'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { FooterColumnLinkProps } from './FooterColumnLink'

type ListProps = {
  items: FooterColumnLinkProps[]
  type: string
}

const List = ({ items, type }: ListProps) => {
  const isLink = type === 'link'
  const itemClass = isLink ? styles.item : styles.icon
  const listClass = clsx(styles.list, !isLink && styles.iconList)

  return (
    <ul className={listClass}>
      {items.map((item, i) => {
        if ((isLink && !item.href) || (!isLink && !item.image)) {
          console.warn(
            'Warning: A Footer Column Item is missing an image or href field'
          )
          return null
        }

        return <ListItem key={i} item={item} itemClass={itemClass} />
      })}
    </ul>
  )
}

export default React.memo(List)
