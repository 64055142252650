import React from 'react'
import loadable from '@loadable/component'
import {
  withSitecoreContext,
  Placeholder,
  RouteData,
} from '@sitecore-jss/sitecore-jss-react'

import MajorMinorHero, { HeroFields } from '../../page-components/MajorMinor-Hero'
import BreadcrumbsFromRoute from '../../page-components/Breadcrumb/BreadcrumbsFromRoute'
import BreadcrumbList from '../../page-components/Breadcrumb/BreadcrumbList'
import H2AndDescriptor from '../../components/H2AndDescriptor'
import styles from './styles.module.scss'

const BottomCTA = loadable(() => import('../../page-components/Bottom-CTA/WithContext'))

type Route = {
  fields: {
    hero: {
      fields: HeroFields
    }
    breadcrumbs: any
    heading: any
  }
} & RouteData

function MinorPage({
  sitecoreContext: { route },
}: {
  sitecoreContext: { route: Route }
}) {
  const heroProps = {...route.fields.hero, applyDefaultImgSizing: true}
  return (
    <>
      <MajorMinorHero {...heroProps} />
      <Placeholder name="Hero-Placeholder" rendering={route} />
      <div className="copy minor">
        <BreadcrumbsFromRoute>
          {(breadcrumbs) => 
            <BreadcrumbList
              showWrap={false}
              breadcrumbs={breadcrumbs}
              isSticky={false}
              className={styles.breadcrumb}
              paddedLeft={true}
            />}
        </BreadcrumbsFromRoute>
        {route.fields.heading.fields.heading.value.length > 0 && (
          <H2AndDescriptor {...route.fields.heading} />
        )}
        <div>
          <Placeholder name="Minor-Placeholder" rendering={route} />
        </div>
        <BottomCTA />
      </div>
    </>
  )
}

export default withSitecoreContext()(MinorPage)
