import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

type CurrentPageCrumbProps = {
  title: JSX.Element
}

export function CurrentPageCrumb({ title }: CurrentPageCrumbProps) {
  return (
    <span
      className={clsx(styles.crumb, styles.currentPageCrumb)}
      key="current"
    >
      {title}
    </span>
  )
}
