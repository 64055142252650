import React from 'react'
import FooterColumnLink, { FooterColumnLinkProps } from './FooterColumnLink'
import styles from './styles.module.scss'

type ListItemProps = {
  item: FooterColumnLinkProps
  itemClass: string
}

const ListItem = ({ item, itemClass }: ListItemProps) => {
  return (
    <li className={itemClass}>
      <FooterColumnLink
        image={item.image}
        className={styles.anchor}
        imageStyles={styles.iconImage}
        text={item.text}
        href={item.href}
        title={item.title}
        newWindow={item.newWindow}
      />
    </li>
  )
}

export default React.memo(ListItem)
