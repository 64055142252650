import React from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'

import MultiColumnBase from './MultiColumn'
import { BooleanField } from '../../types/SitecoreAdapter'

type MulticolumnFields = {
  mergeColumnsOnMobile: BooleanField
}

type MulticolumnProps = {
  fields?: MulticolumnFields
  rendering: any
  testId?: string
}

const MultiColumn = (props: MulticolumnProps) => {
  return (
    <MultiColumnBase
      data-test-id={props.testId}
      left={<Placeholder name="MultiColumn-Left-Placeholder" rendering={props.rendering} />}
      right={<Placeholder name="MultiColumn-Right-Placeholder" rendering={props.rendering} />}
      mergeColumnsOnMobile={
        (props.fields &&
          props.fields.mergeColumnsOnMobile &&
          props.fields.mergeColumnsOnMobile.value) ||
        false
      }
    />
  )
}


export default MultiColumn
