import React from 'react'
import { chunk } from 'lodash-es'
import clsx from 'clsx'
import LinkWrapper from '../../../../components/LinkWrapper'
import styles from './styles.module.scss'

const LinkSection = React.memo(
  ({ title, links, linksPerColumn = 4, width }) => {
    // handle link columns
    const linkSections = chunk(links, linksPerColumn).map((linkList, index) => {
      return (
        <div className={styles.linkSectionColumn} key={index}>
          {linkList.map((link, index) => (
            <LinkWrapper
              key={index}
              href={link.href}
              text={link.text}
              title={link.title}
              className={clsx(styles.link, link.isSummaryLink && styles.moreLink)}
              data-tag="tig-meganav"
            />
          ))}
        </div>
      )
    })

    const titleEl = title && (
      <div className={styles.linkSectionTitle}>{title}</div>
    )

    const columnsEl = (
      <div className={styles.linkSectionColumnContainer}>{linkSections}</div>
    )

    return (
      <div
        className={clsx(
          styles.linkSection,
          width === 'med' && styles.colMed,
          width === 'double' && styles.colDouble,
          width === 'full' && styles.colFull
        )}
      >
        {titleEl}
        {columnsEl}
      </div>
    )
  }
)

export default LinkSection
