import React from 'react'
import Slider from 'react-slick'
// required by React Slick
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useLocation, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import carouselStyles from './styles.module.scss'
// override slick styles, global selectors are not namespaced to a component
import './styles.scss'
import nextImage from './next.svg'
import prevImage from './prev.svg'
import { TabListProps, TabListTabType } from '../TabList'
import { CarouselArrow } from './Arrow'
import { Tab } from './Tab'

type MobileTabsProps = TabListProps

const MobileTabs = ({ tabs, className }: MobileTabsProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  return React.createElement(
    Slider,
    {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      centerMode: true,
      prevArrow: (
        <CarouselArrow image={prevImage} arrowPosition={carouselStyles.prev} />
      ),
      nextArrow: (
        <CarouselArrow image={nextImage} arrowPosition={carouselStyles.next} />
      ),
      centerPadding: '0',
      className: clsx(carouselStyles.mobileWrap, className),
      initialSlide: findInitialSlide(tabs, location.pathname),
      afterChange: (newIndex) => {
        if (tabs[newIndex].onTabSelect) {
          tabs[newIndex].onTabSelect!()
        }

        // use React Router history to change the location
        // of the current page
        // This will not cause a full page rerender, it will
        // only update the parts that should be updated
        // which is pretty cool :-)
        const nextTab = tabs[newIndex]
        if (nextTab.href) {
          navigate(nextTab.href)
        }
      },
    },
    tabs.map(Tab)
  )
}

export default MobileTabs

// return index of the current location
function findInitialSlide(tabs: TabListTabType[], pathname: string) {
  // look for a tab that matches our current location
  const foundIndex = tabs.findIndex(({ href }) => href === pathname)
  // if we don't find a matching tab, show the first tab in carousel
  return foundIndex === -1 ? 0 : foundIndex
}
