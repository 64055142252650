import React, { useContext } from 'react'
import { Status } from '../../state-management/states'
import {
  SelectFocus,
  SelectCTA,
  ModalClose,
} from '../../state-management/events'
import {
  triggerInputClick,
  triggerButtonClick,
} from '../../analytics/search-app-analytics'
import SelectInert from '../../components/Select/SelectInert'
import Modal from '../../../../../page-components/Modal'
import { AppStartSettingsContext } from '../../context'
import styles from './styles.module.scss'
import { SearchAppStartEmbedProps } from '../../state-management/container'
import Button from '../../../../../components/Button'
import TypeaheadContent from '../../components/TypeaheadContent'
import LeadRoutingNumberAttribution from '../../../../../components/Lead-Routing-Number-Attribution'

export default function MobileHeroSearchAppStart({
  loadOptions,
  appState,
  dispatch,
  callbacks: { onClose, onInput },
}: SearchAppStartEmbedProps) {
  const {
    buttonText,
    mobilePlaceholder,
    modalHeading,
    preselectShowInput,
    confirmButtonLabel,
  } = useContext(AppStartSettingsContext)

  if (appState.status === Status.Inert) {
    return (
      <div className={styles.control}>
        {preselectShowInput && (
          <SelectInert
            value={appState.selection}
            placeholder={mobilePlaceholder}
            onFocus={() => {
              triggerInputClick()
              dispatch(SelectFocus)
            }}
          />
        )}

        <Button
          className={styles.mobileQuoteButton}
          onClick={() => {
            triggerButtonClick()
            dispatch(SelectCTA)
          }}
          testId="QuoteButton"
          size="xlarge"
        >
          {buttonText}
        </Button>
        <div className={styles.routingAttr}>
          <LeadRoutingNumberAttribution variant={'cta'} />
        </div>
      </div>
    )
  }

  if (appState.status === Status.AppStart && appState.direct === true) {
    return (
      <div className={styles.control}>
        {preselectShowInput && (
          <SelectInert
            placeholder={mobilePlaceholder}
            value={appState.selection}
            isDisabled={true}
          />
        )}

        <Button
          className={styles.mobileQuoteButton}
          loading
          disabled
          testId="QuoteButton"
          size="xlarge"
        >
          {buttonText}
        </Button>
      </div>
    )
  }

  const handleClose = () => {
    onClose && onClose()
    dispatch(ModalClose)
  }
  return (
    <Modal modalHeading={modalHeading} onClose={handleClose} desktopSize="md">
      <TypeaheadContent
        appState={appState}
        confirmButtonLabel={confirmButtonLabel}
        dispatch={dispatch}
        loadOptions={loadOptions}
        onInput={onInput}
        placeholder={mobilePlaceholder}
      />
    </Modal>
  )
}
