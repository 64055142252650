import React from 'react'
import { Image, Text } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import Media from '../../components/Media'
import DesktopCarrierBand from './Desktop'
import Slideshow from '../Carrier-Band-Slides/Slideshow'
import * as SC from '../../types/SitecoreAdapter'
import { ImageFieldValue } from '@sitecore-jss/sitecore-jss-react/types/components/Image'

import styles from './styles.module.scss'

type SlideAdapter = {
  url: string
  alt: SC.TextField
  width: SC.TextField
  height: SC.TextField
}

// this data is the result of an integrated graphql query
// that means, the graphql query runs on the server, so data is fetched in time for SSR
// the reason we used graphql was to get the url of the images in a list
// out of the box, a treelist of image items didn't provide src urls
// we told Sitecore that this amount of ceremony to get src urls for a list of images is a bit silly, and
// they have taken that into consideration

type CarrierBandAdapterProps = {
  fields: {
    data: {
      datasource: {
        title: {
          jss: {
            value: string
          }
        }
        desktopImage: {
          alt: string
          height: null | string
          width: null | string
          src: null | string
        }
        tabletSlides: {
          targetItems: SlideAdapter[]
        }
        mobileSlides: {
          targetItems: SlideAdapter[]
        }
        mobileDuration: {
          value: string
        }
        tabletDuration: {
          value: string
        }
      }
    }
  }
  testId?: string
}

const CarrierBand = ({ fields: { data }, testId }: CarrierBandAdapterProps) => {
  if (!data || !data.datasource) return null

  const {
    datasource: {
      title,
      desktopImage,
      tabletSlides,
      mobileSlides,
      mobileDuration,
      tabletDuration,
    },
  } = data

  const intro = title.jss.value ? (
    <Text field={title.jss} className={styles.intro} tag="div" />
  ) : null

  return (
    <div data-test-id="CarrierBand" className={clsx(styles.wrap, 'CarrierBand')}>
      {intro}
      <Media
        desktop={() => (
          <DesktopCarrierBand
            key="desktop-carrier-band"
            image={<Image field={{ value: desktopImage }} />}
          />
        )}
        tablet={() => (
          <Slideshow
            key="tablet-carrier-band"
            slides={getSlides(tabletSlides)}
            height={80}
            width={750}
            className={styles.center}
            durationSeconds={getDuration(tabletDuration, 4)}
          />
        )}
        mobile={() => (
          <Slideshow
            key="mobile-carrier-band"
            slides={getSlides(mobileSlides)}
            height={60}
            width={300}
            className={styles.center}
            durationSeconds={getDuration(mobileDuration, 2)}
          />
        )}
      />
    </div>
  )
}

export function getDuration(
  { value }: { value: string },
  defaultSeconds: number
): number {
  if (value === '') return defaultSeconds
  const parsedSeconds = parseInt(value, 10)
  return isNaN(parsedSeconds) ? defaultSeconds : parsedSeconds
}

function getSlides({
  targetItems,
}: {
  targetItems: SlideAdapter[]
}): JSX.Element[] {
  return targetItems.map(slide => {
    // the graphql on the server gets close to the right shape, but not quite there
    // so we do some adjustments here, to make it closer to a regular Image
    const imageProps: ImageFieldValue = {
      src: slide.url,
      alt: slide.alt.value,
      height: slide.height.value,
      width: slide.width.value
    }

    return <Image key={imageProps.src} field={{ value: imageProps }} />
  })
}

export default CarrierBand
