import React from 'react'
import styles from './styles.module.scss'

const ListWrapper = ({ children }: any) => {
  return (
    <div className={styles.list}>
      {children}
    </div>
  )
}

export default React.memo(ListWrapper)
