import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { ComponentConsumerProps } from '@sitecore-jss/sitecore-jss-react/types/enhancers/withSitecoreContext'
import MajorMinorHero, {
  HeroFields,
} from '../../page-components/MajorMinor-Hero'

export default withSitecoreContext()(ArticleHero)

type Props = ComponentConsumerProps & {
  sitecoreContext: {
    route: {
      fields: { hero: { fields: HeroFields } }
    }
  }
}

function ArticleHero({
  sitecoreContext: {
    route: {
      fields: { hero },
    },
  },
}: Props) {
  if (hero) {
    const heroProps = { ...hero, applyDefaultImgSizing: true }
    return <MajorMinorHero {...heroProps} headingTag="div" />
  }

  return null
}
