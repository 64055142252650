import React from 'react'

import { Text, Placeholder, Image } from '@sitecore-jss/sitecore-jss-react'
import {
  TextField,
  BooleanField,
  ImageField,
} from '../../types/SitecoreAdapter'

import AccordionBase from './base'
import styles from './accordion-styles.module.scss'
import IsPageEditing from '../IsPageEditing'
import { isEmpty } from 'lodash-es'

type AccordionProps = {
  startsOpen: BooleanField
  heading: TextField
  icon?: ImageField
}

type AccordionAdapterProps = {
  fields: AccordionProps
  rendering: any
}

const Accordion = ({
  fields: { startsOpen, heading, icon },
  rendering,
}: AccordionAdapterProps) => {
  const hasIcon = !isEmpty(icon?.value)
  const styleOverrides = hasIcon ? { body: styles.bodyWithIcon } : undefined

  const head = !hasIcon ? (
    <Text field={heading} tag="h3" className={styles.heading} />
  ) : (
    <div data-test-id="AccordionSummary" className={styles.headingWrap}>
      <Image field={icon} className={styles.icon} />
      <Text field={heading} tag="h3" className={styles.headingWithIcon} />
    </div>
  )

  const body = <Placeholder name="Accordion-Body" rendering={rendering} />

  return (
    <IsPageEditing>
      {(isEditing: boolean) => {
        if (isEditing) {
          return (
            <div>
              {head}
              {body}
            </div>
          )
        }

        return (
          <AccordionBase
            heading={head}
            open={startsOpen.value}
            styleOverrides={styleOverrides}
          >
            {body}
          </AccordionBase>
        )
      }}
    </IsPageEditing>
  )
}

export default Accordion
