import React, { FC } from 'react'
import clsx from 'clsx'
import { chunk } from 'lodash-es'
import styles from './styles.module.scss'

type Props = {
  columnCount: number
  children: Array<any>
}

const Columns = React.memo(({ columnCount, children }: Props) => {
  if (columnCount === 1) {
    return <>{children}</>
  }

  return (
    <>
      {chunk(children, columnCount).map((row, index) => (
        <div className={clsx(styles.row)} key={index}>
          {row}
        </div>
      ))}
    </>
  )
})

export default Columns
