import React, { useState, useRef, useEffect, FC, MouseEvent } from 'react'
import Button from '../Button'
import { Link } from './Link'

import styles from './styles.module.scss'
import clsx from 'clsx'
import { StateModuleProps } from '.'

/*
 * @param {component} heading - visible header of this component
 * @param {string} description
 * @param {string} buttonText
 * @param {[]object} options The options to use within the state selection
 * dropdown
 */

type Props = Omit<StateModuleProps['fields'], 'image' | 'imageAlt'>

export const StateSelection: FC<Props> = React.memo(
  ({ heading, headingSize, descriptor, buttonLabel, options }) => {
    const descriptionComponent = (
      <div className={styles.description}>{descriptor}</div>
    )

    const headingSizeClass = headingSize ? headingSize.toLowerCase() : 'h2'

    let headingComponent

    switch (headingSize) {
      case 'H2': {
        headingComponent = (
          <h2 className={clsx(headingSizeClass, styles.stateHeading)}>
            {heading}
          </h2>
        )
        break
      }
      case 'H3': {
        headingComponent = (
          <h3 className={clsx(headingSizeClass, styles.stateHeading)}>
            {heading}
          </h3>
        )
        break
      }
      default: {
        headingComponent = (
          <h3 className={clsx(headingSizeClass, styles.stateHeading)}>
            {heading}
          </h3>
        )
        break
      }
    }

    return (
      <div data-test-id="StateModule" className={clsx(styles.wrap, 'block')}>
        {headingComponent}
        {descriptionComponent}
        <Selection button={buttonLabel} options={options} />
      </div>
    )
  }
)

const Selection = (props: {
  button: string
  options: StateModuleProps['fields']['options']
}) => {
  /**
   * Constants
   */
  const { button, options } = props
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  /**
   * Functions
   */
  const toggle = () => setIsOpen(isOpen => !isOpen)
  const close = () => setIsOpen(false)

  /**
   * Effects
   */
  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        close()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [toggle])

  const List = options.map((option, index) => (
    <li key={index} className={styles.list}>
      <Link href={option.href} title={option.title} body={option.body} />
    </li>
  ))

  const showStyle = isOpen ? { display: 'block' } : { display: 'none' }

  return (
    <div ref={ref} className={styles.selectionRef}>
      <Button testId="StateModule-Button" download onClick={toggle} className={styles.button}>
        <span className={styles.arrow}>{button}</span>
      </Button>
      <ul style={showStyle} className={styles.listWrap}>
        {List}
      </ul>
    </div>
  )
}
