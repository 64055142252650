import React, { ReactElement, ReactNode } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

type H3IconProps = {
  heading: ReactElement
  icon: ReactElement
  body: ReactNode
}

const H3andIcon = ({ heading, icon, body }: H3IconProps) => {
  return (
    <div
      data-test-id="H3WithIcon"
      className={clsx(
        spaceStyles.contain,
        'block',
        styles.wrap,
        'H3Icon'
      )}
    >
      <div className={styles.icon}>
        {React.createElement(icon.type, {
          ...icon.props,
          width: '100%',
          height: '100%',
        })}
      </div>
      <div className={styles.right}>
        {React.createElement(heading.type, {
          ...heading.props,
          className: styles.heading,
        })}
        <div className={clsx(styles.body, 'Subsection', 'list-style')}>
          {body}
        </div>
      </div>
    </div>
  )
}

export default H3andIcon
