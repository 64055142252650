import { ImageLink } from '../types'

export function resolveLogo(logo: ImageLink) {
  // Fallback to default shape
  if (!logo) {
    console.warn('Missing logo from navigation tree')
    return { image: { src: '' }, href: '', title: '' }
  }

  const { src, alt, width, height, href, title } = logo
  
  return {
    image: { src, alt, width, height },
    href,
    title
  }
}
