import React from 'react'
import { ComponentConsumerProps, Text } from '@sitecore-jss/sitecore-jss-react'
import Media from '../Media'
import Desktop from './Desktop'
import Tablet from './Tablet'
import Mobile from './Mobile'
import Edit from './Edit'
import styles from './styles.module.scss'
import {
  BooleanField,
  ImageField,
  LinkField,
  TextField,
} from '../../types/SitecoreAdapter'

import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

//slick slider for the customer testimonial component
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { TestimonialAdapterProps } from '../Testimonial'
import TrustPilotWidget from '../TrustPilot-Widget/TrustPilotWidget'
import clsx from 'clsx'
import { TrustPilotWidgetGlobalSettingsType } from '../../types/common'
import { get, isBoolean } from 'lodash-es'

type CustomerTestimonialAdapterProps = ComponentConsumerProps & {
  fields: {
    heading: TextField
    link: LinkField
    mobileImage: ImageField
    desktopImage: ImageField
    testimonials: TestimonialAdapterProps[]
    useTrustpilotWidget: BooleanField // (if True) Renders Trustpilot widget, else default Delighted Reviews (if False)
    widgetType?: TextField // Type of TrustPilot widget (i.e. Grid,Carousel, etc.)
    sku?: TextField
    productName?: TextField
  }
  sitecoreContext: {
    pageEditing: boolean
    route: {
      fields: { tpWidgetGlobalSettings: TrustPilotWidgetGlobalSettingsType }
    }
  }
  testId?: string
}

const CustomerTestimonial = ({
  fields: {
    heading,
    link,
    mobileImage,
    desktopImage,
    testimonials,
    useTrustpilotWidget = { value: false },
    widgetType = { value: 'Carousel' },
    sku,
    productName,
  },
  sitecoreContext: {
    pageEditing,
    route: {
      fields: { tpWidgetGlobalSettings },
    },
  },
  testId,
}: CustomerTestimonialAdapterProps) => {
  const headingEl = (
    <div className={styles.heading}>
      <Text field={heading} tag="div" className="h2" />
    </div>
  )

  const replaceCustomerTestimonial = get(
    tpWidgetGlobalSettings,
    'fields.replaceCustomerTestimonial.value'
  )

  const showTPWidget = isBoolean(useTrustpilotWidget.value)
    ? useTrustpilotWidget.value
    : replaceCustomerTestimonial

  if (pageEditing) {
    return (
      <div data-test-id="CustomerTestimonial" className="block">
        <div className={styles.wrap}>
          <TrustPilotWidget
            heading={heading}
            type={widgetType.value}
            sku={(sku && sku.value) || ''}
            productName={(productName && productName.value) || ''}
            externalStyles={styles.wrap}
          />
        </div>
      </div>
    )
  }

  const imageEl = (imageField: ImageField) => {
    const image = (
      <img
        src={imageField.value.src}
        alt={imageField.value.alt}
        width={imageField.value.width}
        height={imageField.value.height}
        title={imageField.value.title}
        loading={'lazy'}
      />
    )

    if (link && link.value && link.value.href) {
      return <a href={link.value.href}>{image}</a>
    }

    return image
  }

  const desktopImageEl = imageEl(desktopImage)
  const mobileImageEl = imageEl(mobileImage)
  const props = {
    useTrustpilotWidget: showTPWidget,
    widgetType: widgetType.value,
    testimonials: testimonials,
  }

  return (
    <div
      data-test-id="CustomerTestimonial"
      className={clsx(
        'block CustomerTestimonial',
        showTPWidget && styles.customerTestimonialTPWrap
      )}
    >
      {showTPWidget ? (
        <TrustPilotWidget
          heading={heading}
          type={widgetType.value}
          sku={(sku && sku.value) || ''}
          productName={(productName && productName.value) || ''}
          externalStyles={styles.wrap}
        />
      ) : (
        <>
          {headingEl}
          <Media
            mobile={() => <Mobile {...props} imageEl={mobileImageEl} />}
            tablet={() => <Tablet {...props} imageEl={desktopImageEl} />}
            desktop={() => <Desktop {...props} imageEl={desktopImageEl} />}
          />
        </>
      )}
    </div>
  )
}

export default withSitecoreContext()(CustomerTestimonial)
