import React, { memo } from 'react'
import IconTabList from './IconTabList'
import IconTab from './IconTab'
import { TextField, ImageField, Item } from '../../types/SitecoreAdapter'

export type SitecoreTab = Item<{
  href: TextField
  body: TextField
  icon: ImageField
  title: TextField
}>

type IconTabsProps = {
  tabs: SitecoreTab[]
  className?: string
}

export function IconTabs({ tabs, className }: IconTabsProps) {
  if (!tabs || tabs.length === 0) {
    return null
  }

  return <IconTabList className={className}>{tabs.map(adaptTab)}</IconTabList>
}

function adaptTab({ fields: { icon, href, body, title } }: SitecoreTab) {
  const iconProps = icon.value && icon.value.src
    ? { icon: <img src={icon.value.src} alt={icon.value.alt} /> }
    : {}

  return (
    <IconTab
      key={href.value}
      href={href.value}
      title={title.value}
      {...iconProps}
    >
      {body.value}
    </IconTab>
  )
}

export default memo(IconTabs)
