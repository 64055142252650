import { reduce, concat } from 'lodash-es'
import React from 'react'
import Submenu from './Submenu'
import SubmenuContent from './SubmenuContent'
import {
  SubmenuNavBar,
  SubmenuBackButton,
  SubmenuLinkHeading,
} from './SubmenuNavBar'

const AllSubmenus = React.memo(({ specs, activeSection, clearSection }) => {
    return specs.map(
      section =>
        // a section has a submenu if there are children
        // so, if children, then render the submenu
        section.children && (
          <Submenu isOpen={section === activeSection} key={section.text}>
            <SubmenuNavBar
              backButton={
                <SubmenuBackButton onClick={clearSection} />
              }
              heading={
                <SubmenuLinkHeading title={section.text} href={section.href} />
              }
            />
            {/* columns are a desktop specific feature, so we remove them before building the submenu */}
            <SubmenuContent specs={removeColumns(section.children)} />
          </Submenu>
        )
    )
})

export default AllSubmenus


// there are column elements in the spec for desktop
// they are not relevant on mobile, so we remove them
// ideally, they'd not make it to this level of the system
function removeColumns(children) {
  return reduce(
    children,
    function(specs, child) {
      if (child.type === 'Columns') {
        return concat(specs, child.children)
      }

      specs.push(child)
      return specs
    },
    []
  )
}