import React from 'react'
import BottomCTAAdapter, { BottomCTAItem } from './index'
import {
  ComponentConsumerProps,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'

type IBottomCTAContext = ComponentConsumerProps & {
  sitecoreContext: {
    route: {
      fields: {
        bottomCTA: BottomCTAItem
      }
    }
  }
}

export const BottomCTA = withSitecoreContext()(function ({
  sitecoreContext: {
    route: {
      fields: { bottomCTA },
    },
  },
}: IBottomCTAContext) {
  if (!bottomCTA) {
    return null
  }

  return <BottomCTAAdapter fields={bottomCTA.fields} />
})

export default BottomCTA
