import { LinkObject, LinkObjectField } from '../types/SitecoreAdapter'

export default function getLinkValues(link: LinkObjectField): LinkObject {
  return (
    link && {
      href: link.fields.href.value,
      title: link.fields.title.value,
      body: link.fields.body.value,
    }
  )
}
