import React from 'react'
import SubmenuHeading from '../MenuOption/SubmenuHeading'
import SubmenuLink from '../MenuOption/SubmenuLink'

const SubmenuLinkSection = React.memo(({ title, links }) => {
  const titleEl = title && <SubmenuHeading heading={title} />
  const linkEls = links.map(link => {
    const { text, title, href, isSummaryLink } = link
    const className = isSummaryLink && 'moreLink'
    return (
      <SubmenuLink text={text} title={title} href={href} className={className} key={href} />
    )
  })

  return (
    <React.Fragment key={title}>
      {titleEl}
      {linkEls}
    </React.Fragment>
  )
})

export default SubmenuLinkSection
