import React, { FC } from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import spaceStyles from '../../styles/space.module.scss'
import Media from '../Media'
import { TextField } from '../../types/SitecoreAdapter'

type Props = {
  fields: {
    title: TextField
    mobileTitle: TextField
    tag: { fields: { tag: TextField } }
    descriptor: TextField
  }
  addStyle: {
    root: string
    descriptor: string
  }
  embed: boolean
  testId?: string
}

const Heading: FC<Props> = ({
  fields: { title, mobileTitle, tag, descriptor },
  addStyle,
  embed = false,
  testId
}) => {
  const headingTag = tag.fields.tag.value
  const mobileHeading = mobileTitle && mobileTitle.value ? mobileTitle : title

  return (
    <div
      data-test-id={testId}
      className={clsx(
        addStyle.root,
        !embed && 'block',
        'Heading',
        !embed && spaceStyles.contain
      )}
    >
      <Media
        desktop={() => <Text field={title} tag={headingTag} />}
        mobile={() => <Text field={mobileHeading} tag={headingTag} />}
      />
      <RichText
        field={descriptor}
        className={clsx('descriptor', addStyle.descriptor)}
      />
    </div>
  )
}

export default Heading
