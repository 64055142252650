import React from 'react'
import {
  ComponentConsumerProps,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import ContactSidebar from './ContactSidebar'
import { TextField } from '../../types/SitecoreAdapter'

type ContactSidebarAdapterProps = ComponentConsumerProps & {
  sitecoreContext: {
    route: {
      fields: {
        contact: {
          fields: {
            phone: TextField
            hours: TextField
            days: TextField
          }
        }
      }
    }
  }
}

const ContactSidebarAdapter = ({
  sitecoreContext: {
    route: {
      fields: {
        contact: {
          fields: { phone, hours, days },
        },
      },
    },
  },
}: ContactSidebarAdapterProps) => {
  const phoneEl = phone.value
  const hoursEl = hours.value
  const daysEl = days.value
  return <ContactSidebar phone={phoneEl} hours={hoursEl} days={daysEl} />
}

export default withSitecoreContext()(React.memo(ContactSidebarAdapter))
