import React, { memo } from 'react'
import SidebarSection from '../../../components/SidebarSection'
import TagList from '../../../page-components/Tag-List/Tag-List'
import {
  SitecoreContextValue,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import { TagObjectProps } from '../../../page-components/Tag/Tag'

type Props = {
  sitecoreContext: SitecoreContextValue & { tags: TagObjectProps[] }
}

const TopicsAdapter = memo(({ sitecoreContext: { tags } }: Props) => (
  <SidebarSection title="Topics" className="Topics">
    <TagList tags={tags} />
  </SidebarSection>
))

export const Topics = withSitecoreContext()(TopicsAdapter)
