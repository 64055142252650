import React, { ReactNode } from 'react'
import clsx from 'clsx'
import spaceStyles from '../../styles/space.module.scss'
import styles from './styles.module.scss'

function IconTabList({ children, className }: { children: ReactNode, className?: string }) {
  return (
    <div className={clsx(styles.list, spaceStyles.contain, className)}>
      {children}
    </div>
  )
}

export default IconTabList
