import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

type Props = {
  heading: ReactNode
  cards: {
    heading: ReactNode
    text?: ReactNode
    image?: ReactNode
    caption?: ReactNode
  }[]
}

const HomePageValueProp: FC<Props> = ({ heading, cards }) => {
  return (
    <div data-test-id="HomePageValueProp" className={clsx(styles.valueprop, 'block', 'HomePageValueProp')}>
      <h2 className={styles.heading}>{heading}</h2>
      <div className={styles.grid}>
        {cards.map((card, index) => {
          return (
            <div key={index} className={styles.card}>
              <div className={styles.subhead}>{card.heading}</div>
              <div className={styles.content}>
                {card.image && (
                  <div className={styles.cardImage}>{card.image}</div>
                )}
                {card.text && <div>{card.text}</div>}
              </div>
              <div className={styles.caption}>{card.caption}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HomePageValueProp
