import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

type HomePageTagLineProps = {
  heading: React.ReactNode
  descriptor: React.ReactNode
}

const HomePageTagline = ({ heading, descriptor }: HomePageTagLineProps) => {
  return (
    <div className={clsx(styles.tagline)}>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.descriptor}>{descriptor}</div>
    </div>
  )
}

export default HomePageTagline
