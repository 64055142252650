import React from 'react'

import styles from './styles.module.scss'
import ScrollToTopOnMount from '../../util/ScrollToTopOnMount'
import LinkWrapper from '../../components/LinkWrapper'

type ErrorPageProps = {
  image: React.ReactNode
  heading: React.ReactNode
  linkText: string
  linkDataTag?: string
  linkHref: string
}

const ErrorPage = ({
  image,
  heading,
  linkText,
  linkHref,
  linkDataTag,
}: ErrorPageProps) => {
  return (
    <div className={styles.errorPage}>
      <ScrollToTopOnMount />
      <div className={styles.imageWrapper}>{image}</div>
      <h1 className={styles.heading}>{heading}</h1>
      {linkText && (
        <LinkWrapper
          text={linkText}
          data-tag={linkDataTag}
          className={styles.gotoLink}
          href={linkHref}
        />
      )}
    </div>
  )
}

export default ErrorPage
