import React from 'react'
import Media from '../Media'
import { MobileTable } from './Mobile/MobileTable'
import { DesktopTable } from './Desktop/DesktopTable'
import { TableRow } from './types'

export type TableProps = {
  data: TableRow[]
  boldRowHeadings?: boolean
  squash?: boolean
  largerFirstColumn: boolean
  numberOfColumns: number
}

const Table = ({
  data,
  boldRowHeadings = true,
  squash = false,
  largerFirstColumn = false,
  numberOfColumns,
}: TableProps) => {
  const getDesktopTable = () => (
    <DesktopTable
      data={data}
      numberOfColumns={numberOfColumns}
      boldRowHeadings={boldRowHeadings}
      largerFirstColumn={largerFirstColumn}
    />
  )

  const getMobileTable = () => (
    <MobileTable data={data} numberOfColumns={numberOfColumns} />
  )

  return squash ? (
    getDesktopTable()
  ) : (
    <Media desktop={getDesktopTable} mobile={getMobileTable} />
  )
}

export default Table
export { DesktopTable, MobileTable }
