import React, { memo } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import {
  LinkObjectField,
  SitecoreContextType,
} from '../../../../types/SitecoreAdapter'
import LearnMore from './LearnMore'

type LearnMoreAdapterProps = SitecoreContextType<{
  learnMore: LinkObjectField[]
}>

function unwrapLink(link: LinkObjectField) {
  return {
    body: link.fields.body.value,
    href: link.fields.href.value,
    title: link.fields.title.value,
  }
}

const LearnMoreAdapter = memo(
  ({
    sitecoreContext: {
      route: {
        fields: { learnMore },
      },
    },
  }: LearnMoreAdapterProps) => {
    const links = learnMore.map(unwrapLink)
    return <LearnMore links={links} />
  }
)

export default withSitecoreContext()(LearnMoreAdapter)
