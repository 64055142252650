import React, { ReactNode, FC } from 'react'
import clsx from 'clsx'
import ContactBanner from './ContactBanner'
import Columns from './Columns'
import SecurityImages from './SecurityImages'
import LegalLinks from './LegalLinks'
import Info from './Info'
import styles from './styles.module.scss'
import { LinkWrapperProps } from '../../components/LinkWrapper/LinkTypes'
import { ColumnProps } from './Columns/Column'

export type SecurityImagePropType = {
  text: string
  href: string
  title?: string
  newWindow?: boolean
  image: JSX.Element
  mobileImage: JSX.Element
}

type Props = {
  contact?: {
    heading: string
    link: {
      href: string
      text: string
    }
  }
  columns: Omit<ColumnProps, 'heading'>[]
  securityImages: SecurityImagePropType[]
  legalLinks: LinkWrapperProps[]
  copyright: ReactNode
  copyrightLink?: LinkWrapperProps
  disclaimer: ReactNode
  showTrustPilotWidget: boolean
}

const DynamicFooter: FC<Props> = ({
  contact,
  columns,
  securityImages,
  legalLinks,
  copyright,
  copyrightLink,
  disclaimer,
  showTrustPilotWidget = false,
}) => {
  return (
    <footer className={clsx(styles.footer, 'block', 'Footer')}>
      {contact && (
        <ContactBanner
          contact={contact}
          showTrustPilotWidget={showTrustPilotWidget}
        />
      )}
      {columns && <Columns columns={columns as ColumnProps[]} />}
      {securityImages && <SecurityImages securityImages={securityImages} />}
      {legalLinks && <LegalLinks legalLinks={legalLinks} />}
      <Info
        copyright={copyright}
        copyrightLink={copyrightLink}
        disclaimer={disclaimer}
      />
    </footer>
  )
}

export default DynamicFooter
