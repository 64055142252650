import React, { FC } from 'react'

import styles from './styles.module.scss'

type Props = {
  image: React.ReactNode
  heading: React.ReactNode
  descriptor: React.ReactNode
}

const ContactCard: FC<Props> = ({ image, heading, descriptor }) => {
  return (
    <div data-test-id="ContactCard" className={styles.contactCard}>
      <div className={styles.contactCardImageWrapper}>{image}</div>
      <h3 className={styles.heading}>{heading}</h3>
      <div className={styles.descriptor}>{descriptor}</div>
    </div>
  )
}

export default ContactCard
