import React from 'react'
import Section from './Section/Section'
import Card from './Card/Card'
import FeaturedContentLink from './Link/FeaturedContentLink'


type FeaturedContentAdapterProps = {
  fields: {
    title: string
    description: string
    href: string
    image: { src: string; alt: string } | null
    intro: string
    linkNewTab: boolean
    linkText: string
    linkTitle: string
  }
}

export default function FeaturedContentAdapter({
  fields: {
    title,
    description,
    image,
    href,
    linkTitle,
    linkNewTab,
    linkText,
    intro,
  },
}: FeaturedContentAdapterProps) {
  const featuredLinkProps = {
    href: href,
    text: linkText,
    title: linkTitle,
    newWindow: linkNewTab,
  }

  const titleLinkProps = {
    href: href,
    newWindow: linkNewTab,
  }

  const imageProps = image
    ? {
      src: `${image.src}&mh=250&c=1&ch=250&cw=310`,
      alt: image.alt
    }
    : null

  return (
    <Section intro={intro}>
      <Card
        title={title}
        titleAttr={titleLinkProps}
        description={<div dangerouslySetInnerHTML={{ __html: description }} />}
        image={imageProps}
        featuredLink={<FeaturedContentLink linkAttr={featuredLinkProps} />}
      />
    </Section>
  )
}
