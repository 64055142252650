import React from 'react'

export const isIE11 = (typeof window !== 'undefined') && /Trident\/7/.test(window.navigator.userAgent)

export class IE11BodyClass extends React.PureComponent {
  componentDidMount() {
    if (isIE11) {
      document.body.classList.add('ie11')
    }
  }

  render() {
    return null
  }
}

