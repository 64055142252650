import React from 'react'
import MenuOptionOpenSubmenu from './MenuOption/MenuOptionOpenSubmenu'
import MenuOptionLink from './MenuOption/MenuLink'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

import styles from './styles.module.scss'

class RootMenu extends React.PureComponent {
  targetElement = null

  componentDidUpdate() {
    if (this.props.isOpen) {
      disableBodyScroll(this.targetElement)
    } else {
      enableBodyScroll(this.targetElement)
    }
  }

  // we probably will not unmount the nav from the page, but just in case, clean up
  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  render() {
    const { specs, onShowSection } = this.props

    const bodyEl = specs.map(child => {
      const { href, text, title, isSummaryLink } = child
      const className = isSummaryLink ? styles.moreLink : null

      // if the menu option has children, that means it opens down a level, into a submenu
      if (child.type === 'MenuOption' && child.children && child.children.length > 0) {
        return (
          <MenuOptionOpenSubmenu
            key={href}
            text={text}
            title={title}
            href={href}
            onSelect={() => onShowSection(child)}
          />
        )
      }

      if (child.type === 'MenuOption') {
        return <MenuOptionLink key={href} text={text} title={title} href={href} className={className} />
      }

      return null
    })

    return <div ref={el => (this.targetElement = el)}>{bodyEl}</div>
  }
}

export default RootMenu
