import React, { useContext } from 'react'
import { Status } from '../../state-management/states'
import {
  SelectFocus,
  SelectCTA,
  ModalClose,
} from '../../state-management/events'
import {
  triggerInputClick,
  triggerButtonClick,
} from '../../analytics/search-app-analytics'
import SelectInert from '../../components/Select/SelectInert'
import Modal from '../../../../../page-components/Modal'
import QuoteButton from '../../components/QuoteButton'
import { AppStartSettingsContext } from '../../context'
import styles from './styles.module.scss'
import { CloseCallback, DispatchProp, SearchAppStartEmbedProps } from '../../state-management/container'
import TypeaheadContent from '../../components/TypeaheadContent'
import LeadRoutingNumberAttribution from '../../../../../components/Lead-Routing-Number-Attribution'

export default function DesktopHeroSearchAppStart({
  loadOptions,
  appState,
  dispatch,
  callbacks: { onInput, onClose },
}: SearchAppStartEmbedProps) {
  const {
    buttonText,
    placeholder,
    modalHeading,
    preselectShowInput,
    confirmButtonLabel,
  } = useContext(AppStartSettingsContext)

  const controlClass = preselectShowInput
    ? styles.control
    : styles.ctrlWithoutInput

  if (appState.status === Status.Inert) {
    return (
      <div className={controlClass}>
        {preselectShowInput && (
          <SelectInert
            value={appState.selection}
            placeholder={placeholder}
            onFocus={() => {
              triggerInputClick()
              dispatch(SelectFocus)
            }}
          />
        )}

        <QuoteButton
          onClick={() => {
            triggerButtonClick()
            dispatch(SelectCTA)
          }}
          isInputVisible={preselectShowInput}
        >
          {buttonText}
        </QuoteButton>
        <div className={styles.routingAttr}>
          <LeadRoutingNumberAttribution variant={'cta'} />
        </div>
      </div>
    )
  }

  if (appState.status === Status.AppStart && appState.direct == true) {
    return (
      <div className={controlClass}>
        {preselectShowInput && (
          <SelectInert
            value={appState.selection}
            placeholder={placeholder}
            isDisabled={true}
          />
        )}

        <QuoteButton loading disabled isInputVisible={preselectShowInput}>
          {buttonText}
        </QuoteButton>
      </div>
    )
  }

  return (
    <ModalAndBackground
      preselectShowInput={preselectShowInput}
      buttonText={buttonText}
      placeholder={placeholder}
      modalHeading={modalHeading}
      controlClass={controlClass}
      onClose={onClose}
      dispatch={dispatch}
    >
      <TypeaheadContent
        appState={appState}
        confirmButtonLabel={confirmButtonLabel}
        dispatch={dispatch}
        loadOptions={loadOptions}
        onInput={onInput}
        placeholder={placeholder}
      />
    </ModalAndBackground>
  )
}


type ModalAndBackgroundProps = {
  children: React.ReactNode
  preselectShowInput: boolean
  buttonText: string
  placeholder: string
  modalHeading: React.ReactElement
  controlClass: string
} & CloseCallback &
  DispatchProp

// extract ModalAndBackground component in order to have scroll block attached once (prevents flashing)
function ModalAndBackground({
  preselectShowInput,
  controlClass,
  placeholder,
  buttonText,
  modalHeading,
  onClose,
  dispatch,
  children
}: ModalAndBackgroundProps) {
  const handleClose = () => {
    onClose && onClose()
    dispatch(ModalClose)
  }
  // controlClass - css class changed depending on preselect status
  return (
    <>
      <div className={controlClass}>
        {preselectShowInput && <SelectInert placeholder={placeholder} />}
        <QuoteButton disabled isInputVisible={preselectShowInput}>
          {buttonText}
        </QuoteButton>
      </div>
      <Modal
        onClose={handleClose}
        modalHeading={modalHeading}
        desktopSize="md"
      >
        {children}
      </Modal>
    </>
  )
}