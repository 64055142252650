import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import LinkWrapper from '../../../components/LinkWrapper'

export type HeadingProps = {
  heading: {
    href?: string
    text: string
    title?: string
  }
}

const Heading = ({ heading }: HeadingProps) => {
  if (heading.href) {
    return (
      <LinkWrapper
        href={heading.href}
        text={heading.text}
        title={heading.title}
        className={clsx(styles.heading, styles.anchor)}
        data-tag="footer"
      />
    )
  }

  return <div className={styles.heading}>{heading.text}</div>
}

export default React.memo(Heading)
